import React from 'react'
import { FormattedMessage } from 'react-intl'
import Carousel from 'react-material-ui-carousel'
import { Typography, Box, Container, Grid, Hidden } from '@material-ui/core'

import { useStyles } from './promotionindex_style'
import CTABanner from 'components/CTABanner/CTABanner'
import PromotionCard from 'cards/PromotionCard/PromotionCard'
import Paper from 'components/Paper/Paper'
import PromotionIndexHelmet from 'helmets/PromotionIndexHelmet'
import { PROMOTION } from 'assets/AuthentificationHelper'
import SEOBlock from 'pages/ItemIndexMain/SEOBlock/SEOBlock'
import MultiFontText from 'shared/components/MultiFontText/MultiFontText'
import LottieFactory from 'icons/LottieFactory/LottieFactory'

const PromotionIndex = ({ user, promotions }) => {
    const classes = useStyles()

    const renderPromotionCards = () => {
        const cards = promotions.map((promo, index) => {
            return (
                <Grid key={index} item sm={6} md={4} lg={3} className={classes.promotionItem}>
                    <PromotionCard promotion={promo}/>
                </Grid>
            )
        })
        return cards
    }

    return (
        <Box className={classes.promotionPageContainer}>
            <PromotionIndexHelmet />
            <Box className={classes.bannerContainer}>
                <Box className={classes.lottieContainer}>
                    <LottieFactory
                        lottie="speaker"
                    />
                </Box>
                <Box className={classes.lottieContainerBis}>
                    <LottieFactory
                        lottie="star"
                        delay={500}
                    />
                </Box>
                <Box className={classes.bannerText}>
                    <MultiFontText>
                        <FormattedMessage id='promotions_for_student.index_title_v2'>
                            {text =>
                                <Typography className={classes.bannerTitle} variant='h1' dangerouslySetInnerHTML={{ __html: text }} />
                            }
                        </FormattedMessage>
                    </MultiFontText>
                </Box>
            </Box>
            <Container maxWidth='lg' className={classes.pageContainer}>
                <Paper className={classes.breaklines}>
                    <Typography variant='h2' className={classes.breaklineTitle}>
                        <FormattedMessage id='promotions_for_student.index_subtitle' />
                    </Typography>
                    <Typography className={classes.text}>
                        <FormattedMessage id='promotions_for_student.index_descriptions.desc_1' />
                    </Typography>
                </Paper>
                {promotions.length > 0 &&
                    <Box className={classes.promotionsContainer}>
                        <Box className={classes.promotionCardsContainer}>
                            <Grid container justify='center' spacing={3} >
                                <Hidden xsDown>
                                    {renderPromotionCards()}
                                </Hidden>
                                <Hidden smUp>
                                    <Carousel
                                        autoPlay={false}
                                        animation='slide'
                                        navButtonsAlwaysInvisible
                                        navButtonsAlwaysVisible={false}
                                        indicatorContainerProps={{
                                            className: 'indicatorsContainer',
                                            style: { marginTop: 20 }
                                        }}
                                    >
                                        {renderPromotionCards()}
                                    </Carousel>
                                </Hidden>
                            </Grid>
                        </Box>
                    </Box>
                }
                <Box className={classes.CTAContainer}>
                    <CTABanner
                        user={user}
                        origin={PROMOTION}
                        title={<FormattedMessage id='promotions_for_student.cta_all_promotions' />}
                        buttonText={<FormattedMessage id='promotions_for_student.all_promotions' />}
                        buttonIcon='badge-dollar'
                        link='https://www.4ucampus.be/'
                    />
                </Box>
                <SEOBlock type='promotion'/>
            </Container>
        </Box>
    )
}
export default PromotionIndex
