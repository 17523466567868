/* eslint-disable no-multi-spaces */
import CollectionHandler from 'assets/CollectionHandler.js'
import URLHandler from 'assets/URLHandler.js'

const queryString = require('query-string')

const parseURLParams = () => {
    return queryString.parse(location.search)
}

const parseRedirectFromURL = () => {
    const url = new URL(location.href)
    const searchParams = new URLSearchParams(url.search)
    return searchParams.get('redirect')
}

const redirectWithURL = () => {
    const redirectUrl = parseRedirectFromURL()
    if (verifyRedirectURLAgainstDomain(redirectUrl)) {
        window.location.href = redirectUrl
    } else {
        window.location.href = '/'
    }
}

const verifyRedirectURLAgainstDomain = (redirectUrl) => {
    const studentDomain = window.location.host
    const redirectDomain = new URL(redirectUrl).host

    return studentDomain === redirectDomain
}

const stringifyURLParams = (params) => {
    return queryString.stringify(params)
}

const reloadPageAndAddParams = (params) => {
    const newURLParams = {
        ...parseURLParams(),
        ...params
    }
    location.href = `${addPrefixURLParams()}${stringifyURLParams(newURLParams)}`
}

const addPrefixURLParams = () => {
    return `${URLHandler.removeLastSlash()}?`
}

const getValueFromURLFilters = (filter, collection = null) => {
    return Array.isArray(filter)
        ? filter.map((option) => { return { value: option, name: CollectionHandler.Translate(collection, option) } })
        : [{ value: filter, name: CollectionHandler.Translate(collection, filter) }]
}

const getValueFromFilterArray = (filterArray) => {
    if (filterArray) {
        return filterArray.map((filter) => filter.value)
    }
    return undefined
}

const isPagePresentInURL = () => {
    return !!parseURLParams().page
}

const loadCurrentPage = () => {
    return isPagePresentInURL() ? parseInt(queryString.parse(location.search).page) : 1
}

const loadPreviousPageBool = () => isPagePresentInURL()

const isObjectEmpty = (obj) => !Object.keys(obj).length

const isParamsEmpty = (params) => {
    return !(
        (params?.study_domain?.length > 0)  ||
        (params?.company_size?.length > 0)  ||
        (params?.quick_filter?.length > 0)  ||
        (params?.location?.length > 0)      ||
        (params?.job_schedule?.length > 0)  ||
        (params?.sector?.length > 0)        ||
        (params?.job_type?.length > 0)      ||
        (params?.title)                     ||
        (params?.kot_type?.length > 0)      ||
        (params?.disponibility?.length > 0) ||
        (params?.price?.length > 0)         ||
        (params?.room_count?.length > 0)    ||
        (params?.type)                      ||
        (params?.page)                      ||
        (params?.order_direction)           ||
        (params?.traineeship)               ||
        (params?.order_key)
    )
}

const loadProcessFromURL = () => {
    const search = queryString.parse(location.search)

    return search.process
}

/*
    Parse the filter Params from the URL
    and return a hash object with the params

    Reads snake_case params from url
    and load them into camelCase to hash object (filter state)
*/
const loadUrlFilters = (pageType) => {
    const urlFilters = queryString.parse(location.search)
    let urlFiltersObject

    switch (pageType) {
    case 'first_job':
    case 'internship':
    case 'student_job':
        // Prevents loading null values to the object
        urlFiltersObject = {
            ...(urlFilters.title && { title: urlFilters.title }),
            ...(urlFilters.company_size && { companySize: getValueFromURLFilters(urlFilters.company_size, 'companySizeCollection') }),
            ...(urlFilters.study_domain && { studyDomain: getValueFromURLFilters(urlFilters.study_domain, 'studyDomainCollection') }),
            ...(urlFilters.job_schedule && { jobSchedule: getValueFromURLFilters(urlFilters.job_schedule, 'studentJobSchedulesCollection') }),
            ...(urlFilters.sector && { sector: getValueFromURLFilters(urlFilters.sector, 'sectorCollection') }),
            ...(urlFilters.quick_filter && { quickFilter: urlFilters.quick_filter }),
            ...(urlFilters.traineeship && { traineeship: true })
        }
        break
    case 'kot':
        urlFiltersObject = {
            ...(urlFilters.kot_type && { kotType: getValueFromURLFilters(urlFilters.kot_type, 'kotTypeCollection') }),
            ...(urlFilters.disponibility && { disponibility: getValueFromURLFilters(urlFilters.disponibility, 'kotDisponibilityCollection') }),
            ...(urlFilters.price && { price: getValueFromURLFilters(urlFilters.price, 'kotPriceCollection') }),
            ...(urlFilters.room_count && { roomCount: getValueFromURLFilters(urlFilters.room_count, 'kotRoomCountCollection') })
        }
        break
    case 'company':
        urlFiltersObject = {
            ...(urlFilters.title && { title: urlFilters.title }),
            ...(urlFilters.sector && { sector: getValueFromURLFilters(urlFilters.sector, 'sectorCollection') }),
            ...(urlFilters.company_size && { companySize: getValueFromURLFilters(urlFilters.company_size, 'companySizeCollection') }),
            ...(urlFilters.job_type && { jobType: getValueFromURLFilters(urlFilters.job_type, 'jobTypeCollection') }),
            ...(urlFilters.quick_filter && { quickFilter: urlFilters.quick_filter })
        }
        break
    case 'article':
        urlFiltersObject = {
            ...(urlFilters.title && { title: urlFilters.title })
        }
        break
    case 'company_job':
        urlFiltersObject = {
            ...(urlFilters.title && { title: urlFilters.title }),
            ...(urlFilters.type && { type: urlFilters.type }),
            ...(urlFilters.page && { page: urlFilters.page }),
            ...(urlFilters.order_direction && { orderDirection: urlFilters.order_direction }),
            ...(urlFilters.order_key && { orderKey: urlFilters.order_key }),
            ...(urlFilters.session_id && { session_id: urlFilters.session_id })
        }
        break
    case 'alert':
        urlFiltersObject = {
            ...(urlFilters.item_type && { itemType: urlFilters.item_type }),
            ...(urlFilters.study_domain && { studyDomains: getValueFromURLFilters(urlFilters.study_domain, 'studyDomainCollection') }),
            ...(urlFilters.company_size && { companySize: getValueFromURLFilters(urlFilters.company_size, 'companySizeCollection') }),
            ...(urlFilters.job_schedule && { schedule: getValueFromURLFilters(urlFilters.job_schedule, 'studentJobSchedulesCollection') }),
            ...(urlFilters.sector && { sectors: getValueFromURLFilters(urlFilters.sector, 'sectorCollection') }),
            ...(urlFilters.quick_filter && { quickFilter: urlFilters.quick_filter })
        }
        break
    }

    urlFiltersObject = {
        ...urlFiltersObject,
        ...(urlFilters.location && {
            location: Array.isArray(urlFilters.location)
                ? urlFilters.location.map((option) => {
                    const optionArray = option.split(',')
                    return {
                        value: optionArray[0],
                        lat: optionArray[1],
                        long: optionArray[2],
                        name: CollectionHandler.Translate('cityCollection', optionArray[0])
                    }
                })
                : [{ // TODO Refactor - prevent splitting 3 times
                    value: urlFilters.location.split(',')[0],
                    lat: urlFilters.location.split(',')[1],
                    long: urlFilters.location.split(',')[2],
                    name: urlFilters.location.split(',')[0]
                }]
        })
    }

    return urlFiltersObject
}

const getURLParamsFromFilters = (pageType, filters) => {
    let urlFilters = {}

    switch (pageType) {
    case 'first_job':
    case 'internship':
    case 'student_job':
        urlFilters = {
            title: filters.title || undefined,
            study_domain: getValueFromFilterArray(filters.studyDomain),
            company_size: getValueFromFilterArray(filters.companySize),
            job_schedule: getValueFromFilterArray(filters.jobSchedule),
            sector: getValueFromFilterArray(filters.sector),
            traineeship: filters.traineeship || undefined,
            quick_filter: filters.quickFilter || undefined
        }
        break
    case 'kot':
        urlFilters = {
            kot_type: getValueFromFilterArray(filters.kotType),
            disponibility: getValueFromFilterArray(filters.disponibility),
            price: getValueFromFilterArray(filters.price),
            room_count: getValueFromFilterArray(filters.roomCount)
        }
        break
    case 'company':
        urlFilters = {
            title: filters.title || undefined,
            company_size: getValueFromFilterArray(filters.companySize),
            sector: getValueFromFilterArray(filters.sector),
            job_type: getValueFromFilterArray(filters.jobType),
            quick_filter: filters.quickFilter || undefined
        }
        break
    case 'article':
        urlFilters = {
            title: filters.title || undefined
        }
        break
    case 'company_job':
        urlFilters = {
            title: filters.title,
            type: filters.type,
            page: filters.page,
            order_direction: filters.orderDirection,
            order_key: filters.orderKey
        }
        break
    case 'alert':
        urlFilters = {
            item_type: filters.itemType,
            location: getValueFromFilterArray(filters.location),
            study_domain: getValueFromFilterArray(filters.studyDomains),
            company_size: getValueFromFilterArray(filters.companySize),
            job_schedule: getValueFromFilterArray(filters.schedule),
            sector: getValueFromFilterArray(filters.sectors),
            quick_filter: filters.quickFilter || undefined
        }
        break
    default:
        break
    }

    urlFilters = {
        ...urlFilters,
        location: filters.location
            ? filters.location.map((option) => {
                return `${option.value},${option.lat},${option.long}`
            })
            : undefined
    }

    return urlFilters
}
/*
    Create the Hash object used to push the filter params to the URL

    Loads camelCase filters state and push them to snake_case URL params
*/

const removeFilterParamsFromParsedUrlParamsObject = (parsedUrlParams, urlFilters) => {
    return Object.fromEntries(
        Object.entries(parsedUrlParams).filter(([key]) => !(key in urlFilters))
    )
}

const createURLFilters = (pageType, filters, page) => {
    const urlFilters = getURLParamsFromFilters(pageType, filters, page)
    const parsedUrlParams = parseURLParams()
    const nonFilterParsedUrlParams = removeFilterParamsFromParsedUrlParamsObject(parsedUrlParams, urlFilters)
    const newUrlParams = {
        ...urlFilters,
        ...nonFilterParsedUrlParams
    }

    if (isObjectEmpty(parsedUrlParams) && isParamsEmpty(urlFilters)) {
        window.history.pushState('', '', location.pathname)
    } else {
        window.history.pushState('', '', `${location.pathname}?${queryString.stringify(newUrlParams)}`)
    }
}

const getURLSearchParams = (pageType, filters) => {
    const urlFilters = getURLParamsFromFilters(pageType, filters)

    if (!isParamsEmpty(urlFilters)) return ('&' + queryString.stringify(urlFilters))
}

const removeAllParams = () => {
    window.history.pushState('', '', location.pathname)
}

export default {
    loadProcessFromURL,
    loadUrlFilters,
    createURLFilters,
    loadCurrentPage,
    loadPreviousPageBool,
    parseURLParams,
    stringifyURLParams,
    reloadPageAndAddParams,
    getURLSearchParams,
    removeAllParams,
    parseRedirectFromURL,
    redirectWithURL
}
