import confetti from 'canvas-confetti'

export const useConfetti = (props = {}) => {
    const { type } = props

    const confettiCanon = () => {
        if (!type) randomConfetti()
        if (type === 'gala') galaConfetti()
        if (type === 'firework') fireworkConfetti()
        if (type === 'curtain') curtainConfetti()
        if (type === 'simple') simpleConfetti()
    }

    const customConfettiCanon = (options) => {
        const customOptions = {
            ...defaultOptions,
            ...options
        }
        const canvas = document.createElement('canvas')
        canvas.style.position = 'absolute'
        canvas.style.top = '50%'
        canvas.style.left = '50%'
        canvas.style.transform = 'translate(-50%, -50%)'
        canvas.style.pointerEvents = 'none'
        canvas.width = customOptions.width
        canvas.height = customOptions.height
        const refComponent = customOptions.ref.current
        refComponent.appendChild(canvas)
        const customConfetti = confetti.create(canvas)

        setTimeout(() => {
            customConfetti(customOptions.particles)
        }, customOptions.delay)

        setTimeout(() => {
            canvas.remove()
            customConfetti.reset()
        }, customOptions.timeout)
    }

    return { confettiCanon, customConfettiCanon }
}

// PRIVATE

const defaultOptions = {
    width: 800,
    height: 400,
    timeout: 2000,
    delay: 300,
    particles: {
        particleCount: 180,
        spread: 360,
        startVelocity: 30,
        gravity: 1,
        origin: { x: 0.5, y: 0.45 }
    }
}

const galaConfetti = () => {
    const duration = 5 * 1000
    const animationEnd = Date.now() + duration

    const interval = setInterval(() => {
        const timeLeft = animationEnd - Date.now()
        confetti({ particleCount: 10, angle: 60, spread: 35, origin: { x: 0 } })
        confetti({ particleCount: 10, angle: 120, spread: 35, origin: { x: 1 } })

        if (timeLeft <= 0) {
            return clearInterval(interval)
        }
    }, 200)
}

const fireworkConfetti = () => {
    const duration = 5 * 1000
    const animationEnd = Date.now() + duration
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 }

    const randomInRange = (min, max) => {
        return Math.random() * (max - min) + min
    }

    const interval = setInterval(() => {
        const timeLeft = animationEnd - Date.now()

        if (timeLeft <= 0) {
            return clearInterval(interval)
        }

        const particleCount = 50 * (timeLeft / duration)
        confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } })
        confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } })
    }, 250)
}

const curtainConfetti = () => {
    confetti({
        particleCount: 300,
        spread: 145,
        ticks: 500,
        origin: { y: -0.1 }
    })
}

const randomConfetti = () => {
    const possibleFireworks = [galaConfetti, fireworkConfetti, curtainConfetti, simpleConfetti]
    const randomIndex = Math.floor(Math.random() * possibleFireworks.length)
    possibleFireworks[randomIndex]()
}

const simpleConfetti = () => {
    confetti({
        particleCount: 300,
        spread: 90,
        origin: { y: 0.6 }
    })
}
