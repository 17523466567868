import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    articles: {
        display: 'flex',
        gap: 16,
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    article: {
        flex: 3,
        minWidth: 240,
        maxWidth: 350,
    }
}))
