import React from 'react'

import { useStyles } from './CompanyNews_style'
import { Box } from '@material-ui/core'
import ArticleCardV2 from 'shared/cards/ArticleCard/ArticleCardV2'

const CompanyNews = (props) => {
    const classes = useStyles()
    const { articles } = props

    return (
        <Box className={classes.companyNewsContainer}>
            <Box className={classes.articles}>
                {articles?.map(article =>
                    <Box className={classes.article} key={article.id}>
                        <ArticleCardV2 article={article} />
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default CompanyNews
