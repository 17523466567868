import React from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import RouteMap from 'assets/RouteMap'
import DefaultHelmet from './DefaultHelmet'
import CloudinaryURLHelper from 'assets/CloudinaryURLHelper'
import CollectionHandler from 'assets/CollectionHandler'
import Sanitizer from 'assets/Sanitizer'

class SchoolShowHelmet extends React.Component {
    render () {
        return null

        const { formatMessage } = this.props.intl

        const logoURL = CloudinaryURLHelper.getImageUrl('META_IMAGE', this.props.logoCloudinaryKey)
        const description = formatMessage({ id: 'helmet.company.description' }, { company_name: this.props.company.name })

        const knowsLanguageString = () => {
            let languageList = ''
            if (this.props.company.description_en && this.props.company.description_en !== '<p><br></p>') languageList += 'English, '
            if (this.props.company.description_fr && this.props.company.description_fr !== '<p><br></p>') languageList += 'French, '
            if (this.props.company.description_nl && this.props.company.description_nl !== '<p><br></p>') languageList += 'Dutch'
            languageList = languageList.trim().replace(/,\s*$/, '')

            return languageList
        }

        return <>
            <DefaultHelmet
                title={formatMessage({ id: 'helmet.company.title' }, { company_name: this.props.company.name })}
                description={description}
                imageCloudinaryKey={this.props.logoCloudinaryKey}
            />
            <Helmet>
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`https://www.student.be${window.location.pathname}`} />
                <meta property='og:updated_time' content={this.props.company.updated_at} /> {/* When the company was last changed. */}
                <link rel="canonical" href={RouteMap.Show('company', this.props.company.url)} />
                <link rel="alternate" hrefLang="fr-BE" href={RouteMap.Show('company', this.props.company.url, 'fr')} />
                <link rel="alternate" hrefLang="en-BE" href={RouteMap.Show('company', this.props.company.url, 'en')} />
                <link rel="alternate" hrefLang="nl-BE" href={RouteMap.Show('company', this.props.company.url, 'nl')} />
                <link rel="alternate" hrefLang="x-default" href={RouteMap.Show('company', this.props.company.url, 'en')} />
            </Helmet>
            <script type="application/ld+json">
                {`{
                    "@context": "http://schema.org",
                    "@type": "Organization",
                    "name": "${Sanitizer.SanitizeForApplicationJson(this.props.company.name)}",
                    "description" : "${Sanitizer.SanitizeForApplicationJson(description)}",
                    "url": "https://www.student.be${window.location.pathname}",
                    "logo" : "${logoURL}",
                    "founder": {
                        "@type": "Person",
                        "name": "${Sanitizer.SanitizeForApplicationJson(this.props.company.ceo_name)}"
                    },
                    "numberOfEmployees": "${numberOfEmployees}",
                    "knowsLanguage": "[${knowsLanguageString()}]"
                }`}
            </script>

        </>
    }
}

export default injectIntl(SchoolShowHelmet)
