import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    companyImpactContainer: {
        padding: '80px 0',
        display: 'flex',
        flexDirection: 'column',
        gap: 30,
        [theme.breakpoints.down('sm')]: {
            padding: '40px 0px'
        }
    },
    companyImpactText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            // maxWidth: 325,
            textAlign: 'center'
        }
    },
    companyImpactTitle: {
        fontSize: 40,
        textAlign: 'center',
        lineHeight: '56px',
        fontFamily: 'GreedWide-Heavy',
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            fontSize: 32,
            lineHeight: '40px'
        }
    },
    companyImpactCardsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 16,
        width: '90%',
        margin: 'auto'
    },
    alignCenter: {
        margin: '0 auto'
    },
    CompanyImpactDescription: {
        lineHeight: '18px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        }
    },
    sliderContainer: {
        // maxWidth: 1250
    },
    companyCardBox: {
        scrollSnapAlign: 'center',
        width: '100%',
        minWidth: 370,
        [theme.breakpoints.down('xs')]: {
            minWidth: 300
        }
    }
}))
