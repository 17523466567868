import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    stringInputBox: {
        marginTop: 16,
        width: 600,
        '& .MuiFormControl-root': {
            width: '100%'
        }
    },
    fullWidthBox: {
        width: '100% !important'
    },
    quillBox: {
        width: 600
    },
    itemWrapper: {
        padding: '20px',
        borderRadius: 10,
        border: '1px solid black',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        width: '100%',
        maxWidth: 700,
        minWidth: 400
    },
    quillStyle: {
        height: 250,
        marginBottom: 40
    },
    addFacultyBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    buttonBox: {
        marginTop: 16
    },
    stringInput: {
        minWidth: 400,
        marginTop: 16
    },
    accordionTitle: {
        backgroundColor: 'var(--background-grey-light)'
    },
    accordionContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 16
    },
    formLabel: {
        fontWeight: 'bold',
        marginBottom: 16
    }
}))
