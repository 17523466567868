/* eslint-disable react/prop-types */
import React from 'react'
import { useStyles } from './ItemIcon_style'
import { Box, useTheme } from '@material-ui/core'
import SVGFactory from 'icons/SVGFactory/SVGFactory'

const ItemIcon = (props) => {
    const { type, width, height, color } = props
    const classes = useStyles()
    const theme = useTheme()

    const typeToSVGMapping = {
        first_job: { icon: 'first-job', color: theme.palette.bg.green },
        student_job: { icon: 'lightning', color: theme.palette.bg.yellow },
        internship: { icon: 'graduate', color: theme.palette.bg.blue },
        company: { icon: 'trainee', color: theme.palette.bg.orange },
        kot: { icon: 'trainee', color: theme.palette.bg.orange },
        article: { icon: 'trainee', color: theme.palette.bg.orange }
    }

    const iconObject = typeToSVGMapping[type]

    if (!iconObject) return null

    return (
        <Box
            className={classes.iconBubble}
            style={{
                backgroundColor: color || typeToSVGMapping[type].color,
                width: width * 2,
                height: height * 2
            }}>
            <SVGFactory name={typeToSVGMapping[type].icon} width={`${width}px`} height={`${height}px`} />
        </Box>
    )
}

export default ItemIcon
