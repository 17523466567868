/* eslint-disable no-multi-spaces */
/* eslint-disable no-case-declarations */
import TagManager from 'react-gtm-module'
import Bugsnag from '@bugsnag/js'

import AnalyticsAPI from 'api/AnalyticsAPI'
import CollectionHandler from 'assets/CollectionHandler'
import DateHandler from 'assets/DateHandler'
import FiltersHelper from 'assets/FiltersHelper'
import URLHandler from 'assets/URLHandler'
import {
    EBOOK_DOWNLOAD, FAVOURITE, KOT_SHOW,
    JOB_APPLICATION, SAVE_FILTERS, JOB_SHOW, PROMOTION, EMPLOYER_BUYING_CREDITS, MY_BS_REGISTRATION, JOIN_THE_CLUB
} from 'assets/AuthentificationHelper'
import { JOB_TYPES_LIST } from 'assets/ItemHelper'

const sendGTMEvent = (eventName, data = {}) => {
    try {
        TagManager.dataLayer({
            dataLayer: {
                event: eventName,
                ...data,
                student_website_language: URLHandler.GetLocaleFromUrl()
            }
        })
    } catch (error) {
        Bugsnag.notify(error)
    }
}

const sendAnalyticsEvent = (action, data = {}) => {
    try {
        return AnalyticsAPI.sendData(action, data)
    } catch (error) {
        Bugsnag.notify(error)
    }
}

// ===== Analytics Event Constructors =====
// Event: Page Viewed
const constructPageViewedAnalyticsAttributes = (user) => {
    return {
        page_url: window.location.href,
        ...constructUserAnalyticsAttributes(user),
        ...constructMinimalStudentAnalyticsAttributes(user)
    }
}

// Events:
//  - Account Deleted
const constructBasicAnalyticsAttributes = (user) => {
    return {
        ...constructUserAnalyticsAttributes(user)
    }
}

// Event: Login
const constructLoginAnalyticsAttributes = (user, loginType, loginOrigin) => {
    return {
        ...constructUserAnalyticsAttributes(user),
        ...constructAuthentificationAnalyticsAttributes(loginType, loginOrigin)
    }
}

// Event: Create Account Started
const constructRegisterAnalyticsAttributes = (loginType, loginOrigin) => {
    return {
        ...constructAuthentificationAnalyticsAttributes(loginType, loginOrigin)
    }
}

// Event: Create Account Completed - Employer
const constructRegisterEmployerAnalyticsAttributes = (user, registerType, registerOrigin, company) => {
    return {
        ...constructUserAnalyticsAttributes(user),
        ...constructAuthentificationAnalyticsAttributes(registerType, registerOrigin),
        ...constructCompanyAnalyticsAttributes(company)
    }
}

// Event: Create Account Completed - Kot Owner
const constructRegisterOwnerAnalyticsAttributes = (user, registerType, registerOrigin) => {
    return {
        ...constructUserAnalyticsAttributes(user),
        ...constructAuthentificationAnalyticsAttributes(registerType, registerOrigin)
    }
}

// Event: Create Account Completed - Student
const constructRegisterStudentAnalyticsAttributes = (user, registerType, registerOrigin) => {
    return {
        ...constructUserAnalyticsAttributes(user),
        ...constructAuthentificationAnalyticsAttributes(registerType, registerOrigin),
        ...constructAverageStudentAnalyticsAttributes(user)
    }
}

// Event: Article Details Viewed
const constructArticleDetailsViewedAnalyticsAttributes = (user, article) => {
    return {
        ...constructUserAnalyticsAttributes(user),
        ...constructStudentAnalyticsAttributes(user),
        ...constructArticleAnalyticsAttributes(article)
    }
}

// Event: Job Details Viewed
const constructJobDetailsViewedAnalyticsAttributes = (user, job, company) => {
    return constructJobGeneralAnalyticsAttributes(user, job, company)
}

// Event: Kot Details Viewed
const constructKotDetailsViewedAnalyticsAttributes = (user) => {
    return {
        ...constructUserAnalyticsAttributes(user),
        ...constructStudentAnalyticsAttributes(user)
    }
}

// Event: Company Details Viewed
const constuctCompanyDetailsViewedAnalyticsAttributes = (user, company) => {
    return {
        ...constructUserAnalyticsAttributes(user),
        ...constructStudentAnalyticsAttributes(user),
        ...constructCompanyAnalyticsAttributes(company)
    }
}

// Events:
//  - Kot List Viewed
//  - Company List Viewed
//  - Job List Viewed
//  - Article List Viewed
const constructIndexListViewedAnalyticsAttributes = (user, jobType = null) => {
    return {
        ...constructUserAnalyticsAttributes(user),
        ...constructStudentAnalyticsAttributes(user),
        ...constructJobTypeAnalyticsAttributes(jobType)
    }
}

// Event: Search Filter Applied
const constructSearchFilterAppliedAnalyticsAttributes = (user, filters, alertOrigin) => {
    return {
        ...constructUserAnalyticsAttributes(user),
        ...constructFiltersAnalyticsAttributes(filters),
        alert_creation_origin: alertOrigin
    }
}

const constructStudentUserObjectForApply = (user, student, studentFormation, birthdate) => {
    return {
        id: user.id,
        type: user.type,

        education_type: studentFormation[0]?.study_type,
        background_type: studentFormation[0]?.study_cycle,
        study_domain: studentFormation[0]?.field_of_study?.value || studentFormation[0]?.other_field_of_study,
        city: student.city,
        zip: student.zip,
        age: DateHandler.toAge(birthdate),
        student_status: [],
        language_preference: user.language_preference,
        school_name: studentFormation[0]?.school?.name || studentFormation[0]?.other_school,
        school_location: studentFormation[0]?.school?.location
    }
}

// Events:
//  - Job Apply Started - Student Job
//  - Job Apply Completed - Student Job
//  - Profile Summary Filled - Student Job
//  - CV Uploaded - Student Job
//  - Question From Employer Filled - Student Job
//  - Message To Employer Filled - Student Job
const constructStudentJobAnalyticsAttributes = (user, job, company, skipCVStep) => {
    const studentJobAttributes = constructJobGeneralAnalyticsAttributes(user, job, company)
    studentJobAttributes.job_cv_upload = !skipCVStep && job.application_type !== 'url'
    studentJobAttributes.job_question_from_employer = !!job.job_questions.length
    studentJobAttributes.job_message_to_employer = !!job.motivation_letter
    // studentJobAttributes.source = window.frames.top.document.referrer

    return studentJobAttributes
}

// Events:
//  - Job Apply Started - Internship
//  - Job Apply Started - First Job
//  - Job Apply Completed - Internship
//  - Job Apply Completed - First Job
const constructFirstInternJobAnalyticsAttributes = (user, job, company) => {
    const jobAttributes = constructJobGeneralAnalyticsAttributes(user, job, company)
    jobAttributes.job_cv_upload = job.application_type !== 'url'
    jobAttributes.contract_type = job.contract_type
    // jobAttributes.source = window.frames.top.document.referrer

    return jobAttributes
}

// Event: Account Updated
// Event: Experience Updated
const constructAccountUpdatedAnalyticsAttributes = (user, studentProfile, lastFormation, ageDate, context) => {
    return {
        ...constructUserAnalyticsAttributes(user),
        student_education_type: lastFormation?.study_cycle,
        student_background_type: lastFormation?.study_type,
        student_study_domain: lastFormation?.field_of_study?.name,
        student_city: studentProfile.city,
        student_zip: studentProfile.zip,
        student_age: DateHandler.toAge(ageDate),
        student_status: user.student_status,
        student_preferred_language: user.language_preference,
        school_name: lastFormation?.school?.name || lastFormation?.other_school,
        school_location: lastFormation?.school?.location,
        context: context
    }
}

// Event: Student Status Updated
const constructStudentStatusAnalyticsAttributes = (user, studentStatus, context) => {
    return {
        ...constructUserAnalyticsAttributes(user),
        student_education_type: user?.education_type,
        student_background_type: user?.background_type,
        student_study_domain: user?.study_domain,
        student_city: user?.city,
        student_zip: user?.zip,
        student_age: user?.age,
        student_status: constructStudentStatusArray(studentStatus),
        student_preferred_language: user?.language_preference,
        school_name: user?.school_name,
        school_location: user?.school_location,
        context: context
    }
}

// Events:
//  - AdBanner Impression
//  - AdBanner Click
const constructBannerAnalyticsAttributes = (bannerLabel) => {
    return {
        banner_label: bannerLabel
    }
}

// Event: Stripe Begin Checkout
const constructStripeCheckoutAnalyticsAttributes = (creditPackage, user, company, context) => {
    return {
        package_name: creditPackage.name,
        package_credits: creditPackage.credits,
        package_price_per_credit: creditPackage.price,
        package_total_cost: creditPackage.value,
        context: context,
        ...constructUserAnalyticsAttributes(user),
        ...constructEmployerAnalyticsAttributes(user, company)
    }
}

// Event: begin_checkout (from JobForm Payement Step)
const constructJobFormCheckoutAnalyticsAttributes = (user, company, jobType, selectedPackage, isBuyingCredits) => {
    return {
        ...constructUserAnalyticsAttributes(user),
        ...constructEmployerAnalyticsAttributes(user, company),
        items: [{
            item_name: selectedPackage.title,
            item_id: selectedPackage.name,
            item_category: formatJobType(jobType),
            ...getPaymentPriceAndQuantity(user, selectedPackage, isBuyingCredits)
        }],
        currency: 'EUR',
        ...getPayementValue(user, selectedPackage, isBuyingCredits)
    }
}

// Event: begin_checkout (from Pricing Page)
const constructPricingPageCheckoutAnalyticsAttributes = (user, company, selectedPackage) => {
    return {
        ...constructUserAnalyticsAttributes(user),
        ...constructEmployerAnalyticsAttributes(user, company),
        items: [{
            item_name: selectedPackage.title,
            item_id: selectedPackage.name,
            item_category: 'Credits', // Purchasing package of credits, rather than a job
            price: selectedPackage.price,
            quantity: selectedPackage.credits
        }],
        currency: 'EUR',
        value: selectedPackage.credits * selectedPackage.price
    }
}

// Events:
//  - begin_checkout (from Boost Modal)
//  - begin_checkout (from Active Modal)
const constructModalCheckoutAnalyticsAttributes = (user, company, selectedPackage, jobType) => {
    return {
        ...constructUserAnalyticsAttributes(user),
        ...constructEmployerAnalyticsAttributes(user, company),
        items: [{
            item_name: selectedPackage.title,
            item_id: selectedPackage.name,
            item_category: formatJobType(jobType),
            price: selectedPackage.price,
            quantity: selectedPackage.credits - user.number_of_credits
        }],
        currency: 'EUR',
        value: (selectedPackage.credits - user.number_of_credits) * selectedPackage.price
    }
}

// Event: purchase
const constructPurchaseAnalyticsAttributes = (user, company, lastTransaction) => {
    return {
        ...constructUserAnalyticsAttributes(user),
        ...constructEmployerAnalyticsAttributes(user, company),
        ...getLastPurchaseInformation(lastTransaction)
    }
}

// Event: Pricing Plan List Viewed
const constructPricingPagViewedeAnalyticsAttributes = (user, company) => {
    return {
        ...constructUserAnalyticsAttributes(user),
        ...constructEmployerAnalyticsAttributes(user, company)
    }
}

// Event: Clicked Job Funnel Continue
const constructJobFunnelAnalyticsAttributes = (user, company, jobType) => {
    return {
        job_type: jobType,
        ...constructUserAnalyticsAttributes(user),
        ...constructEmployerAnalyticsAttributes(user, company)
    }
}

// ===== END Analytics Event Constructors =====

// ===== PRIVATE =====
const constructStudentStatusArray = (studentStatus) => {
    const result = []
    if (studentStatus.actively_looking_student_job) result.push('actively_looking_student_job')
    if (studentStatus.actively_looking_internship) result.push('actively_looking_internship')
    if (studentStatus.actively_looking_first_job) result.push('actively_looking_first_job')
    if (studentStatus.actively_looking_kot) result.push('actively_looking_kot')
    if (studentStatus.not_looking_any_job) result.push('not_looking_any_job')

    return result
}

// Transform login/register origin code value to GA attribute value
const getSourceName = (origin) => {
    switch (origin) {
    case EBOOK_DOWNLOAD:
        return 'Ebook'
    case FAVOURITE:
        return 'Favourite'
    case KOT_SHOW:
        return 'Kot show'
    case JOB_APPLICATION:
        return 'Job application'
    case SAVE_FILTERS:
        return 'Alert creation'
    case JOB_SHOW:
        return 'Job show'
    case PROMOTION:
        return 'Promotion'
    case EMPLOYER_BUYING_CREDITS:
        return 'Employer Buying Credits'
    case MY_BS_REGISTRATION:
        return 'MyBS Registration'
    case JOIN_THE_CLUB:
        return 'Join The Club'
    default:
        return 'Default'
    }
}

// Format job type to consistent format of Studentjob Internship Firstjob
const formatJobType = (jobType) => {
    switch (jobType) {
    case 'student_job':
    case 'first_job':
    case 'internship':
        return (jobType.charAt(0).toUpperCase() + jobType.slice(1)).replace('_', '')
    }
}

const getPaymentPriceAndQuantity = (user, selectedPackage, isBuyingCredits) => {
    // Case of employer buying more credits than needed on payement step
    if (isBuyingCredits) {
        return {
            price: selectedPackage.price,
            quantity: selectedPackage.credits
        }
    } else {
        return {
            price: selectedPackage.price,
            quantity: selectedPackage.credits - user.number_of_credits
        }
    }
}

const getPayementValue = (user, selectedPackage, isBuyingCredits) => {
    if (isBuyingCredits) {
        return {
            value: selectedPackage.credits * selectedPackage.price
        }
    } else {
        return {
            value: (selectedPackage.credits - user.number_of_credits) * selectedPackage.price
        }
    }
}

const firstJobsPackages = CollectionHandler.Get('firstJobPackagesCollection')
const studentJobsPackages = CollectionHandler.Get('studentJobBoostPackagesCollection')
const internshipJobsPackages = CollectionHandler.Get('internshipPackagesCollection')

// Custom Purchase Information parsing depending on the jobType.
// Unfortunately, the cases are really different and parsing the payement
// data has to be done case by case.
const getLastPurchaseInformation = (transaction) => {
    const payementInformation = {
        items: [{}]
    }
    switch (transaction.job_type) {
    case 'FirstJob':
        const boughtPackage = firstJobsPackages.find(pck => transaction.package_name.includes(pck.title))
        payementInformation.items[0].item_id      = boughtPackage.name
        payementInformation.items[0].item_name    = boughtPackage.title
        payementInformation.items[0].price        = firstJobsPackages[0].price
        payementInformation.items[0].quantity     = transaction.credits_before
        payementInformation.value        = payementInformation.items[0].quantity * payementInformation.items[0].price
        break
    case 'Internship':
        if (transaction.package_name.includes('10')) {
            const intPackage = internshipJobsPackages.find((intPackage) => intPackage.name === 'internship-2')
            payementInformation.items[0].item_id  = intPackage.name
            payementInformation.items[0].price    = intPackage.price
        } else if (transaction.package_name.includes('3')) {
            const intPackage = internshipJobsPackages.find((intPackage) => intPackage.name === 'internship-4')
            payementInformation.items[0].item_id  = intPackage.name
            payementInformation.items[0].price    = intPackage.price
        } else {
            payementInformation.items[0].item_id  = internshipJobsPackages[0].name
            payementInformation.items[0].price    = internshipJobsPackages[0].price
        }
        payementInformation.items[0].item_name    = transaction.package_name
        payementInformation.items[0].quantity     = Math.abs(transaction.credit_transaction)
        payementInformation.value        = Math.abs(payementInformation.items[0].quantity * payementInformation.items[0].price)
        break
    case 'StudentJob':
    default:
        if (transaction.package_name.includes('10')) {
            const sjPackage = studentJobsPackages.find((sjPackage) => sjPackage.name === 'student_job-2')
            payementInformation.items[0].item_id  = sjPackage.name
            payementInformation.items[0].price    = sjPackage.price
        } else if (transaction.package_name.includes('3')) {
            const sjPackage = studentJobsPackages.find((sjPackage) => sjPackage.name === 'student_job-5')
            payementInformation.items[0].item_id  = sjPackage.name
            payementInformation.items[0].price    = sjPackage.price
        } else {
            payementInformation.items[0].item_id  = studentJobsPackages[0].name
            payementInformation.items[0].price    = studentJobsPackages[0].price
        }
        payementInformation.items[0].item_name    = transaction.package_name
        payementInformation.items[0].quantity     = Math.abs(transaction.credit_transaction)
        payementInformation.value        = Math.abs(payementInformation.items[0].quantity * payementInformation.items[0].price)
        break
    }
    payementInformation.items[0].item_category    = transaction.job_type || 'CREDITS'
    payementInformation.transaction_id   = transaction.id
    payementInformation.currency         = 'EUR'

    return payementInformation
}

const constructJobGeneralAnalyticsAttributes = (user, job, company) => {
    return {
        ...constructUserAnalyticsAttributes(user),
        ...constructStudentAnalyticsAttributes(user),
        ...constructJobAnalyticsAttributes(job),
        ...constructCompanyAnalyticsAttributes(company)
    }
}

const constructUserAnalyticsAttributes = (user) => {
    return {
        user_id: user?.id,
        user_type: user?.type
    }
}

const constructAuthentificationAnalyticsAttributes = (authentificationType, authentificationOrigin) => {
    return {
        login_type: authentificationType,
        login_source: getSourceName(authentificationOrigin)
    }
}

const constructMinimalStudentAnalyticsAttributes = (user) => {
    return {
        student_education_type: user?.education_type,
        student_background_type: user?.background_type,
        student_study_domain: user?.study_domain
    }
}

const constructAverageStudentAnalyticsAttributes = (user) => {
    return {
        student_education_type: user?.education_type,
        student_background_type: user?.background_type,
        student_study_domain: user?.study_domain,
        school_name: user?.school_name,
        school_location: user?.school_location
    }
}

const constructStudentAnalyticsAttributes = (user) => {
    return {
        student_education_type: user?.education_type,
        student_background_type: user?.background_type,
        student_study_domain: user?.study_domain,
        student_city: user?.city,
        student_zip: user?.zip,
        student_age: user?.age,
        student_status: user?.student_status,
        student_preferred_language: user?.language_preference,
        school_name: user?.school_name,
        school_location: user?.school_location
    }
}

const constructArticleAnalyticsAttributes = (article) => {
    return {
        article_id: article.id,
        article_tags: article.tag.map(elem => elem.value)
    }
}

const constructJobAnalyticsAttributes = (job) => {
    return {
        job_id: job.id,
        job_type: job.type,
        job_city: job.address?.city,
        job_zip: job.address?.zip,
        job_study_domain: job.field_of_study?.length ? job.field_of_study : undefined
    }
}

const constructJobTypeAnalyticsAttributes = (jobType) => {
    return {
        job_type: JOB_TYPES_LIST.includes(jobType) ? jobType : undefined
    }
}

const constructEmployerAnalyticsAttributes = (user, company) => {
    if (user?.type !== 'employer') return undefined

    return {
        company_id: company.id,
        company_name: company.name,
        company_sector: company.sector,
        company_size: company.size,
        company_city: company.address.city,
        company_zip: company.address.zip,
        special_license: user.gold_licence ? 'gold_licence' : user.silver_licence ? 'silver_licence' : null
    }
}

const constructCompanyAnalyticsAttributes = (company) => {
    return {
        company_id: company.id,
        company_name: company.name,
        company_sector: company.sector,
        company_size: company.size
    }
}

const constructFiltersAnalyticsAttributes = (filters) => {
    return {
        search_keywords: filters.title !== '' ? filters.title : undefined,
        job_study_domain: FiltersHelper.getValues(filters.studyDomain),
        job_city: FiltersHelper.getCityNames(filters.location),
        job_zip: FiltersHelper.getValues(filters.location),
        job_type: FiltersHelper.getValues(filters.jobType),
        job_schedule: FiltersHelper.getValues(filters.jobSchedule),
        company_size: FiltersHelper.getValues(filters.companySize),
        company_sector: FiltersHelper.getValues(filters.sector),
        quick_filter: filters.quickFilter
    }
}
// ===== END PRIVATE =====

export default {
    constructAccountUpdatedAnalyticsAttributes,
    constructArticleDetailsViewedAnalyticsAttributes,
    constructAverageStudentAnalyticsAttributes,
    constructBannerAnalyticsAttributes,
    constructBasicAnalyticsAttributes,
    constructFirstInternJobAnalyticsAttributes,
    constructIndexListViewedAnalyticsAttributes,
    constructJobDetailsViewedAnalyticsAttributes,
    constructJobFormCheckoutAnalyticsAttributes,
    constructKotDetailsViewedAnalyticsAttributes,
    constructLoginAnalyticsAttributes,
    constructModalCheckoutAnalyticsAttributes,
    constructPageViewedAnalyticsAttributes,
    constructPricingPagViewedeAnalyticsAttributes,
    constructPricingPageCheckoutAnalyticsAttributes,
    constructPurchaseAnalyticsAttributes,
    constructRegisterAnalyticsAttributes,
    constructRegisterEmployerAnalyticsAttributes,
    constructRegisterOwnerAnalyticsAttributes,
    constructRegisterStudentAnalyticsAttributes,
    constructSearchFilterAppliedAnalyticsAttributes,
    constructStudentJobAnalyticsAttributes,
    constructStudentStatusAnalyticsAttributes,
    constuctCompanyDetailsViewedAnalyticsAttributes,
    sendAnalyticsEvent,
    sendGTMEvent,
    constructStudentUserObjectForApply,
    constructStripeCheckoutAnalyticsAttributes,
    constructJobFunnelAnalyticsAttributes
    
}
