import React from 'react'
import NewArticle from '../../pages/user/NewArticle/NewArticle'

const AdminEditArticle = (props) => {
    return (
        <NewArticle
            user={props.user}
            companyId={props.company_id}
            action={props.mod}
            article={props.article}
            admin
            disableFooter
            disableNavbar
            companies={props.companies}
            linkedCompanies={props.linkedCompanies}
            featuredSchools={props.featuredSchools}
            linkedFeaturedSchools={props.linkedFeaturedSchools}
        />
    )
}
export default AdminEditArticle
