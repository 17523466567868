import axios from 'axios'
import ReactOnRails from 'react-on-rails'

import {
    ADMIN_COMPANY_EXPORT,
    ADMIN_USERS_EXPORT,
    ADMIN_DATA_USERS,
    ADMIN_AD_CREATE,
    ADMIN_AD_UPDATE,
    ADMIN_FEATURED_SCHOOL_UPDATE
} from './env'

const GenerateCompanyExport = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: ADMIN_COMPANY_EXPORT,
        method: 'post',
        data: data
    })
}

const GenerateDBUsersExport = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: ADMIN_USERS_EXPORT,
        method: 'post',
        data: data,
    })
}

const GetUsersData = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: ADMIN_DATA_USERS,
        method: 'post',
        data: data,
    })
}

const AdCreate = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: ADMIN_AD_CREATE,
        method: 'post',
        data: data
    })
}

const AdUpdate = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: ADMIN_AD_UPDATE,
        method: 'post',
        data: data
    })
}

const UpdateFeaturedSchool = (data) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    return axios({
        url: ADMIN_FEATURED_SCHOOL_UPDATE,
        method: 'patch',
        data: data
    })
}

export default {
    GenerateCompanyExport,
    GenerateDBUsersExport,
    GetUsersData,
    AdCreate,
    AdUpdate,
    UpdateFeaturedSchool
}