/* eslint-disable react/prop-types */
import React from 'react'
import { Box } from '@material-ui/core'

import MultipleSelectFilter from '../shared/MultipleSelectFilter/MultipleSelectFilter'
import ResetFilters from '../shared/ResetFilters/ResetFilters'

import { useStyles } from './AdminDataFilters_style'
import CollectionHandler from 'assets/CollectionHandler'

const AdminDataFilters = (props) => {
    const { children, filters, handleFilterChange, reset } = props
    const isHighSchoolCycle = filters.studyCycles.find(e => e.value === 'high_school')
    const classes = useStyles()
    const languageOptions = CollectionHandler.Get('defaultLanguageCollection')
    const provinceCollection = CollectionHandler.Get('provinceCollection')
    const studyFieldClustersCollection = CollectionHandler.Get('studyFieldClustersCollection')
    const highSchoolStudyTypeCollection = CollectionHandler.Get('highSchoolStudyTypeCollection')
    const studyCycleCollection = CollectionHandler.Get('studyCycleCollection')
    const studyFieldCollection = CollectionHandler.Get('studyFieldCollection')
    const graduationYearCollection = CollectionHandler.Get('graduationYearCollection')
    const accountCreatedYearCollection = CollectionHandler.Get('accountCreatedYearCollection')

    return (<>
        <Box className={classes.filterWrapper}>
            <Box className={classes.filterBox}>
                <MultipleSelectFilter
                    type='languagePreferences'
                    collection={languageOptions}
                    filters={filters}
                    setFilters={handleFilterChange}
                    width={250}
                    filterTextId='search_bar.language'
                />
            </Box>
            <Box className={classes.filterBox}>
                <MultipleSelectFilter
                    type='provinces'
                    collection={provinceCollection}
                    filters={filters}
                    setFilters={handleFilterChange}
                    width={250}
                    filterTextId='search_bar.provinces'
                />
            </Box>
            <Box className={classes.filterBox}>
                <MultipleSelectFilter
                    type='studyCycles'
                    collection={studyCycleCollection}
                    filters={filters}
                    setFilters={handleFilterChange}
                    width={250}
                    filterTextId='search_bar.study_cycles'
                />
            </Box>
            {isHighSchoolCycle &&
                <Box className={classes.filterBox}>
                    <MultipleSelectFilter
                        type='highSchoolTypes'
                        collection={highSchoolStudyTypeCollection}
                        filters={filters}
                        setFilters={handleFilterChange}
                        width={250}
                        filterTextId='search_bar.high_school_study_types'
                    />
                </Box>
            }
            <Box className={classes.filterBox}>
                <MultipleSelectFilter
                    type='studyClusters'
                    collection={studyFieldClustersCollection}
                    filters={filters}
                    setFilters={handleFilterChange}
                    width={250}
                    filterTextId='search_bar.study_clusters'
                />
            </Box>
            <Box className={classes.filterBox}>
                <MultipleSelectFilter
                    type='studyFields'
                    collection={studyFieldCollection}
                    filters={filters}
                    setFilters={handleFilterChange}
                    width={250}
                    filterTextId='search_bar.study_fields'
                />
            </Box>
            <Box className={classes.filterBox}>
                <MultipleSelectFilter
                    type='graduationYears'
                    collection={graduationYearCollection}
                    filters={filters}
                    setFilters={handleFilterChange}
                    width={250}
                    filterTextId='search_bar.graduation_year'
                />
            </Box>
            <Box className={classes.filterBox}>
                <MultipleSelectFilter
                    type='creationYears'
                    collection={accountCreatedYearCollection}
                    filters={filters}
                    setFilters={handleFilterChange}
                    width={250}
                    filterTextId='search_bar.creation_year'
                />
            </Box>
            <Box className={classes.filterBox}>
                <MultipleSelectFilter
                    type='lastLoginYears'
                    collection={accountCreatedYearCollection}
                    filters={filters}
                    setFilters={handleFilterChange}
                    width={250}
                    filterTextId='search_bar.last_login_year'
                />
            </Box>
            <ResetFilters reset={reset} />
            {children}
        </Box>
    </>)
}

export default AdminDataFilters
