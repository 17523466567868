import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        padding: 20,
        border: `1px solid ${theme.palette.fg.silver}`,
        borderRadius: 'var(--border-radius)',
        scrollbarWidth: 'none', // For Firefox
        '&::-webkit-scrollbar': {
            display: 'none' // For Chrome, Safari, and Edge
        },
    }
}))
