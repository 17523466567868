import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import ProgressCircle from 'components/ProgressCircle/ProgressCircle'
import { useStyles } from '../ApplyPopupComponent_style'

const RedirectionToPartnerMessage = (props) => {
    const classes = useStyles()

    return (
        <Box className={classes.redirectingToPartnerContainer}>
            <Typography variant='h2' component={'div'} className={classes.redirectingToPartnerTitle}>
                <FormattedMessage id='apply_modal.redirecting_to_partner.title' />
            </Typography>
            <Typography variant='h3' component={'div'} className={classes.redirectingToPartnerSubTitle}>
                <FormattedMessage id='apply_modal.redirecting_to_partner.subtitle' />
            </Typography>
            <Box className={classes.progressCircleBox}>
                <ProgressCircle small disableMargin/>
            </Box>
        </Box>
    )
}

export default RedirectionToPartnerMessage
