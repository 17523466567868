import React, { useEffect, useState } from 'react'

import Container from '@material-ui/core/Container'
import { Box, CircularProgress } from '@material-ui/core'
import { sample } from 'lodash'

import AdminAPI from 'api/AdminAPI'
import { useStyles } from './AdminDataPage_style'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import AdminDataFilters from 'components/Searchbar/AdminDataFilters/AdminDataFilters'
import FeedbackSnackbar from 'shared/components/FeedbackSnackbar/FeedbackSnackbar'
import Button from 'components/Button/Button'
import AdminDataTable from './components/AdminDataTable'
import { waitMessages, MAX_EXPORT_SIZE } from './settings'
import { useFirstRender } from 'hooks/useFirstRender'

const AdminDataPage = (props) => {
    const { user } = props
    const classes = useStyles()
    const firstRender = useFirstRender()
    const [results, setResults] = useState({ count: 0, items: [], estimate: 0 })
    const [isLoading, setIsLoading] = useState(false)
    const [newFiltersApplied, setNewFiltersApplied] = useState(false)
    const [feedback, setFeedback] = useState({ open: false, message: null, severity: null })
    const [filters, setFilters] = useState({
        graduationYears: [],
        creationYears: [],
        lastLoginYears: [],
        languagePreferences: [],
        provinces: [],
        studyClusters: [],
        studyCycles: [],
        highSchoolTypes: [],
        studyFields: [],
    })

    useEffect(() => {
        if (!firstRender) {
            setNewFiltersApplied(true)
        }
    }, [filters])

    const initState = (reset) => {
        let searchFields = {}

        if (reset) {
            searchFields.languagePreferences = []
            searchFields.graduationYears = []
            searchFields.creationYears = []
            searchFields.lastLoginYears = []
            searchFields.provinces = []
            searchFields.studyClusters = []
            searchFields.studyCycles = []
            searchFields.highSchoolTypes = []
            searchFields.studyFields = []
        }

        return searchFields
    }

    const closeFeedback = () => setFeedback({ ...feedback, open: false })

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters)
    }

    const reset = () => {
        const newState = initState(true)
        setFilters(newState)
    }

    const formatFiltersForApiCall = (filters) => {
        const isHighSchoolCycle = filters.studyCycles.find(e => e.value === 'high_school')
        return {
            languages: filters.languagePreferences.map(lang => lang.value),
            years: filters.graduationYears.map(lang => lang.value),
            creation_years: filters.creationYears.map(lang => lang.value),
            last_login_years: filters.lastLoginYears.map(lang => lang.value),
            provinces: filters.provinces.map(lang => lang.value),
            study_clusters: filters.studyClusters.map(lang => lang.value),
            study_cycles: filters.studyCycles.map(lang => lang.value),
            high_school_types: (isHighSchoolCycle ? filters.highSchoolTypes.map(lang => lang.value) : []),
            study_fields: filters.studyFields.map(lang => lang.value),
        }
    }

    const handleApiCall = () => {
        setIsLoading(true)
        setNewFiltersApplied(false)
        const formattedFilters = formatFiltersForApiCall(filters)

        AdminAPI.GetUsersData(formattedFilters).then((response) => {
            const isSuccess = response.data.is_success
            if (isSuccess) {
                setIsLoading(false)
                setResults({
                    count: response.data.data.count,
                    estimate: response.data.data.estimate,
                    items: response.data.data.items
                })
            } else {
                setIsLoading(false)
                setFeedback({ open: true, message: response.data.data.message, severity: 'error' })
            }
        })
    }

    const handleUsersExport = () => {
        setIsLoading(true)
        const formattedFilters = formatFiltersForApiCall(filters)
        const data = {
            recipient: user.email,
            ...formattedFilters
        }

        AdminAPI.GenerateDBUsersExport(data).then((response) => {
            const isSuccess = response.data.is_success
            setIsLoading(false)
            setFeedback({
                open: true,
                message: response.data.data.message,
                severity: isSuccess ? 'success' : 'error'
            })
        })
    }

    return (
        <PageWrapper user={props.user} disableFooter disableNavbar>
            <FeedbackSnackbar
                open={feedback.open}
                message={feedback.message}
                severity={feedback.severity}
                closeFeedback={closeFeedback}
            />
            <RouteMapLink redirectionUrl={'/en/admin'}>
				Go to Admin
            </RouteMapLink>
            <Container maxWidth={false} className={classes.container}>
                <h1 className={classes.title}>The only DB export tool you'll ever need !</h1>
                <h2>(1) Filter (2) Verify (3) Export 📨</h2>
                {isLoading &&
					<Box className={classes.loadingMessage}>
					    <CircularProgress color='primary' size={20} />
					    <p>Loading data... ⏳ {sample(waitMessages)}</p>
                    </Box>
                }
                <AdminDataFilters
                    filters={filters}
                    handleFilterChange={handleFilterChange}
                    reset={reset}
                >
                    <Button color='primary' disabled={isLoading || !newFiltersApplied} onClick={handleApiCall}>
                        {newFiltersApplied ? 'Apply filters' : 'Filters applied'}
                    </Button>
                </AdminDataFilters>
                <Box className={classes.totals} my={2}>
                    <p className={classes.sampleText}>
						If the results are lower than 15K we calculate the exact amount, otherwise we calculate an estimate (99% accuracy).
                    </p>
                </Box>
                <Box className={classes.totals} my={2}>
                    <h3 className={classes.totalsText}>
                        {results.count
                            ? <>Results: <span>{isLoading ? '...' : results.count.toLocaleString('be-NL')}</span> students matching your criteria 💎</>
                            : <>Estimated: <span>{isLoading ? '...' : results.estimate.toLocaleString('be-NL')}</span> students matching your criteria 💎</>
                        }
                    </h3>
                    <p className={classes.sampleText}>
						Does <span>not include unsubbed</span> users. The table below shows a <span>random sample of 25 students</span> matching your criteria.
                    </p>
                </Box>
                <AdminDataTable
                    results={results}
                />
                <Box my={1} className={classes.exportButton}>
                    <p className={classes.exportEmail}>
						Max export size: <span>{MAX_EXPORT_SIZE.toLocaleString('be-NL')} students</span>.
						Estimated export time: <span>1 min / 10K records</span>.
						Export will be sent to current account: <span>{user.email}</span>.
                    </p>
                    <Button color='primary' disabled={isLoading || results.count > MAX_EXPORT_SIZE} onClick={handleUsersExport}>
                        Export my data
                    </Button>
                </Box>
            </Container>
        </PageWrapper>
    )
}

export default AdminDataPage
