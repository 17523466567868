import React from 'react'
import { Box, FormLabel, Typography } from '@material-ui/core'
import ReactQuill from 'react-quill'
import Button from 'components/Button/Button'
import IconFactory from 'icons/IconFactory/IconFactory'
import StringInput from '../StringInput/StringInput'
import UploadImageV2 from 'modals/UploadImageV2/UploadImageV2'
import CollectionHandler from 'assets/CollectionHandler'
import TagMultiSelect from 'components/TagMultiSelect/TagMultiSelect'
import { useStyles } from '../../AdminEditFeaturedSchoolView_style'

const FacultyFormInput = (props) => {
    const { index, items, setItems, deleteItem } = props
    const classes = useStyles()
    const studyDomainCollection = CollectionHandler.Get('studyDomainCollection')

    const handleItemChange = (name) => {
        items[index][name] = event.target.value
        setItems([...items])
    }

    const handleImageAltChange = (e) => {
        items[index].image = { ...items[index].image, alt: e.target.value }
        setItems([...items])
    }

    const handleQuillChange = (value, name) => {
        items[index][name] = value
        setItems([...items])
    }

    const handleSelectedItems = (selectedItems, attribute) => {
        const formattedItems = selectedItems.map(item => item.value)
        items[index][attribute] = formattedItems
        setItems([...items])
    }

    const setImage = (newImage) => {
        items[index].image = newImage
        setItems([...items])
    }

    return (
        <Box className={classes.itemWrapper}>
            <StringInput
                title='Faculty title'
                className={classes.stringInput}
                state={items[index]}
                value='title'
                handleChange={() => handleItemChange('title')}
            />
            <Box className={classes.marginTopBox}>
                <Typography>Faculty description</Typography>
                <ReactQuill
                    modules={{ clipboard: { matchVisual: false } }}
                    className={classes.quillStyle}
                    value={items[index].description || ''}
                    onChange={(quillValue) => handleQuillChange(quillValue, 'description')}
                />
            </Box>
            <Box className={classes.marginTopBox}>
                <FormLabel>Available study domains</FormLabel>
                <TagMultiSelect
                    collection={studyDomainCollection}
                    selectedItems={items[index].study_domains.map(studyDomain => { return { value: studyDomain } })}
                    setSelectedItems={(items) => handleSelectedItems(items, 'study_domains')}
                />
            </Box>
            <StringInput
                title='Faculty URL'
                className={classes.stringInput}
                state={items[index]}
                value='url'
                handleChange={() => handleItemChange('url')}
            />
            <Typography>Image</Typography>
            <UploadImageV2
                image={items[index].image}
                setImage={setImage}
                title='Upload your image'
            />
            {items[index].image &&
                <StringInput
                    title='Image alt'
                    className={classes.stringInput}
                    state={items[index].image}
                    value='alt'
                    handleChange={handleImageAltChange}
                />
            }
            <Box className={classes.marginTopBox}>
                <Button onClick={() => deleteItem(index)} size='small' variant='secondary'>
                    Delete this faculty
                    <IconFactory color='black' icon='delete' />
                </Button>
            </Box>
        </Box>
    )
}

export default FacultyFormInput
