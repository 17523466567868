import React, { useState } from 'react'

import { useStyles } from '../../Searchbar_v2_style'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import RouteMap from 'assets/RouteMap.js'
import CollectionHandler from 'assets/CollectionHandler.js'
import QuickFilterTagOption from '../QuickFilterTagOption/QuickFilterTagOption'

const QuickFilterTag = (props) => {
    const classes = useStyles()
    const { page, quickFilterOption, quickFilterSelection, setFilters, filters } = props
    const quickFilterCollection = CollectionHandler.Get('quickFilterCollection')
    const [isHoverText, setIsHoverText] = useState(null)

    const baseUrl = RouteMap.TrailingQuickFilterTag(page, quickFilterOption.value, quickFilterCollection[page])
    const params = window.location.search.replace(/quick_filter=[^&]*&?/, '')
        .replace(/\?$/, '')
        .replace(/[?&]$/, '')
    const redirectionUrl = `${baseUrl}${params}`

    const isSelected = quickFilterSelection === quickFilterOption.value

    const setQuickFilter = (selectedQuickFilter) => {
        window.history.pushState({}, '', baseUrl)

        if (selectedQuickFilter === filters.quickFilter) {
            setFilters({
                ...filters,
                quickFilter: ''
            })
        } else {
            setFilters({
                ...filters,
                quickFilter: selectedQuickFilter
            })
        }
    }

    return (
        <RouteMapLink
            key={quickFilterOption.value}
            className={classes.quickFilterOption}
            redirectionUrl={redirectionUrl}
            onClick={() => setQuickFilter(quickFilterOption.value)}
        >
            <QuickFilterTagOption
                isSelected={isSelected}
                isHoverText={isHoverText}
                setIsHoverText={setIsHoverText}
                quickFilterOption={quickFilterOption}
            />
        </RouteMapLink>
    )
}

export default QuickFilterTag
