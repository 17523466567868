import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    alertCreationForm: {
        display: 'flex',
        flexDirection: 'column'
    },
    itemTypeSelection: {
        marginBottom: 40,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20
        }
    },
    itemChips: {
        display: 'flex',
        gap: 20,
        flexWrap: 'wrap',
        marginTop: 20,
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20
        }
    },
    chipIcon: {
        color: theme.palette.fg.black
    },
    chip: {
        fontFamily: "'GreedStandard-Medium', sans-serif",
        width: 'fit-content',
        padding: '20px 12px',
        cursor: 'pointer',
        borderRadius: 'var(--border-radius)',
        fontSize: '0.875rem',
        transition: 'transform ease-in-out 0.05s'
    },
    hoverChip: {
        '&:hover': {
            backgroundColor: theme.palette.bg.purple,
            color: theme.palette.fg.white,
            boxShadow: `4px 4px 0px 0px ${theme.palette.bg.black}`,
            border: `2px solid ${theme.palette.fg.black}`,
            '& >svg': {
                color: theme.palette.fg.white
            }
        }
    },
    selectedChip: {
        backgroundColor: theme.palette.bg.purple,
        color: theme.palette.fg.white,
        border: `2px solid ${theme.palette.fg.black}`,
        '& >svg': {
            color: theme.palette.fg.white
        }
    },
    alertFiltersContainer: {
        position: 'relative',
    },
    locationSectionContainer: {
        width: '100%',
        marginTop: 20,
        marginBottom: 40,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20
        }
    },
    locationField: {
        width: '100%',
        marginTop: 20,
        [theme.breakpoints.up('sm')]: {
            maxWidth: 380,
        }
    },
    fieldOfStudySectionContainer: {
        marginTop: 20
    },
    checkboxListContainer: {
        marginTop: 20
    },
    jobScheduleSectionContainer: {
        marginTop: 20,
        marginBottom: 40,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20
        }
    },
    tagsMultiSelectContainer: {
        marginTop: 20
    },
    companySizeSectionContainer: {
        marginTop: 20,
        marginBottom: 40,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20
        }
    },
    sectorSectionContainer: {
        marginTop: 20,
        marginBottom: 40,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20
        }
    },
    sectorField: {
        width: '100%',
        marginTop: 20,
        [theme.breakpoints.up('sm')]: {
            maxWidth: 380,
        }
    },
    quickFilterContainer: {
        marginTop: 20,
        marginBottom: 40
    },
    quickFilters: {
        display: 'flex',
        gap: 30,
        width: 650,
        maxWidth: '100%',
        flexWrap: 'wrap',
        marginTop: 20,
    },
    quickFilterOption: {
        width: 75,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 4,
        textAlign: 'center',
        cursor: 'pointer',
        color: 'var(--black)'
    },
    alertSubmitContainer: {
        position: 'sticky',
        bottom: -20,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: '20px 0',
        backgroundColor: theme.palette.bg.white,
        borderTop: '1px solid var(--background-silver)'
    },
    submitSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 4
    },
    submitExplanation: {
        fontSize: 14
    },
    warningColor: {
        color: theme.palette.bg.orange
    }
}))
