import React from 'react'
import { Box, Typography } from '@material-ui/core'
import ReactQuill from 'react-quill'
import Button from 'components/Button/Button'
import IconFactory from 'icons/IconFactory/IconFactory'
import StringInput from '../StringInput/StringInput'
import UploadImageV2 from 'modals/UploadImageV2/UploadImageV2'
import { useStyles } from '../../AdminEditFeaturedSchoolView_style'
import SingleCityFilter from 'components/Searchbar/shared/SingleCityFilter/SingleCityFilter'

const FeaturedCampusFormInput = (props) => {
    const { index, items, setItems, deleteItem } = props
    const classes = useStyles()

    const handleItemChange = (name) => {
        console.log('handleItemChange', name, event)
        items[index][name] = event.target.value
        setItems([...items])
    }

    const handleImageAltChange = (e) => {
        items[index].image = { ...items[index].image, alt: e.target.value }
        setItems([...items])
    }

    const handleQuillChange = (value, name) => {
        items[index][name] = value
        setItems([...items])
    }

    const handleCityChange = (value) => {
        items[index].city = value
        setItems([...items])
    }

    const setImage = (newImage) => {
        items[index].image = newImage
        setItems([...items])
    }

    return (
        <Box className={classes.itemWrapper}>
            <StringInput
                title='title'
                className={classes.stringInput}
                state={items[index]}
                value='title'
                handleChange={() => handleItemChange('title')}
            />
            <Box className={classes.marginTopBox}>
                <Typography>description</Typography>
                <ReactQuill
                    modules={{ clipboard: { matchVisual: false } }}
                    className={classes.quillStyle}
                    value={items[index].description || ''}
                    onChange={(quillValue) => handleQuillChange(quillValue, 'description')}
                />
            </Box>
            <StringInput
                title='URL'
                className={classes.stringInput}
                state={items[index]}
                value='url'
                handleChange={() => handleItemChange('url')}
            />
            <Box className={classes.itemWrapper}>
                <SingleCityFilter
                    city={items[index].city}
                    handleCityChange={handleCityChange}
                />
            </Box>
            <Typography>Image</Typography>
            <UploadImageV2
                image={items[index].image}
                setImage={setImage}
                title='Upload your image'
            />
            {items[index].image &&
                <StringInput
                    title='Image alt'
                    className={classes.stringInput}
                    state={items[index].image}
                    value='alt'
                    handleChange={handleImageAltChange}
                />
            }
            <Box className={classes.marginTopBox}>
                <Button onClick={() => deleteItem(index)} size='small' variant='secondary'>
                    Delete this featured campus
                    <IconFactory color='black' icon='delete' />
                </Button>
            </Box>
        </Box>
    )
}

export default FeaturedCampusFormInput
