import { makeStyles } from '@material-ui/core/styles'

export const useStyles = (selectedColor) =>
  makeStyles((theme) => ({
    card: {
      width: '283px',
      minWidth: '283px',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      border: `1px solid ${theme.palette.fg['silver']}`,
      borderRadius: '20px',
      padding: '40px',
      transition: 'background-color 0.3s ease',
      '&:hover': {
        backgroundColor: selectedColor,
      },
      [theme.breakpoints.up('lg')]: {
        minWidth: 'unset',
        width: 'unset',
        flex: 1,
        maxWidth: '283px',
      },
    },

        // Content
        cardContent: {
            padding: '0px 0px 16px 0px'
        },

        iconContainer: {
            width: '64px',
            height: '64px',
            backgroundColor: selectedColor,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },

    title: {
      padding: '16px 0px',
      color: theme.palette.fg['black'],
      fontFamily: 'GreedStandard-Bold',
      fontSize: '24px',
      lineHeight: '32px',
    },

        listContainer: {
            margin: '0px',
            padding: '0px',
            listStyle: 'none',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '16px'
        },

    item: {
      color: theme.palette.fg['black'],
      fontSize: '16px',
      lineHeight: '18px',
    },

        // Actions
        cardActions: {
            padding: '0px'
        },

        linkContainer: {
            display: 'flex',
            alignItems: 'center',
            columnGap: '8px',
            textDecoration: 'none !important'
        },

    linkText: {
      color: theme.palette.fg['black'],
      fontSize: '16px',
      lineHeight: '18px',
    },

    linkIconContainer: {
      width: '24px',
      height: '24px',
      minWidth: 24,
      borderRadius: '50px',
      backgroundColor: theme.palette.bg['black'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }))()
