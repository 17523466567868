import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    customAlert: {
        borderRadius: 'var(--border-radius)',
        boxShadow: 'var(--box-shadow)'
    },
    customSuccess: {
        color: 'var(--foreground-black)',
        backgroundColor: 'var(--background-green)'
    }
}))
