import React from 'react'
import { Dialog } from '@material-ui/core'

import AlertForm from 'shared/components/AlertForm/AlertForm'
import { useStyles } from './JobAlertEditModal_style'

const JobAlertEditModal = (props) => {
    const { alert, handleSubmit, isOpen, closeModal } = props
    const classes = useStyles()

    return (
        <Dialog
            open={isOpen}
            onClose={closeModal}
            fullWidth
            PaperProps={{ className: classes.dialogContainer }}
        >
            <AlertForm
                alert={alert}
                handleSubmit={handleSubmit}
                formType={'edit'}
            />
        </Dialog>
    )
}

export default JobAlertEditModal
