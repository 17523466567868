import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Image, Transformation } from 'cloudinary-react'
import { Box, Container, Typography } from '@material-ui/core'

import { useStyles } from './AlertCreationBanner_style'

const AlertCreationBanner = (props) => {
    const classes = useStyles()
    return (
        <Container maxWidth='lg' className={classes.whyAlertContainer}>
            <Typography className={classes.whyAlertTitle} component='h2'>
                <FormattedMessage id='alert_creation_page.why_alert.title' />
            </Typography>
            <Box className={classes.whyAlertsBenefits}>
                <Box className={classes.whyAlertsBenefit}>
                    <Image
                        cloudName='studentbe'
                        publicId='assets/job_alerts_benefits_1'
                        alt='student.be benefit of automated search'
                        className={classes.whyAlertsImage}
                    >
                        <Transformation flags='progressive' fetchFormat='auto' quality='auto:best' width={400} crop='fill' />
                    </Image>
                    <Typography className={classes.whyAlertsBenefitTitle} variant='h6' style={{ backgroundColor: 'var(--background-yellow)' }}>
                        <FormattedMessage id='alert_creation_page.why_alert.automated_search.title' />
                    </Typography>
                    <Typography className={classes.whyAlertsBenefitExplanation} variant='body'>
                        <FormattedMessage id='alert_creation_page.why_alert.automated_search.explanation' />
                    </Typography>
                </Box>
                <Box className={classes.whyAlertsBenefit}>
                    <Image
                        cloudName='studentbe'
                        publicId='assets/job_alerts_benefits_2'
                        alt='student.be benefit of customisation'
                        className={classes.whyAlertsImage}
                    >
                        <Transformation flags='progressive' fetchFormat='auto' quality='auto:best' width={400} crop='fill' />
                    </Image>
                    <Typography className={classes.whyAlertsBenefitTitle} variant='h6' style={{ backgroundColor: 'var(--background-orange)' }}>
                        <FormattedMessage id='alert_creation_page.why_alert.customisation.title' />
                    </Typography>
                    <Typography className={classes.whyAlertsBenefitExplanation} variant='body'>
                        <FormattedMessage id='alert_creation_page.why_alert.customisation.explanation' />
                    </Typography>
                </Box>
                <Box className={classes.whyAlertsBenefit}>
                    <Image
                        cloudName='studentbe'
                        publicId='assets/job_alerts_benefits_3'
                        alt='student.be benefit of more opportunities'
                        className={classes.whyAlertsImage}
                    >
                        <Transformation flags='progressive' fetchFormat='auto' quality='auto:best' width={400} crop='fill' />
                    </Image>
                    <Typography className={classes.whyAlertsBenefitTitle} variant='h6' style={{ backgroundColor: 'var(--background-green)' }}>
                        <FormattedMessage id='alert_creation_page.why_alert.more_opportunities.title' />
                    </Typography>
                    <Typography className={classes.whyAlertsBenefitExplanation} variant='body'>
                        <FormattedMessage id='alert_creation_page.why_alert.more_opportunities.explanation' />
                    </Typography>
                </Box>
            </Box>
        </Container>
    )
}

export default AlertCreationBanner
