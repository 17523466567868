import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    alertCardContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minHeight: '100px'
    },
    checkContainer: {
        width: '35px'
    },
    alertCard: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            height: '100%'
        }
    },
    alertCardColumn: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            height: '100%',
            padding: '0 26px',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 16
        }
    },
    alertJobType: {
        borderRadius: '5px 0 0 5px',
        textTransform: 'capitalize',
        borderRight: '1px solid lightgrey',
        [theme.breakpoints.up('md')]: {
            width: 333,
            color: 'black'
        },
        [theme.breakpoints.down('md')]: {
            height: '100%',
            padding: '0 20px'
        }
    },
    iconBubble: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 50,
        width: 64,
        height: 64
    },
    jobType: {
        fontSize: 16
    },
    gridContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: '0 30px',
        gap: 8,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            padding: 20
        }
    },
    gridSubContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
        gap: 8,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center'
        }
    },
    alertCreationDate: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    hoverActionContainer: {
        width: 160,
        padding: 20,
        height: '100%',
        display: 'flex',
        gap: 20,
        justifyContent: 'center',
        alignItems: 'center',
        borderLeft: '1px solid lightgrey',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            width: 80,
            minHeight: 160
        }
    },
    hoverAction: {
        position: 'relative',
        cursor: 'pointer'
    },
    hoverActionDescription: {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: '12px',
        bottom: -16,
        [theme.breakpoints.down('sm')]: {
            bottom: -12
        }
    },
    matchingJobsButton: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        gap: 8,
        '&:hover > span, &:hover > svg': {
            color: 'var(--foreground-purple) !important'
        }
    },
    iconButton: {
        width: 30,
        height: 30
    },
    iconBubbleSmall: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 50,
        width: 20,
        height: 20
    },
    mobileTypeContainer: {
        display: 'flex',
        alignItems: 'center',
        textTransform: 'capitalize',
        gap: 8
    },
    inactive: {
        color: theme.palette.fg.greySlate
    }
}))
