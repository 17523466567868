import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    banner: {
        display: 'flex',
        width: '100%',
        height: 350,
        backgroundColor: 'var(--background-purple)',
        backgroundImage: 'url(\'https://res.cloudinary.com/studentbe/image/upload/v1721138136/student-be-assets/Mask_group.png\')',
        [theme.breakpoints.down('sm')]: {
            height: 'fit-content',
        },
    },
    bannerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 50
    },
    bannerText: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    pageTitle: {
        fontSize: 50,
        marginBottom: 12,
        color: 'var(--foreground-white)',
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            fontSize: 40,
            textAlign: 'center',
            lineHeight: 1.2,
            maxWidth: 280
        }
    },
    pageSubTitle: {
        fontSize: 18,
        color: 'var(--foreground-white)',
        maxWidth: '75%',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            textAlign: 'center'
        }
    },
    alertFormContainer: {
        marginTop: 60,
        [theme.breakpoints.down('sm')]: {
            marginTop: 30
        }
    }
}))
