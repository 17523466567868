/* eslint-disable react/prop-types */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Image } from 'cloudinary-react'

import { useStyles } from './Footer_style'
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Hidden from '@material-ui/core/Hidden'

import RouteMap from 'assets/RouteMap.js'
import SVGFactory from 'icons/SVGFactory/SVGFactory'
import FooterCitiesList from './components/FooterCitiesList/FooterCitiesList'
import LangSelect from 'inputs/LangSelect/LangSelect'

const Footer = (props) => {
    const { classes, paramsForUrlTranslation, disableFooterLists = false } = props
    const locale = RouteMap.GetLocaleFromUrl()

    const targetPageOrLogin = (targetUrl) => {
        if (props.user) {
            return targetUrl
        } else {
            return `${RouteMap.Page('login')}?userType=employer&redirect=${targetUrl}`
        }
    }

    const getInstagramAccountUrl = () => {
        switch (locale) {
        case 'fr':
            return 'https://www.instagram.com/student__fr/'
        case 'en':
        case 'nl':
            return 'https://www.instagram.com/student__nl/'
        }
    }

    const getTiktokAccountUrl = () => {
        switch (locale) {
        case 'fr':
            return 'https://www.tiktok.com/@student.be_fr'
        case 'en':
        case 'nl':
            return 'https://www.tiktok.com/@student_nl'
        }
    }

    const getEbookUrlPerLanguage = (jobType) => {
        if (jobType === 'student_job') {
            switch (locale) {
            case 'fr':
                return 'https://www.student.be/fr/student-life/e-book-salaire-job-etudiant-tout-ce-qu-il-faut-savoir/'
            case 'en':
            case 'nl':
                return 'https://www.student.be/nl/student-life/e-book-studentenloon-alles-wat-je-moet-weten-over-je-loon-als-student/'
            }
        } else if (jobType === 'first_job') {
            switch (locale) {
            case 'fr':
                return 'https://www.student.be/fr/student-life/e-book-premier-salaire-tout-ce-qu-il-faut-savoir-en-tant-que-jeune-travailleur/'
            case 'en':
            case 'nl':
                return 'https://www.student.be/nl/student-life/e-book-starterlonen-alles-wat-je-moet-weten-over-je-loon-als-starter/'
            }
        }
    }

    return (
        <>
            {/* Cities list */}
            {!disableFooterLists && <FooterCitiesList />}

            {/* FOOTER 2 */}
            <Box className={classes.footerContainer}>
                <Box className={classes.wrapperFooter}>
                    <Box className={classes.wrapperItemFirst}>
                        <Image
                            className={classes.logo}
                            cloudName='studentbe'
                            publicId='student-be-logos/Student_2024_Logo_Main.png'
                            alt='Student.be logo'
                        />
                        <Box className={classes.footerFollowUsContainer}>
                            <Typography component='p' variant='text' className={classes.textFollowUs}>
                                <FormattedMessage id='page_footer_navbar_data.footer.follow_us' />{':'}
                            </Typography>
                            <Box className={classes.iconsContainer}>
                                <IconButton href={getTiktokAccountUrl()} target='_blank' rel='noopener noreferrer' className={classes.iconButton}>
                                    <SVGFactory skipSvgWrapper height="40px" width="24px" {...props} name="tiktok" />
                                </IconButton>
                                <IconButton href='https://www.linkedin.com/company/student-be' target='_blank' rel='noopener noreferrer' className={classes.iconButton}>
                                    <SVGFactory skipSvgWrapper height="40px" width="24px" {...props} name="linkedin" />
                                </IconButton>
                                <IconButton href={getInstagramAccountUrl()} target='_blank' rel='noopener noreferrer' className={classes.iconButton}>
                                    <SVGFactory skipSvgWrapper height="40px" width="40px" {...props} name="instagram" />
                                </IconButton>
                                <IconButton href='https://www.facebook.com/student.be/' target='_blank' rel='noopener noreferrer' className={classes.iconButton}>
                                    <SVGFactory skipSvgWrapper height="40px" width="24px" {...props} name="facebook" />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.wrapperItemSecondAndThird}>
                        <Box className={classes.wrapperItemSecond}>
                            <Box className={classes.wrapperSubItemSecond} >
                                <Typography variant='h1' component='p' className={classes.titleColumn}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.employer' />
                                </Typography>
                                <Link href={RouteMap.Page('employers')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.our_approach' />
                                </Link>
                                <Link href={RouteMap.Page('contact-employer')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.contact_us' />
                                </Link>
                                <Link href={RouteMap.Page('publish-a-student-job')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.publish_student_job' />
                                </Link>
                                <Link href={targetPageOrLogin(RouteMap.Page('users/internships/new'))} component='a' color='initial' className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.publish_internship' />
                                </Link>
                                <Link href={targetPageOrLogin(RouteMap.Page('users/first_jobs/new'))} component='a' color='initial' className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.publish_job' />
                                </Link>
                                <Link href={RouteMap.Page('payment')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.package_and_price' />
                                </Link>
                                <Link href={RouteMap.ArticleCategory('employer')} component='a' color='initial' className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.article_for_employers' />
                                </Link>
                            </Box>
                            <Box className={classes.wrapperSubItemSecond} >
                                <Typography variant='h1' component='p' className={classes.titleColumn}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.employer_kot' />
                                </Typography>
                                {props.user?.type !== 'employer' && (
                                    <Link
                                        component='a'
                                        color='initial'
                                        href={props.user ? RouteMap.Page('users/kots/new') : `${RouteMap.Page('login')}?redirect=${RouteMap.Page('users/kots/new')}`}
                                        className={classes.link}
                                    >
                                        <FormattedMessage id='page_footer_navbar_data.footer.publish_kot' />
                                    </Link>
                                )}
                                <Link href={RouteMap.Page('kot-owners')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.kot_terms' />
                                </Link>
                            </Box>
                        </Box>
                        <Box className={classes.wrapperItemThird}>
                            <Typography variant='h1' component='p' className={classes.titleColumn}>
                                <FormattedMessage id='page_footer_navbar_data.footer.students_and_young_graduate_part1' />
                            </Typography>
                            <Typography variant='h1' component='p' className={classes.titleColumn}>
                                <FormattedMessage id='page_footer_navbar_data.footer.students_and_young_graduate_part2' />
                            </Typography>
                            <Link href={RouteMap.Page('create-your-job-alert')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                <FormattedMessage id='page_footer_navbar_data.footer.create_job_alert' />
                            </Link>
                            <Link href={RouteMap.Page('student-job')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                <FormattedMessage id='page_footer_navbar_data.footer.about_student_jobs_temp' />
                            </Link>
                            <Link href={RouteMap.Page('summer-job')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                <FormattedMessage id='page_footer_navbar_data.footer.about_summer_jobs_temp' />
                            </Link>
                            <Link href={RouteMap.Page('internship')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                <FormattedMessage id='page_footer_navbar_data.footer.about_internship_temp' />
                            </Link>
                            <Link href={RouteMap.Page('starter-job')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                <FormattedMessage id='page_footer_navbar_data.footer.about_first_job_temp' />
                            </Link>
                            <Link href={RouteMap.Page('companies')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                <FormattedMessage id='page_footer_navbar_data.footer.about_discover_company' />
                            </Link>
                            <Link href={RouteMap.Page('student-life/categories/education')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                <FormattedMessage id='page_footer_navbar_data.education' />
                            </Link>
                            <Link href={RouteMap.Page('student-room')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                <FormattedMessage id='page_footer_navbar_data.footer.about_kot_temp' />
                            </Link>
                            <Link href={RouteMap.Page('only-for-students')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                <FormattedMessage id='page_footer_navbar_data.footer.promotions' />
                            </Link>
                            <Link href={RouteMap.Page('login')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                <FormattedMessage id='page_footer_navbar_data.footer.register' />
                            </Link>
                        </Box>
                        <Box className={classes.wrapperItemFourth}>
                            <Box className={classes.wrapperSubItemSecond}>
                                <Typography variant='h1' component='p' className={classes.titleColumn}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.student_be' />
                                </Typography>
                                <Link href={RouteMap.Page('mybusinessschoolevent')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    Business school fair
                                </Link>
                                <Link href={RouteMap.Page('about')} component='a' color='initial' hreflang={`${locale}-BE`} className={`${classes.link}`}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.about_us' />
                                </Link>
                                <Link href={RouteMap.Page('podcast')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.podcast' />
                                </Link>
                                <Link href={RouteMap.Page('press')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.about_press' />
                                </Link>
                                <Link href={RouteMap.Page('faq')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.about_faq' />
                                </Link>
                                <Link href={RouteMap.Page('terms')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.terms' />
                                </Link>
                                <Link href={RouteMap.Page('contact')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.contact_us' />
                                </Link>
                                <Link href={RouteMap.Page('login')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.register' />
                                </Link>
                            </Box>
                            <Box className={classes.wrapperSubItemSecond}>
                                <Typography variant='h1' component='p' className={classes.titleColumn}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.freebies' />
                                </Typography>
                                <Link href={getEbookUrlPerLanguage('student_job')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.student_salary_ebook' />
                                </Link>
                                <Link href={getEbookUrlPerLanguage('first_job')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.starter_salary_ebook' />
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                    {/* Mobile */}
                    <Box className={classes.wrapperItemSecondAndThirdMobile}>
                        <Box className={classes.wrapperRowMobile}>
                            <Box className={classes.wrapperRowItemMobile}>
                                <Typography variant='h1' component='p' className={classes.titleColumn}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.employer' />
                                </Typography>
                                <Link href={RouteMap.Page('employers')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.our_approach' />
                                </Link>
                                <Link href={RouteMap.Page('contact-employer')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.contact_us' />
                                </Link>
                                <Link href={RouteMap.Page('publish-a-student-job')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.publish_student_job' />
                                </Link>
                                <Link href={targetPageOrLogin(RouteMap.Page('users/internships/new'))} component='a' color='initial' className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.publish_internship' />
                                </Link>
                                <Link href={targetPageOrLogin(RouteMap.Page('users/first_jobs/new'))} component='a' color='initial' className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.publish_job' />
                                </Link>
                                <Link href={RouteMap.Page('payment')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.package_and_price' />
                                </Link>
                                <Link href={RouteMap.ArticleCategory('employer')} component='a' color='initial' className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.article_for_employers' />
                                </Link>
                            </Box>
                            <Box className={classes.wrapperRowItemMobile}>
                                <Typography variant='h1' component='p' className={classes.titleColumn}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.students_and_young_graduate_part1' />
                                </Typography>
                                <Typography variant='h1' component='p' className={classes.titleColumn}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.students_and_young_graduate_part2' />
                                </Typography>
                                <Link href={RouteMap.Page('create-your-job-alert')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.create_job_alert' />
                                </Link>
                                <Link href={RouteMap.Page('student-job')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.about_student_jobs_temp' />
                                </Link>
                                <Link href={RouteMap.Page('summer-job')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.about_summer_jobs_temp' />
                                </Link>
                                <Link href={RouteMap.Page('internship')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.about_internship_temp' />
                                </Link>
                                <Link href={RouteMap.Page('starter-job')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.about_first_job_temp' />
                                </Link>
                                <Link href={RouteMap.Page('companies')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.about_discover_company' />
                                </Link>
                                <Link href={RouteMap.Page('student-life/categories/education')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.education' />
                                </Link>
                                <Link href={RouteMap.Page('student-room')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.about_kot_temp' />
                                </Link>
                                <Link href={RouteMap.Page('only-for-students')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.promotions' />
                                </Link>
                                <Link href={RouteMap.Page('login')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.register' />
                                </Link>
                            </Box>
                        </Box>
                        <Box className={classes.wrapperRowMobile}>
                            <Box className={classes.wrapperRowItemMobile} >
                                <Typography variant='h1' component='p' className={classes.titleColumn}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.employer_kot' />
                                </Typography>
                                {props.user?.type !== 'employer' && (
                                    <Link
                                        component='a'
                                        color='initial'
                                        href={props.user ? RouteMap.Page('users/kots/new') : `${RouteMap.Page('login')}?redirect=${RouteMap.Page('users/kots/new')}`}
                                        className={classes.link}
                                    >
                                        <FormattedMessage id='page_footer_navbar_data.footer.publish_kot' />
                                    </Link>
                                )}
                                <Link href={RouteMap.Page('kot-owners')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.kot_terms' />
                                </Link>
                            </Box>
                            <Box className={classes.wrapperRowItemMobile}>
                                <Typography variant='h1' component='p' className={classes.titleColumn}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.student_be' />
                                </Typography>
                                <Link href={RouteMap.Page('mybusinessschoolevent')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    Business school fair
                                </Link>
                                <Link href={RouteMap.Page('about')} component='a' color='initial' hreflang={`${locale}-BE`} className={`${classes.link}`}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.about_us' />
                                </Link>
                                <Link href={RouteMap.Page('podcast')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.podcast' />
                                </Link>
                                <Link href={RouteMap.Page('press')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.about_press' />
                                </Link>
                                <Link href={RouteMap.Page('faq')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.about_faq' />
                                </Link>
                                <Link href={RouteMap.Page('terms')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.terms' />
                                </Link>
                                <Link href={RouteMap.Page('contact')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.contact_us' />
                                </Link>
                                <Link href={RouteMap.Page('login')} component='a' color='initial' hreflang={`${locale}-BE`} className={classes.link}>
                                    <FormattedMessage id='page_footer_navbar_data.footer.register' />
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.containerBottom}>
                    <Box className={classes.containerBottomLeft}>
                        <Typography className={classes.copyrightTypo}>
                            ©&nbsp;<FormattedMessage id="page_footer_navbar_data.footer.right_reserved" values={{ year: new Date().getFullYear() }} />
                            <span onClick={() => { window.openAxeptioCookies() }} style={{ marginLeft: '0.3rem', cursor: 'pointer' }}> - Cookies</span>
                        </Typography>
                        <Hidden xsDown>
                            <Divider orientation='vertical' className={classes.divider} variant="middle" flexItem />
                        </Hidden>
                    </Box>
                    <Box className={classes.containerBottomRight}>
                        <Box className={classes.containerLangSelect}>
                            <LangSelect
                                color="white"
                                lang='FR'
                                name='footer'
                                altLangUrls={props.altLangUrls}
                                allLangsFlat
                                paramsForUrlTranslation={paramsForUrlTranslation}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

        </>
    )
}

export default withStyles(useStyles)(Footer)
