import React, { useState, useEffect } from 'react'
import JobAPI from 'api/JobAPI'
import RecommendedJobsView from 'components/RecommendedJobsView/RecommendedJobsView.jsx'
import RouteMap from 'assets/RouteMap.js'
import { FormattedMessage } from 'react-intl'

const RecommendedJobs = (props) => {
    const { job } = props
    const [recommendedJobs, setRecommendedJobs] = useState([])

    useEffect(() => {
        getRecommendedJobs()
    }, [])

    const getRecommendedJobs = () => {
        const data = {
            id: job?.id,
            field_of_study_id: job?.field_of_study_id,
            per_page: 3,
            job_type: job.type
        }
        JobAPI.GetSimilarJobs(data).then((response) => {
            if (response.data) {
                setRecommendedJobs(response.data?.slice(0, 3))
            }
        })
    }

    const moreItemsURL = () => {
        if (!job) return null
        let params = ''

        if (job.type === 'student_job') {
            if (job.type === 'student_job' && job.city) {
                return RouteMap.LocationIndex('student_job', job.address)
            }
        } else if (['internship', 'first_job'].includes(job.type) && job.study_domains?.length > 0) {
            if (job.study_domains?.length === 1) {
                return RouteMap.Tag(job.type, 'seoStudyDomainCollection', job.study_domains[0].name)
            }
            job.field_of_study.forEach((studyDomain) => {
                params += `study_domain=${studyDomain}&`
            })
        } else if (job.type === 'company') {
            return RouteMap.Show('company', job.url)
        } else if (job.type === 'article') {
            return RouteMap.ArticleCategory(job.type)
        } else if (job.type === 'kot' && job.address) {
            return RouteMap.LocationIndex('kot', job.address)
        }

        if (params.endsWith('&')) {
            params = params.slice(0, -1)
        }

        return RouteMap.Index(job.type, params)
    }

    if (recommendedJobs.length === 0) return null

    return (
        <RecommendedJobsView
            items={recommendedJobs}
            title={<FormattedMessage id='job_show.recommended_jobs.title' />}
            moreItemsText={<FormattedMessage id='job_show.recommended_jobs.more_link' />}
            moreItemsURL={moreItemsURL()}
        />
    )
}

export default RecommendedJobs
