import React, {} from 'react'
import { Box, Container, Hidden } from '@material-ui/core'
import isEmpty from 'lodash.isempty'

import CompanyKeyStats from '../CompanyKeyStats/CompanyKeyStats'
import CompanyPageImages from '../CompanyPageImages/CompanyPageImages'
import CompanyRecentJobs from 'shared/components/CompanyRecentJobs/CompanyRecentJobs'
import HorizontalCardsSlider from 'shared/components/HorizontalCardsSlider/HorizontalCardsSlider'
import ResponsiveVideo from 'shared/components/ResponsiveVideo/ResponsiveVideo'
import { useStyles } from '../CompanyPage_style'

const CompanyOverview = (props) => {
    const classes = useStyles()
    const { jobs, videos, images, keyStats, setTab } = props

    return (
        <Box>
            <Box className={classes.companyBodyOverview}>
                <Box className={classes.companyBodyOverviewCenter}>
                    {!isEmpty(videos)
                        ? <Container maxWidth='lg' disableGutters>
                            <HorizontalCardsSlider shift={500}>
                                {videos.map((video, index) => {
                                    return (
                                        <Box className={classes.videoBox} key={index}>
                                            <ResponsiveVideo
                                                video={{ url: video.url, width: 250, height: 480 }}
                                            />
                                        </Box>
                                    )
                                })}
                            </HorizontalCardsSlider>
                            <Hidden xsDown>
                                <Box my={1}>
                                    <CompanyKeyStats stats={keyStats} />
                                </Box>
                            </Hidden>
                        </Container>
                        : <CompanyPageImages images={images} />
                    }
                    {images.length === 0 && !isEmpty(keyStats) &&
                        <Hidden xsDown>
                            <Box my={1}>
                                <CompanyKeyStats stats={keyStats} />
                            </Box>
                        </Hidden>
                    }
                </Box>
                <Box className={classes.companyBodyOverviewSide}>
                    {!isEmpty(jobs) &&
                        <CompanyRecentJobs
                            jobs={jobs}
                            handleClick={() => setTab('jobs')}
                        />
                    }
                </Box>
            </Box>
            {!isEmpty(videos) &&
                <Box className={classes.companyBodyOverviewImages}>
                    <CompanyPageImages images={images}/>
                </Box>
            }
        </Box>
    )
}

export default CompanyOverview
