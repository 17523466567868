import React from 'react'
import { Grid, Box, Switch, Input, FormHelperText, FormControl, FormControlLabel, FormLabel, Select, Button, Typography } from '@material-ui/core'
import FeedbackSnackbar from 'shared/components/FeedbackSnackbar/FeedbackSnackbar'
import URLHandler from 'assets/URLHandler'
import translations from 'assets/i18n/translations.json'
import CollectionHandler from 'assets/CollectionHandler'
import { useStyles } from './AdminEditVideoView_style'

const AdminEditVideoView = (props) => {
    const { isNew, video, setVideo, feedback, closeFeedback, formErrors, handleFetch, handleChange, handleSubmit } = props
    const classes = useStyles()

    const languageOptions = CollectionHandler.Get('defaultLanguageCollection')
    const locale = URLHandler.GetLocaleFromUrl()
    const messages = translations[locale]

    return (
        <Box className={classes.container}>
            <FeedbackSnackbar
                open={feedback.open}
                message={feedback.message}
                severity={feedback.severity}
                closeFeedback={closeFeedback}
            />
            <form onSubmit={handleSubmit}>
                <Grid container direction='column' spacing={4} justifyContent='flex-start' alignItems='flex-start'>
                    <Grid item xs={12} className={classes.minHalfWidth}>
                        <FormControl>
                            <FormLabel htmlFor='string'>
                                {messages['admin_video_edit.enter_youtube_url']}
                            </FormLabel>
                            <Input
                                className={classes.input}
                                value={video.url}
                                name='url'
                                id='url-input'
                                aria-describedby='url-helper-text'
                                onChange={handleChange}
                                required
                                error={!!formErrors.url}
                                disabled={!isNew}
                            />
                            {!isNew &&
                                <FormHelperText id='url-edit-text'>
                                    {messages['admin_video_edit.cannot_change_url']}
                                </FormHelperText>
                            }
                        </FormControl>
                    </Grid>
                    {isNew &&
                        <Box m={2}>
                            <Grid container justifyContent='flex-end'>
                                <Button
                                    onClick={handleFetch}
                                    disabled={!video.url}
                                    variant='contained'
                                    color='primary'
                                >
                                    {messages['admin_video_edit.fetch_data_button']}
                                </Button>
                            </Grid>
                        </Box>
                    }
                    {video.validated && <>
                        <Grid item xs={12} className={classes.minHalfWidth}>
                            <FormControl>
                                <FormLabel htmlFor='title-input'>
                                    {messages['admin_video_edit.title']}
                                </FormLabel>
                                <Input
                                    className={classes.input}
                                    value={video.title}
                                    name='title'
                                    id='title-input'
                                    aria-describedby='title-helper-text'
                                    onChange={handleChange}
                                    required
                                    error={!!formErrors.title}
                                />
                                <FormHelperText id='title-helper-text'>
                                    {messages['admin_video_edit.title_helper_text']}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} className={classes.fullWidth}>
                            <FormControl fullWidth>
                                <FormLabel>
                                    {messages['admin_video_edit.description']}
                                </FormLabel>
                                <textarea
                                    className={classes.htmlArea}
                                    aria-describedby='description-helper-text'
                                    name='description'
                                    value={video.description}
                                    onChange={handleChange}
                                >
                                    {video.description}
                                </textarea>
                                <FormHelperText id='description-helper-text'>
                                    {messages['admin_video_edit.description_helper_text']}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl >
                                <Box className={classes.booleanBox}>
                                    <FormLabel>
                                        {messages['admin_video_edit.show_in_education_index']}
                                    </FormLabel>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name='show_in_education_index'
                                                id='show_in_education_index-input'
                                                aria-describedby='show_in_education_index-helper-text'
                                                color='secondary'
                                                checked={video.show_in_education_index}
                                                value={video.show_in_education_index}
                                                onChange={() => setVideo({ ...video, show_in_education_index: !video.show_in_education_index })}
                                            />
                                        }
                                    />
                                </Box>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl>
                                <Box className={classes.booleanBox}>
                                    <FormLabel>
                                        {messages['admin_video_edit.show_in_english']}
                                    </FormLabel>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name='en'
                                                id='en-input'
                                                aria-describedby='en-helper-text'
                                                color='secondary'
                                                checked={video.en}
                                                value={video.en}
                                                onChange={() => setVideo({ ...video, en: !video.en })}
                                            />
                                        }
                                    />
                                </Box>
                                <Box className={classes.booleanBox}>
                                    <FormLabel>
                                        {messages['admin_video_edit.show_in_french']}
                                    </FormLabel>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name='fr'
                                                id='fr-input'
                                                aria-describedby='fr-helper-text'
                                                color='secondary'
                                                checked={video.fr}
                                                value={video.fr}
                                                onChange={() => setVideo({ ...video, fr: !video.fr })}
                                            />
                                        }
                                    />
                                </Box>
                                <Box className={classes.booleanBox}>
                                    <FormLabel>
                                        {messages['admin_video_edit.show_in_dutch']}
                                    </FormLabel>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name='nl'
                                                id='nl-input'
                                                aria-describedby='nl-helper-text'
                                                color='secondary'
                                                checked={video.nl}
                                                value={video.nl}
                                                onChange={() => setVideo({ ...video, nl: !video.nl })}
                                            />
                                        }
                                    />
                                </Box>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} className={classes.minHalfWidth}>
                            <FormControl>
                                <FormLabel htmlFor='language-native-simple'>
                                    {messages['admin_video_edit.language']}
                                </FormLabel>
                                <Select
                                    native
                                    className={classes.input}
                                    value={video.language}
                                    onChange={handleChange}
                                    inputProps={{ name: 'language', id: 'language-native-simple' }}
                                    required
                                >
                                    <option aria-label='None' value={null} />
                                    {languageOptions.map(language => {
                                        return <option value={language.value} key={language.value}>{language.name}</option>
                                    })}
                                </Select>
                                <FormHelperText id='language-native-simple'>
                                    {messages['admin_video_edit.language_helper_text']}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Box m={2}>
                            <Grid container justifyContent='flex-end'>
                                <Typography >
                                    To link companies to this video, click 'classic edit' at the top of this page!
                                </Typography>
                            </Grid>
                        </Box>
                        <Box m={2}>
                            <Grid container justifyContent='flex-end'>
                                <Typography >
                                    To link featured schools to this video, click 'classic edit' at the top of this page!
                                </Typography>
                            </Grid>
                        </Box>
                        <Box m={2}>
                            <Grid container justifyContent='flex-end'>
                                <Button type='submit' variant='contained' color='primary'>
                                    {messages['admin_video_edit.save_or_edit']}
                                </Button>
                            </Grid>
                        </Box>
                    </>}
                </Grid>
            </form>
        </Box>
    )
}

export default AdminEditVideoView
