/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'

import { FormattedMessage } from 'react-intl'
import DeleteOfferPopupView from './DeleteOfferPopupView'

const DeleteOfferPopup = (props) => {
    const [state, setState] = useState({
        popupTitle: '',
        deleteInfos: null,
        emptyError: false
    })

    useEffect(() => {
        props.getPopupContent({
            popupTitle: props.popupTitle,
            popupActionButton: <FormattedMessage id={getActionButtonTranslationId()}/>,
            popupCancelButton: <FormattedMessage id='delete_offer_popup.option_no'/>,
            doublebuttons: true,
            actionPopup: actionPopup
        })
    }, [])

    const getActionButtonTranslationId = () => {
        if (props.itemType === 'candidacy') return 'delete_offer_popup.candidacy.option_yes'
        if (props.itemType === 'alert') return 'delete_offer_popup.alert.option_yes'

        return 'delete_offer_popup.option_yes'
    }

    const handleChange = (event) => {
        setState({ deleteInfos: event.target.value })
        props.getPopupContent({
            actionPopup: actionPopup
        })
    }

    const actionPopup = () => {
        if ((props.itemType === 'alert') || (props.itemType === 'article') || (props.itemType === 'company-job') || (props.itemType === 'candidacy') || state.deleteInfos) {
            props.actionPopup(state.deleteInfos)
            props.popupExit()
        } else {
            setState({
                emptyError: true
            })
        }
    }

    return <DeleteOfferPopupView
        handleChange={handleChange}
        itemType={props.itemType}
        itemsLen={props.itemsLen}
        emptyError={state.emptyError}
    />
}

export default DeleteOfferPopup
