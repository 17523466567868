import React from 'react'
import { Grid, Box, Switch, FormControl, FormControlLabel, FormLabel, Button, Typography, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import ReactQuill from 'react-quill'
import StringInput from './components/StringInput/StringInput'
import FacultyForm from './components/FacultyForm/FacultyForm'
import FeaturedCampusForm from './components/FeaturedCampusForm/FeaturedCampusForm'
import SchoolFactForm from './components/SchoolFactForm/SchoolFactForm'
import FaqForm from './components/FaqForm/FaqForm'
import FeedbackSnackbar from 'components/FeedbackSnackbar/FeedbackSnackbar'
import TagMultiSelect from 'components/TagMultiSelect/TagMultiSelect'
import IconFactory from 'icons/IconFactory/IconFactory'
import CollectionHandler from 'assets/CollectionHandler'
import { useStyles } from './AdminEditFeaturedSchoolView_style'
import UploadImageV2 from 'modals/UploadImageV2/UploadImageV2'

const AdminEditFeaturedSchoolView = (props) => {
    const {
        state,
        setState,
        feedback,
        closeFeedback,
        handleChange,
        handleBannerImageAltChange,
        handleSubmit,
        handleQuillChange,
        handleSelectedItems,
        imageArray,
        setImageArray,
        logo,
        setLogo,
        bannerImage,
        setBannerImage,
        schoolFacts,
        setSchoolFacts,
        faculties,
        setFaculties,
        featuredCampuses,
        setFeaturedCampuses,
        faqs,
        setFaqs,
        campusMapImage,
        setCampusMapImage
    } = props
    const classes = useStyles()

    const programTypeCollection = CollectionHandler.Get('programTypeCollection')

    return (
        <Box className={classes.container}>
            <FeedbackSnackbar
                open={feedback.open}
                message={feedback.message}
                severity={feedback.severity}
                closeFeedback={closeFeedback}
            />
            <form onSubmit={handleSubmit}>
                <Grid container direction="column" spacing={4} justifyContent="flex-start" alignItems="flex-start">
                    <Grid item xs={12} className={classes.stringInputBox}>
                        <StringInput
                            title='Name'
                            className={classes.stringInput}
                            state={state}
                            value='name'
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.quillBox}>
                        <FormLabel className={classes.formLabel}>Description of school</FormLabel>
                        <ReactQuill
                            modules={{ clipboard: { matchVisual: false } }}
                            className={classes.quillStyle}
                            value={state.description}
                            onChange={(quillValue) => handleQuillChange(quillValue, 'description')}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.stringInputBox}>
                        <FormLabel className={classes.formLabel}>Available program types</FormLabel>
                        <TagMultiSelect
                            collection={programTypeCollection}
                            selectedItems={state.program_types.map(programType => { return { value: programType } })}
                            setSelectedItems={(items) => handleSelectedItems(items, 'program_types')}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.stringInputBox}>
                        <StringInput
                            title='Blurb title'
                            className={classes.stringInput}
                            state={state}
                            value='blurb_title'
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.stringInputBox}>
                        <StringInput
                            title='Blurb description'
                            className={classes.stringInput}
                            state={state}
                            value='blurb_description'
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.stringInputBox}>
                        <StringInput
                            title='CTA faculties title'
                            className={classes.stringInput}
                            state={state}
                            value='cta_faculties_title'
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.stringInputBox}>
                        <StringInput
                            title='CTA faculties description'
                            className={classes.stringInput}
                            state={state}
                            value='cta_faculties_description'
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.stringInputBox}>
                        <StringInput
                            title='CTA faculties URL title'
                            className={classes.stringInput}
                            state={state}
                            value='cta_faculties_url_title'
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.stringInputBox}>
                        <StringInput
                            title='CTA faculties URL'
                            className={classes.stringInput}
                            state={state}
                            value='cta_faculties_url'
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.fullWidthBox}>
                        <Accordion className={classes.accordion}>
                            <AccordionSummary expandIcon={<IconFactory icon='more' />} className={classes.accordionTitle}>
                                <Typography component='h2' variant='h3' >
                                    Faculties
                                </Typography>
                            </AccordionSummary>
                            <Box className={classes.accordionContent}>
                                <AccordionDetails>
                                    <FacultyForm
                                        items={faculties}
                                        setItems={setFaculties}
                                    />
                                </AccordionDetails>
                            </Box>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12} className={classes.fullWidthBox}>
                        <Accordion className={classes.accordion}>
                            <AccordionSummary expandIcon={<IconFactory icon='more' />} className={classes.accordionTitle}>
                                <Typography component='h2' variant='h3' >
                                    Campuses
                                </Typography>
                            </AccordionSummary>
                            <Box className={classes.accordionContent}>
                                <AccordionDetails>
                                    <FeaturedCampusForm
                                        items={featuredCampuses}
                                        setItems={setFeaturedCampuses}
                                    />
                                </AccordionDetails>
                            </Box>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12} className={classes.fullWidthBox}>
                        <Accordion className={classes.accordion}>
                            <AccordionSummary expandIcon={<IconFactory icon='more' />} className={classes.accordionTitle}>
                                <Typography component='h2' variant='h3' >
                                    FAQ
                                </Typography>
                            </AccordionSummary>
                            <Box className={classes.accordionContent}>
                                <AccordionDetails>
                                    <FaqForm
                                        items={faqs}
                                        setItems={setFaqs}
                                    />
                                </AccordionDetails>
                            </Box>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12} className={classes.fullWidthBox}>
                        <Accordion className={classes.accordion}>
                            <AccordionSummary expandIcon={<IconFactory icon='more' />} className={classes.accordionTitle}>
                                <Typography component='h2' variant='h3' >
                                    School facts
                                </Typography>
                            </AccordionSummary>
                            <Box className={classes.accordionContent}>
                                <AccordionDetails>
                                    <SchoolFactForm
                                        items={schoolFacts}
                                        setItems={setSchoolFacts}
                                    />
                                </AccordionDetails>
                            </Box>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12} className={classes.fullWidthBox}>
                        <Accordion className={classes.accordion}>
                            <AccordionSummary expandIcon={<IconFactory icon='more' />} className={classes.accordionTitle}>
                                <Typography component='h2' variant='h3' >
                                    URL's
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box className={classes.accordionContent}>
                                    <StringInput
                                        title='Main URL'
                                        className={classes.stringInput}
                                        state={state}
                                        value='url'
                                        handleChange={handleChange}
                                    />
                                    <StringInput
                                        title='TikTok URL'
                                        className={classes.stringInput}
                                        state={state}
                                        value='url_tiktok'
                                        handleChange={handleChange}
                                    />
                                    <StringInput
                                        title='LinkedIn URL'
                                        className={classes.stringInput}
                                        state={state}
                                        value='url_linkedin'
                                        handleChange={handleChange}
                                    />
                                    <StringInput
                                        title='Instagram URL'
                                        className={classes.stringInput}
                                        state={state}
                                        value='url_instagram'
                                        handleChange={handleChange}
                                    />
                                    <StringInput
                                        title='Facebook URL'
                                        className={classes.stringInput}
                                        state={state}
                                        value='url_facebook'
                                        handleChange={handleChange}
                                    />
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12}>
                        <Accordion className={classes.accordion}>
                            <AccordionSummary expandIcon={<IconFactory icon='more' />} className={classes.accordionTitle}>
                                <Typography component='h2' variant='h3' >
                                    Images
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box className={classes.accordionContent}>
                                    <FormControl>
                                        <FormLabel className={classes.formLabel}>Logo</FormLabel>
                                        <UploadImageV2
                                            image={logo}
                                            setImage={setLogo}
                                            title='Upload your logo'
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel className={classes.formLabel}>Banner image</FormLabel>
                                        <UploadImageV2
                                            image={bannerImage}
                                            setImage={setBannerImage}
                                            title='Upload your banner image'
                                        />
                                    </FormControl>
                                    {bannerImage &&
                                        <StringInput
                                            title='Banner image alt'
                                            className={classes.stringInput}
                                            state={bannerImage}
                                            value='alt'
                                            handleChange={handleBannerImageAltChange}
                                        />
                                    }
                                    <FormControl>
                                        <FormLabel className={classes.formLabel}>Campus map image</FormLabel>
                                        <UploadImageV2
                                            image={campusMapImage}
                                            setImage={setCampusMapImage}
                                            title='Upload your campus image'
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel className={classes.formLabel}>Images</FormLabel>
                                        <UploadImageV2
                                            imageArray={imageArray}
                                            setImageArray={setImageArray}
                                            title='Upload your images'
                                        />
                                    </FormControl>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={4}>
                                <FormControl>
                                    <FormLabel className={classes.formLabel}>Dutch</FormLabel>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name='nl'
                                                id="nl-input"
                                                aria-describedby="nl-helper-text"
                                                color='secondary'
                                                checked={state.nl}
                                                value={state.nl}
                                                onChange={() => setState({ ...state, nl: !state.nl })}
                                            />
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl>
                                    <FormLabel className={classes.formLabel}>French</FormLabel>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name='fr'
                                                id="fr-input"
                                                aria-describedby="fr-helper-text"
                                                color='secondary'
                                                checked={state.fr}
                                                value={state.fr}
                                                onChange={() => setState({ ...state, fr: !state.fr })}
                                            />
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl>
                                    <FormLabel className={classes.formLabel}>English</FormLabel>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name='en'
                                                id="en-input"
                                                aria-describedby="en-helper-text"
                                                color='secondary'
                                                checked={state.en}
                                                value={state.en}
                                                onChange={() => setState({ ...state, en: !state.en })}
                                            />
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel className={classes.formLabel}>Active</FormLabel>
                            <FormControlLabel
                                control={
                                    <Switch
                                        name='active'
                                        id="active-input"
                                        aria-describedby="active-helper-text"
                                        color='secondary'
                                        checked={state.active}
                                        value={state.active}
                                        onChange={() => setState({ ...state, active: !state.active })}
                                    />
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel className={classes.formLabel}>Paid</FormLabel>
                            <FormControlLabel
                                control={
                                    <Switch
                                        name='paid'
                                        id="paid-input"
                                        aria-describedby="paid-helper-text"
                                        color='secondary'
                                        checked={state.paid}
                                        value={state.paid}
                                        onChange={() => setState({ ...state, paid: !state.paid })}
                                    />
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} className={classes.stringInputBox}>
                        <StringInput
                            title='Email (will not be published on the website for now)'
                            className={classes.stringInput}
                            state={state}
                            value='email'
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Box m={2}>
                        <Grid container justifyContent='flex-end'>
                            <Typography >
                                To link <strong>videos</strong> to this featured school, go to the video classic edit!
                            </Typography>
                        </Grid>
                    </Box>
                    <Box m={2}>
                        <Grid container justifyContent='flex-end'>
                            <Typography >
                                To link <strong>articles</strong> to this featured school, go to the article react edit!
                            </Typography>
                        </Grid>
                    </Box>
                    <Box m={2}>
                        <Grid container justifyContent='flex-end'>
                            <Button type="submit" variant="contained" color="primary">
                                save or edit ✍🏼
                            </Button>
                        </Grid>
                    </Box>
                </Grid>
            </form>
        </Box>
    )
}

export default AdminEditFeaturedSchoolView
