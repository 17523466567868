import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    '@global': {
        html: {
            overflowY: 'hidden'
        }
    },
    root: {
        display: 'flex'
    },
    dialogContainer: {
        width: '90%',
        height: '90%',
        margin: 0,
        borderRadius: 'var(--border-radius)',
        [theme.breakpoints.down('sm')]: {
            height: '95%',
            width: '95%'
        }
    },
    gridContainer: {
        height: 'calc(100% - 50px)%',
        [theme.breakpoints.down('sm')]: {
            height: '90%'
        }
    },
    redirectingToPartnerContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 20,
        textAlign: 'center'
    },
    redirectingToPartnerTitle: {
        color: 'var(--foreground-purple)',
        fontSize: 24
    },
    redirectingToPartnerSubTitle: {
        fontSize: 20
    },
    formContainer: {
        height: '100%',
        overflowY: 'scroll',
        padding: '0 40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 40,
        [theme.breakpoints.down('md')]: {
            padding: '0 12px'
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0 8px'
        }
    },
    loginContainer: {
        padding: '20px 0px',
        gap: 20
    },
    overrideRootPadding: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 24,
            paddingRight: 24
        }
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    appBar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        borderBottom: 'solid var(--foreground-silver) 1px'
    },
    toolBar: {
        display: 'flex',
        justifyContent: 'flex-start'
    },
    applyPopupTitle: {
        marginBottom: 20
    },
    jobTitle: {
        fontSize: 20,
        width: '100%',
        fontWeight: 'bold'
    },
    jobCardContainer: {
        padding: '30px 20px 0 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'sticky',
        top: 0,
        borderRight: 'solid var(--foreground-silver) 1px'
    },
    buttonStepperContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonStepper: {
        margin: '0 5px'
    },
    condensedJobContainer: {
        marginTop: '50px',
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '0px',
            paddingLeft: 4,
            paddingRight: 4
        }
    },
    condensedSubmitContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    lowerCaseButton: {
        textTransform: 'capitalize'
    },
    reactQuill: {
        minHeight: 500,
        height: '50vh',
        marginBottom: 50
    },
    loginRegisterPage: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            marginTop: 13
        }
    },
    loginRegisterContainer: {
        [theme.breakpoints.up('md')]: {
        }
    },
    circular: {
        width: '50%'
    },
    stepperPaper: {
        backgroundColor: 'var(--background-silver)',
        paddingTop: 40,
        marginBottom: 20,
        borderRadius: '0px 0px 20px 20px'
    },
    mb3: {
        marginBottom: '24px'
    },
    checkboxGDPR: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    textGDPR: {
        lineHeight: 'normal'
    },
    addCVNudge: {
        textAlign: 'center',
        margin: '0 auto',
        maxWidth: 220,
        marginTop: 14
    },
    addCVNudgeText: {
        color: theme.palette.fg.purple,
        fontSize: 14
    },
    errorMessage: {
        paddingLeft: 24,
        marginBottom: 30,
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            padding: '0 30px'
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0 15px'
        }
    },
    errorMessageCondensed: {
        marginBottom: 30,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    }
}))
