export const DOCUMENT_API = '/api/v1/documents'
export const USER_API = '/api/v1/users/'
export const KOT_OWNER_API = USER_API + 'kot_owner_update'
export const KOT_API = USER_API + 'kots'
export const USER_JOB_API = '/api/v1/users/jobs'
export const USER_PROFILE = '/api/v1/users/profiles'
export const USER_EXPERIENCE = '/api/v1/users/experiences'
export const GET_USER_INFO_API = '/api/v1/users/get_user_infos'
export const UPDATE_NAME_PROFILE_API = '/api/v1/users/update_name'
export const UPDATE_CITY_PROFILE_API = '/api/v1/users/update_city'
export const UPDATE_EXPERIENCES_PROFILE_API = '/api/v1/users/update_experiences'
export const USER_CANDIDACY = '/api/v1/users/candidacies'
export const SIMILAR_FIRST_JOBS_API = '/api/v1/first_jobs/similar'
export const SIMILAR_STUDENT_JOBS_API = '/api/v1/student_jobs/similar'
export const SIMILAR_INTERNSHIPS_API = '/api/v1/internships/similar'
export const FILTER_COUNT_FIRST_JOBS_API = '/api/v1/first_jobs/filter_count'
export const FILTER_COUNT_STUDENT_JOBS_API = '/api/v1/student_jobs/filter_count'
export const FILTER_COUNT_INTERNSHIPS_API = '/api/v1/internships/filter_count'
export const JOB_RESULTS_EXPORT = '/api/v1/users/job_results/export'
export const ARTICLE_API = '/api/v1/recent_articles'
export const ARTICLE_SIMILAR_JOBS_API = '/api/v1/articles/similar_jobs'
export const ARTICLE_SIMILAR_ARTICLES_API = '/api/v1/articles/similar_articles'
export const ARTICLE_RECENT_API = '/api/v1/articles/recent'
export const ARTICLE_MOST_READ_API = '/api/v1/articles/most_read'
export const ARTICLE_CLASSICS_API = '/api/v1/articles/classics'
export const ARTICLE_SEARCH_ARTICLES_WITH_TAGS_API = '/api/v1/articles/search_articles_with_tags'
export const ARTICLE_EBOOK_VIA_EMAIL_API = '/api/v1/articles/send_ebook_via_email'
export const ARTICLE_ADD_REACTION = '/api/v1/articles/add_article_reaction'
export const ARTICLE_REACTION_OPTIONS = '/api/v1/articles/article_reaction_options'
export const ARTICLE_FILTER = '/api/v1/articles/filter'
export const ARTICLE_SUBSCRIBE_TO_NEWSLETTER = '/api/v1/articles/subscribe_newsletter'
export const NEW_PAYMENT_API = '/api/v1/users/new_payment'
export const PAY_WITH_CREDITS = '/api/v1/users/payment_with_credits'
export const ACTIVATE_WITH_CREDITS = '/api/v1/users/activate_with_credits'
export const BOOST_JOB = '/api/v1/users/boost_job'
export const FAVOURITE_API = '/api/v1/users/favourites'
export const ALERT_API = '/api/v1/users/alerts'
export const CONTACT_API = '/api/v1/contact'

// Candidacies
export const CANDIDACY_API = '/api/v1/users/candidacies'
export const CANDIDACY_CHECK_AND_RETRIEVE_STUDENT_DOCUMENTS = '/api/v1/users/check_and_retrieve_student_documents'

// Admin
export const ADMIN_COMPANY_EXPORT = '/api/v1/admin/exports/company_export'
export const ADMIN_USERS_EXPORT = '/api/v1/admin/exports/users_export'
export const ADMIN_DATA_USERS = '/api/v1/admin/data/users_data'
export const ADMIN_AD_UPDATE = '/admin/ad/update'
export const ADMIN_AD_CREATE = '/admin/ad/create'
export const ADMIN_FEATURED_SCHOOL_UPDATE = '/admin/featured_school/update'
export const ADMIN_VIDEO_FETCH = '/api/v1/admin/videos/fetch_data'
export const ADMIN_VIDEO_CREATE = '/api/v1/admin/videos/create'
export const ADMIN_VIDEO_UPDATE = '/api/v1/admin/videos/update'

// Messages
export const CONVERSATION_API = '/api/v1/users/conversations'
export const CANDIDACIES_MESSAGES_API = '/api/v1/users/messages/get_candidacies'
export const OFFERS_MESSAGES_API = '/api/v1/users/messages/get_offers'
export const ADD_FILE_MESSAGES_API = '/api/v1/users/messages/add_file_to_message'
export const GET_CONVERSATION_MESSAGES_API = '/api/v1/users/messages/get_conversations'
export const GET_MESSAGES_API = '/api/v1/users/messages/get_messages'
export const SEND_MESSAGE_TO_SELECTION = '/api/v1/users/messages/send_message_to_all'

// Company Urls
export const RECRUITING_COMPANIES_API = '/api/v1/companies/recruiting'
export const SIMILAR_COMPANIES_API = '/api/v1/companies/similar'
export const FILTER_COMPANY_JOBS_API = '/api/v1/companies/jobs'
export const TESTIMONIES_API = '/api/v1/testimonies/'
export const GET_TESTIMONIES_API = '/api/v1/testimonies/get_company_testimonies/'
export const COMPANY_API = '/api/v1/users/companies/'
export const JOIN_PARENT_COMPANY_API = '/api/v1/register/join_parent_company'
export const GET_COMPANY_JOBS_API = '/api/v1/users/company_jobs_filter'
export const DUPLICATE_KOT_API = '/api/v1/users/duplicate_kots'
export const DUPLICATE_FIRST_JOB_API = '/api/v1/first_jobs/duplicate'
export const DUPLICATE_INTERNSHIP_API = '/api/v1/internships/duplicate'
export const DUPLICATE_STUDENT_JOB_API = '/api/v1/student_jobs/duplicate'
export const COMPANY_JOBS_API = '/api/v1/users/jobs'
export const API_LINKS = {
    kot: DUPLICATE_KOT_API,
    first_job: DUPLICATE_FIRST_JOB_API,
    internship: DUPLICATE_INTERNSHIP_API,
    student_job: DUPLICATE_STUDENT_JOB_API
}

// settings
export const CHANGE_PASSWORD_API = '/api/v1/password/reset_password'
export const RESET_PASSWORD_API = '/api/v1/password/request_password_reset'
export const USER_SETTINGS_BASIC_API = '/api/v1/users/update_basic_settings'
export const USER_SETTINGS_EMAIL_API = '/api/v1/users/update_email_settings'
export const USER_SETTINGS_PASSWORD_API = '/api/v1/users/update_password_settings'
export const USER_SETTINGS_DELETE_ACCOUNT_API = '/api/v1/users/delete_account'

// notifications
export const GET_NOTIFICATION_API = '/api/v1/users/get_notifications'
export const SEE_NOTIFICATION_API = '/api/v1/users/see_notifications'

// videos
export const VIDEOS_API = '/api/v1/videos/fetch_videos'
export const ADMIN_VIDEOS_API = '/api/v1/admin/videos'

// Sessions
export const LOGIN_API = '/login'
export const AUTHENTIFICATION_PARAMS_API = '/api/v1/authentification/get_login_register_apply_params'
export const DOES_USER_EMAIL_ALREADY_EXISTS_API = '/api/v1/authentification/does_user_email_already_exists'

// Analytics
export const ANALYTICS_API = '/api/v1/analytics/send_data'

// Navigation
export const NAVIGATION_COLLECTION_API = '/api/v1/navigation/get_navigation_filters'
export const COMPANY_FILTER_JOBS_API = '/api/v1/navigation/get_company_filter_jobs_count'

// Dashboard pages
export const DASHBOARD_USER_INFO_API = '/api/v1/users/dashboard/user_info'
export const DASHBOARD_RECOMMENDED_JOBS_API = '/api/v1/users/dashboard/recommended_jobs_for_users'
export const DASHBOARD_RECOMMENDED_ARTICLES_API = '/api/v1/users/dashboard/recommended_articles'
export const DASHBOARD_RECENTLY_VIEWED_JOBS_API = '/api/v1/users/dashboard/recently_viewed_jobs'
export const DASHBOARD_RECRUITING_COMPANIES_API = '/api/v1/users/dashboard/recruiting_companies_for_users'
export const DASHBOARD_FAVOURITES_API = '/api/v1/users/dashboard/favourite_jobs'
export const DASHBOARD_RECOMMENDED_KOTS_API = '/api/v1/users/dashboard/recommended_kots'
export const DASHBOARD_LAST_CHANCE_JOBS_API = '/api/v1/users/dashboard/last_chance_jobs'

// Ads
export const AD_INCREASE_SHOW_COUNTER = '/api/v1/ads/increase_show_counter'

// Promotions
export const PROMOTION_FILTER = '/api/v1/promotions/filter'

// Kots
export const KOT_RECOMMENDED_API = '/api/v1/kots/recommended'
export const KOT_SIMILAR_API = '/api/v1/kots/similar'
