import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    companyFilterContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '80px 0px',
        gap: 30,
        [theme.breakpoints.down('sm')]: {
            padding: '40px 0px'
        }
    },
    companyFilterText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            maxWidth: 325,
            textAlign: 'center'
        }
    },
    companyFilterTitle: {
        fontSize: 40,
        lineHeight: '56px',
        fontFamily: 'GreedWide-Heavy',
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            fontSize: 32,
            lineHeight: '40px'
        }
    },
    companyFilterDescription: {
        lineHeight: '18px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        }
    },
    companyFilterBox: {
        scrollSnapAlign: 'center'
    }
}))
