import React from 'react'
import { Box } from '@material-ui/core'
import IconFactory from 'icons/IconFactory/IconFactory'
import Button from 'components/Button/Button'
import SchoolFactFormInput from './SchoolFactFormInput'
import { useStyles } from '../../AdminEditFeaturedSchoolView_style'

const SchoolFactForm = (props) => {
    const { items, setItems } = props
    const classes = useStyles()
    const BLANK_ITEM = {
        title: '',
        value: ''
    }

    const deleteItem = (index) => {
        items.splice(index, 1)
        setItems([...items])
    }

    const addItem = () => {
        setItems([...items, { ...BLANK_ITEM }])
    }

    return (
        <Box>
            {items.map((item, index) => {
                return (
                    <SchoolFactFormInput
                        key={`SchoolFactFormInput-${index}`}
                        index={index}
                        items={items}
                        setItems={setItems}
                        deleteItem={deleteItem}
                    />
                )
            })}
            <Box className={classes.buttonBox}>
                <Button onClick={addItem} size='small' variant='secondary'>
                    Add a school fact
                    <IconFactory color='black' icon='add' />
                </Button>
            </Box>
        </Box>
    )
}

export default SchoolFactForm
