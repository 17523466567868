
import React, { useState, useRef } from 'react'

import Box from '@material-ui/core/Box'
import IconFactory from 'icons/IconFactory/IconFactory'
import { FormControl, MenuItem, Select } from '@material-ui/core'

import FeedbackSnackbar from 'shared/components/FeedbackSnackbar/FeedbackSnackbar'
import { useStyles } from './RecruitmentStatus_style'
import { FormattedMessage } from 'react-intl'
import CollectionHandler from 'assets/CollectionHandler'
import CandidacyAPI from 'api/CandidacyAPI'

import { DROPDOWN_STYLE_MENU_PROPS } from 'assets/StyleHelper'
import { useConfetti } from 'hooks/useConfetti'

const RecruitmentStatus = (props) => {
    const classes = useStyles()
    const { customConfettiCanon } = useConfetti()
    const [status, setStatus] = useState(props.status)
    const [feedback, setFeedback] = useState({
        open: false,
        message: null,
        severity: null,
        anchor: { vertical: 'bottom', horizontal: 'left' }
    })
    const selectRef = useRef()
    const recruitmentStatusCollection = CollectionHandler.Get('recruitmentStatusCollection')

    const updateRecruitmentStatus = (event) => {
        const oldStatusValue = status
        const newStatusValue = event.target.value
        setStatus(newStatusValue)
        if (newStatusValue === 'hired') {
            launchConfetti()
        }
        CandidacyAPI.updateCandidacy({
            candidacy_id: props.candidacy.id,
            recruitment_status: newStatusValue
        }).catch(error => {
            setStatus(oldStatusValue)
            setFeedback({
                ...feedback,
                open: true,
                message: `Error occured while updating candidacy: ${error.message}`,
                severity: 'error'
            })
        })
    }
    const closeFeedback = () => setFeedback({ ...feedback, open: false })

    const launchConfetti = () => {
        customConfettiCanon({
            width: 800,
            height: 400,
            ref: selectRef,
            timeout: 2000,
            particles: {
                particleCount: 180,
                spread: 360,
                startVelocity: 30,
                gravity: 1,
                origin: { x: 0.5, y: 0.45 }
            }
        })
    }

    const statusToColor = (status) => {
        if (status === 'new_candidate') return 'var(--columbia-blue)'
        if (status === 'in_process') return 'var(--orange)'
        if (status === 'declined') return 'var(--red)'
        if (status === 'hired') return 'var(--green)'

        return 'var(--black)'
    }

    return (
        <>
            <FeedbackSnackbar
                open={feedback.open}
                message={feedback.message}
                severity={feedback.severity}
                closeFeedback={closeFeedback}
            />
            <FormControl className={classes.container}>
                <Select
                    ref={selectRef}
                    value={status}
                    onChange={updateRecruitmentStatus}
                    MenuProps={DROPDOWN_STYLE_MENU_PROPS}
                    inputProps={{ IconComponent: () => null }}
                    disableUnderline
                    classes={{ select: classes.overrideSelect }}
                    renderValue={(selected) => (
                        <Box className={classes.recruitmentStatusDropdown}>
                            <Box className={classes.recruitmentStatusSelected}>
                                <span style={{ color: statusToColor(selected), fontSize: 18 }}>•</span>
                                <FormattedMessage id={`candidacy.recruitment_status.${selected}`} />
                            </Box>
                            <Box className={classes.dropdownContainer}>
                                <IconFactory icon='down-solid' fontSize='12px' color='var(--dark-beige)' />
                            </Box>
                        </Box>
                    )}
                >
                    {recruitmentStatusCollection.map((option) => (
                        <MenuItem key={option.value} value={option.value} className={classes.recruitmentStatusOptions}>
                            <span style={{ color: statusToColor(option.value), fontSize: 16 }}>•</span>
                            <FormattedMessage id={`candidacy.recruitment_status.${option.value}`} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    )
}

export default RecruitmentStatus
