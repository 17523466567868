import React, {} from 'react'
import { Box } from '@material-ui/core'

import { useStyles } from './BannerWrapper_style'

const BannerWrapper = (props) => {
    const { children, className, color } = props
    const classes = useStyles({ color: color })

    return (
        <Box className={`${classes.containerWrapper} ${className}`}>
            {children}
        </Box>
    )
}

export default BannerWrapper
