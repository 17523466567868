import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { useStyles } from './JoinTheClubBanner_style'
import SVGFactory from 'shared/icons/SVGFactory/SVGFactory'
import { LoginRegisterContext } from 'shared/contexts/LoginRegisterContext'
import { JOIN_THE_CLUB } from 'assets/AuthentificationHelper'
import { UserContext } from 'contexts/UserContext'
import RouteMap from 'assets/RouteMap'

const JoinTheClubBanner = (props) => {
    const classes = useStyles()
    const loginRegisterContext = useContext(LoginRegisterContext)
    const userContext = useContext(UserContext)

    const handleClick = () => {
        if (userContext?.user) {
            let redirectionUrl
            if (userContext.user.type === 'student') redirectionUrl = RouteMap.Page('users/dashboard')
            if (userContext.user.type === 'employer') redirectionUrl = RouteMap.Page('users/company_jobs')
            if (userContext.user.type === 'kot_owner') redirectionUrl = RouteMap.Page('users/kots')

            window.location.href = redirectionUrl
        } else {
            loginRegisterContext.openLoginRegisterModal({
                origin: JOIN_THE_CLUB
            })
        }
    }

    return (
        <Box className={classes.banner} onClick={handleClick}>
            {[...Array(2)].map((_, index) => { // We need 2 scrollingContainers for a smooth transition
                return <Typography className={classes.scrollingContainer} key={index}>
                    {[...Array(20)].map((_, subindex) => {
                        return <Box key={(index + 1) * subindex} className={classes.joinTheClubContainer}>
                            <Typography component='h6' className={classes.joinTheClubText}>
                                <FormattedMessage id='banner.join_the_club' />
                            </Typography>
                            <SVGFactory name='lightning' width='20px' height='20px' />
                        </Box>
                    })}
                </Typography>
            })}
        </Box>
    )
}

export default JoinTheClubBanner
