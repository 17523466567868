import React from 'react'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import IconFactory from 'icons/IconFactory/IconFactory'
import { Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './SharedTabs_style'

const GetTranslationBaseString = () => {
    if (window.innerWidth <= 600) {
        return 'landing_connected_user_without_my'
    } else {
        return 'landing_connected_user'
    }
}

const TabButton = (props) => {
    const classes = useStyles()
    const status = (props.currentTab.replace(/\//g, '') === props.page.replace(/\//g, '') ? 'active' : '')

    let content

    if (props.page === 'users/settings/edit') {
        content = (
            <IconFactory icon='settings' className={classes.icon} />
        )
    } else if (props.page === 'sign_out') {
        content = (
            <IconFactory icon='sign-out' className={classes.icon} />
        )
    } else if (props.page === 'messages-icon') {
        content = (
            <RouteMapLink page='messages' key='button-connected-user-navbar-link-to-messages'>
                <IconFactory icon='conversation' className={classes.messageIcon} />
            </RouteMapLink>
        )
    } else {
        content = (
            <Typography variant='h4' component='span' className={classes.tab}>
                <FormattedMessage id={`${GetTranslationBaseString()}.${props.translateId}`} />
            </Typography>
        )
    }

    return (
        <RouteMapLink
            className={`${classes.link} ${status === 'active' && classes.active}`}
            page={props.page}
        >
            {content}
        </RouteMapLink>
    )
}

export default TabButton
