import React, { createContext, useState } from 'react'
import AlertsAPI from 'api/AlertsAPI'
import FiltersHelper from 'assets/FiltersHelper'
import URLParamsHandler from 'assets/URLParamsHandler.js'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import { FormattedMessage } from 'react-intl'

export const AlertCTAContext = createContext(null)

export const useAlertCTAContext = (filters) => {
    const [showAlertModal, setShowAlertModal] = useState(false)
    const [alertModalShown, setAlertModalShown] = useState(false)
    const [alertOrigin, setAlertOrigin] = useState(URLParamsHandler.parseURLParams().alertOrigin)
    const [feedback, setFeedback] = useState({
        open: false,
        message: null,
        severity: null,
        anchor: { vertical: 'bottom', horizontal: 'center' }
    })

    const closeFeedback = () => setFeedback({ ...feedback, open: false })

    const renderAlertModal = (origin = null) => {
        setShowAlertModal(!showAlertModal)
        setAlertOrigin(origin)
        setAlertModalShown(true)
    }

    const closeModal = () => setShowAlertModal(false)

    const getCoords = (filterCities) => {
        if (filterCities && filterCities.length) {
            return filterCities.map((option) => {
                return {
                    long: option.long,
                    lat: option.lat,
                    value: option.value
                }
            })
        } else {
            return 'null'
        }
    }

    const createAlert = (user, filters, type) => {
        AlertsAPI.CreateAlert({
            study_domains: FiltersHelper.getValues(filters?.studyDomain),
            locations: getCoords(filters?.location),
            company_sizes: FiltersHelper.getValues(filters?.companySize),
            sectors: FiltersHelper.getValues(filters?.sector),
            job_type: type,
            schedules: FiltersHelper.getValues(filters?.jobSchedule),
            creation_origin: alertOrigin,
            quick_filter: filters?.quickFilter
        }).then(
            (response) => {
                renderAlertModal()
                setFeedback({
                    ...feedback,
                    open: true,
                    message: <FormattedMessage id='create_alert_popup.alert_creation_success'/>,
                    severity: 'success'
                })
                AnalyticsHelper.sendGTMEvent('alert-creation-success')
                AnalyticsHelper.sendGTMEvent(
                    'Search Saved',
                    AnalyticsHelper.constructSearchFilterAppliedAnalyticsAttributes(
                        user,
                        { job_type: type },
                        alertOrigin
                    )
                )
            },
            (error) => {
                renderAlertModal()
                setFeedback({
                    ...feedback,
                    open: true,
                    message: <FormattedMessage id='create_alert_popup.alert_creation_failure'/> + error,
                    severity: 'error'
                })
            }
        )
    }

    return {
        renderAlertModal,
        alertModalShown,
        setAlertModalShown,
        createAlert,
        showAlertModal,
        feedback,
        closeFeedback,
        closeModal
    }
}
