import React, { useState } from 'react'
import { Image } from 'cloudinary-react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './MobileAppBar_style'
import { useStyles as useDarkModeStyles } from './MobileAppBarDarkMode_style'
import { AppBar, Hidden, IconButton, Box } from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'
import MobileBurgerMenu from '../MobileBurgerMenu/MobileBurgerMenu'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import ProfileHelper from 'assets/ProfileHelper'
import Button from 'components/Button/Button'

const MobileAppBar = (props) => {
    const { altLangUrls, user, paramsForUrlTranslation, darkMode } = props
    const classes = darkMode ? useDarkModeStyles() : useStyles()
    const [open, setOpen] = useState(false)

    const openModal = () => {
        setOpen(true)
    }

    const closeModal = () => {
        setOpen(false)
    }

    return (
        <Hidden mdUp>
            <MobileBurgerMenu
                open={open}
                closeModal={closeModal}
                user={user}
                altLangUrls={altLangUrls}
                paramsForUrlTranslation={paramsForUrlTranslation}
            />
            <AppBar position='static' color='white' elevation={0} className={classes.appBar}>
                <RouteMapLink page='/'>
                    <Image
                        className={classes.logo}
                        cloudName='studentbe'
                        publicId='student-be-logos/Student_2024_Logo_Main.png'
                        alt='Student.be'
                    />
                </RouteMapLink>
                <Box className={classes.iconsContainer}>
                    {user
                        ? <RouteMapLink page={ProfileHelper.GetProfileUrl(user.type)}>
                            <IconButton>
                                <IconFactory icon='account' className={classes.burgerIcon} />
                            </IconButton>
                        </RouteMapLink>
                        : <RouteMapLink page='login'>
                            <Button variant='secondary' size='small' className={classes.actionButton}>
                                <FormattedMessage id="page_footer_navbar_data.login_signup" />
                            </Button>
                        </RouteMapLink>
                    }
                    <IconButton
                        onClick={openModal}
                        className={classes.button}
                        disableRipple
                    >
                        <IconFactory icon='bars' className={classes.burgerIcon} />
                    </IconButton>
                </Box>
            </AppBar>
        </Hidden>
    )
}

export default MobileAppBar
