import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Container, Typography, Chip, Divider } from '@material-ui/core'

import { useStyles } from './AlertForm_style'
import { SectionTitle, SectionDescription } from 'modals/FilterModals/FiltersModalWrapper'
import CityFilter from 'components/Searchbar/shared/CityFilter/CityFilter'
import CheckboxList from 'components/Searchbar/shared/CheckboxList/CheckboxList'
import TagMultiSelect from 'shared/components/TagMultiSelect/TagMultiSelect'
import MultipleSelectFilter from 'components/Searchbar/shared/MultipleSelectFilter/MultipleSelectFilter'
import CollectionHandler from 'assets/CollectionHandler.js'
import QuickFilterTagOption from 'shared/components/Searchbar/shared/QuickFilterTagOption/QuickFilterTagOption'
import useJobIcon from 'hooks/useJobIcon'
import URLParamsHandler from 'assets/URLParamsHandler'
import Button from 'shared/components/Button/Button'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import ResetFilters from 'shared/components/Searchbar/shared/ResetFilters/ResetFilters'
import JobAPI from 'api/JobAPI'
import FiltersHelper from 'assets/FiltersHelper'
import { useFirstRender } from 'hooks/useFirstRender'

// DEV NOTE: If you add a filter YOU MUST ALSO add this filter in URLParamsHandler (getURLParamsFromFilters & loadUrlFilters)
const commonFilters = {
    location: [],
    studyDomains: [],
    quickFilter: ''
}
const studentJobFilters = {
    ...commonFilters,
    schedule: []
}
const internshipFilters = {
    ...commonFilters,
    companySize: []
}
const firstJobFilters = {
    ...commonFilters,
    companySize: [],
    sectors: []
}

const DEFAULT_ITEM_TYPE = 'student_job'

const AlertForm = (props) => {
    const classes = useStyles()
    const { alert, itemType, handleSubmit, formType } = props
    const cityCollection = CollectionHandler.Get('cityCollection')
    const sectorCollection = CollectionHandler.Get('sectorCollection')
    const jobTypesCollection = CollectionHandler.Get('jobTypeCollection')
    const companySizeCollection = CollectionHandler.Get('companySizeCollection')
    const studyDomainCollection = CollectionHandler.Get('studyDomainCollection')
    const studentJobSchedulesCollection = CollectionHandler.Get('studentJobSchedulesCollection')
    const quickFilterStudentJobCollection = CollectionHandler.Get('quickFilterStudentJobCollection')
    const quickFilterInternshipCollection = CollectionHandler.Get('quickFilterInternshipCollection')
    const quickFilterFirstJobCollection = CollectionHandler.Get('quickFilterFirstJobCollection')
    const [quickFilterCollection, setQuickFilterCollection] = useState([])
    const [selectedItemType, setSelectedItemType] = useState(itemType || 'student_job')
    const [filters, setFilters] = useState({})
    const [filterCount, setFilterCount] = useState(0)
    const [isFetching, setIsFetching] = useState(false)
    const firstRender = useFirstRender()

    useEffect(() => {
        const { itemType } = URLParamsHandler.loadUrlFilters('alert')
        setSelectedItemType(alert?.job_type || itemType || DEFAULT_ITEM_TYPE)
    }, [])

    useEffect(() => {
        if (!firstRender) {
            getCountForCriteria()
        }
    }, [filters])

    useEffect(() => {
        const qfCollection = getQuickFilterCollectionForSelectedItemType()
        setQuickFilterCollection(qfCollection)
        filtersByItemType()
    }, [selectedItemType])

    const getQuickFilterCollectionForSelectedItemType = () => {
        if (selectedItemType === 'student_job') return quickFilterStudentJobCollection
        if (selectedItemType === 'internship') return quickFilterInternshipCollection
        if (selectedItemType === 'first_job') return quickFilterFirstJobCollection
    }

    const filtersByItemType = () => {
        const { itemType, ...urlFilters } = URLParamsHandler.loadUrlFilters('alert')
        const filtersFromAlert = {
            location: alert?.locations.map(e => cityCollection.find(ee => ee.value === e.value)) || [],
            quickFilter: alert?.quick_filter || '',
            studyDomains: alert?.study_domains.map(e => studyDomainCollection.find(ee => ee.value === e)) || [],
            schedule: alert?.schedules.map(e => studentJobSchedulesCollection.find(ee => ee.value === e)) || [],
            companySize: alert?.company_sizes.map(e => companySizeCollection.find(ee => ee.value === e)) || [],
            sectors: alert?.sectors.map(e => sectorCollection.find(ee => ee.value === e)) || []
        }
        const allFilters = { ...filters, ...urlFilters, ...filtersFromAlert }
        if (selectedItemType === 'student_job') setFiltersByType(allFilters, studentJobFilters)
        if (selectedItemType === 'internship') setFiltersByType(allFilters, internshipFilters)
        if (selectedItemType === 'first_job') setFiltersByType(allFilters, firstJobFilters)
        if (urlFilters) URLParamsHandler.removeAllParams()
    }

    const setFiltersByType = (currentFilters, filtersByType) => {
        const correctFilters = Object.keys(filtersByType).reduce((result, key) => {
            if (key === 'quickFilter') {
                const qfCollection = getQuickFilterCollectionForSelectedItemType()
                const qfMatch = qfCollection.find((qf) => qf.value === currentFilters[key])?.value
                result[key] = qfMatch || ''
            } else if (key in currentFilters) {
                result[key] = currentFilters[key]
            } else {
                result[key] = filtersByType[key]
            }
            return result
        }, {})
        setFilters(correctFilters)
    }

    const handleQuickFilter = (quickFilter) => {
        const isRemoved = quickFilter === filters.quickFilter
        setFilters({
            ...filters,
            quickFilter: isRemoved ? '' : quickFilter
        })
    }

    const handleItemTypeSelection = (itemType) => {
        setSelectedItemType(itemType)
    }

    const handleSelectedItems = (items, filter) => {
        setFilters({
            ...filters,
            [filter]: items
        })
    }

    const getCountForCriteria = () => {
        setIsFetching(true)
        const data = {
            item_type: selectedItemType,
            company_size: FiltersHelper.getValues(filters.companySize),
            quick_filter: filters.quickFilter,
            study_domain: FiltersHelper.getValues(filters.studyDomains),
            location: filters.location,
            job_schedule: FiltersHelper.getValues(filters.schedule)
        }
        JobAPI.GetJobFilterResultsCount(data).then(response => {
            setIsFetching(false)
            setFilterCount(response.data.count)
        })
    }

    const resetFilters = () => {
        if (selectedItemType === 'student_job') setFilters(studentJobFilters)
        if (selectedItemType === 'internship') setFilters(internshipFilters)
        if (selectedItemType === 'first_job') setFilters(firstJobFilters)
    }

    const handleBeforeSubmit = () => {
        handleSubmit(filters, selectedItemType)
    }

    return (
        <Container maxWidth='lg' className={classes.alertCreationForm}>
            <Box className={classes.itemTypeSelection}>
                <SectionTitle id='alert_creation_form.item_selection.title' />
                <Box className={classes.itemChips}>
                    {jobTypesCollection.map((item, itemKey) => {
                        const selectedItem = item.value === selectedItemType
                        return <Chip
                            key={itemKey}
                            variant='outlined'
                            label={<FormattedMessage id={`${item.value}`} />}
                            onClick={() => handleItemTypeSelection(item.value)}
                            clickable={false}
                            disabled={formType === 'edit'}
                            icon={<IconFactory icon={useJobIcon(item.value)}/>}
                            classes={{ icon: classes.chipIcon }}
                            className={`${classes.chip} ${classes.hoverChip} ${selectedItem ? classes.selectedChip : ''}`}
                        />
                    })}
                </Box>
            </Box>
            <Divider />
            {selectedItemType && filters &&
                <Box className={classes.alertFiltersContainer}>
                    <Box className={classes.quickFilterContainer}>
                        <SectionTitle id='job_filters.quickfilter.title' />
                        <Box className={classes.quickFilters}>
                            {quickFilterCollection.map(quickFilterOption => {
                                const isSelected = filters.quickFilter === quickFilterOption.value
                                return (
                                    <Box
                                        onClick={() => handleQuickFilter(quickFilterOption.value)}
                                        className={classes.quickFilterOption}
                                        key={quickFilterOption.value}
                                    >
                                        <QuickFilterTagOption
                                            isSelected={isSelected}
                                            quickFilterOption={quickFilterOption}
                                            setIsHoverText={() => {}}
                                        />
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>
                    <Divider />
                    {Object.hasOwn(filters, 'location') && <>
                        <Box className={classes.locationSectionContainer}>
                            <SectionTitle id='job_filters.location.title' />
                            <Box className={classes.locationField}>
                                <CityFilter
                                    className={classes.filter}
                                    setFilters={setFilters}
                                    filters={filters}
                                    width={500}
                                />
                            </Box>
                        </Box>
                        <Divider />
                    </>}
                    {Object.hasOwn(filters, 'studyDomains') && <>
                        <Box className={classes.fieldOfStudySectionContainer}>
                            <SectionTitle id='job_filters.study_fields.title' />
                            <SectionDescription id='job_filters.study_fields.description' />
                            <Box className={classes.checkboxListContainer}>
                                <CheckboxList
                                    type='studyDomain'
                                    collection={studyDomainCollection}
                                    selectedItems={filters.studyDomains}
                                    setSelectedItems={(selectedItems) => { handleSelectedItems(selectedItems, 'studyDomains') }}
                                    setFilters={setFilters}
                                    width={400}
                                />
                            </Box>
                        </Box>
                        <Divider />
                    </>}
                    {Object.hasOwn(filters, 'schedule') && <>
                        <Box className={classes.jobScheduleSectionContainer}>
                            <SectionTitle id='job_filters.schedule.title' />
                            <SectionDescription id='job_filters.schedule.description' />
                            <Box className={classes.tagsMultiSelectContainer}>
                                <TagMultiSelect
                                    setSelectedItems={(selectedItems) => { handleSelectedItems(selectedItems, 'schedule') }}
                                    selectedItems={filters.schedule}
                                    collection={studentJobSchedulesCollection}
                                    width={600}
                                />
                            </Box>
                        </Box>
                    </>}
                    {Object.hasOwn(filters, 'companySize') && <>
                        <Box className={classes.companySizeSectionContainer}>
                            <SectionTitle id='job_filters.type_of_company.title' />
                            <SectionDescription id='job_filters.type_of_company.description' />
                            <Box className={classes.tagsMultiSelectContainer}>
                                <TagMultiSelect
                                    setSelectedItems={(selectedItems) => { handleSelectedItems(selectedItems, 'companySize') }}
                                    selectedItems={filters.companySize}
                                    collection={companySizeCollection}
                                    width={600}
                                />
                            </Box>
                        </Box>
                        <Divider />
                    </>}
                    {Object.hasOwn(filters, 'sectors') && <>
                        <Box className={classes.sectorSectionContainer}>
                            <SectionTitle id='job_filters.sector.title' />
                            <SectionDescription id='job_filters.sector.description' />
                            <Box className={classes.sectorField}>
                                <MultipleSelectFilter
                                    type='sectors'
                                    collection={sectorCollection}
                                    filters={filters}
                                    setFilters={setFilters}
                                    width={500}
                                    filterTextId='search_bar.sector'
                                    showTags
                                    dropdownPosition='left'
                                    modal
                                />
                            </Box>
                        </Box>
                    </>}
                </Box>
            }
            <Box className={classes.alertSubmitContainer}>
                <ResetFilters reset={resetFilters} />
                <Box className={classes.submitSection}>
                    <Button size='large' variant='cta-primary' className={classes.customButton} onClick={handleBeforeSubmit}>
                        <FormattedMessage id='alert_creation_form.save_alert.submit' />
                        <Box ml={1}>({isFetching ? '...' : filterCount})</Box>
                    </Button>
                    <Typography className={`${classes.submitExplanation} ${filterCount < 10 ? classes.warningColor : ''}`}>
                        <FormattedMessage id='alert_creation_form.save_alert.helper_text' />
                    </Typography>
                </Box>
            </Box>
        </ Container>
    )
}

export default AlertForm
