import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'

import { Box } from '@material-ui/core'

import { ApplyProcessContext } from 'shared/contexts/ApplyProcessContext'
import Button from 'shared/components/Button/Button.jsx'

const ApplyButton = (props) => {
    const { job, user, containerClass, buttonsClass, buttonVariant = 'primary', buttonSize = 'big' } = props
    const applyProcessContext = useContext(ApplyProcessContext)

    return (
        <Box className={containerClass && containerClass}>
            {(user?.admin || job.current_user_is_job_owner) &&
                <Button onClick={applyProcessContext.redirectToEditJob} variant={buttonVariant} size={buttonSize} className={buttonsClass && buttonsClass}>
                    <FormattedMessage id='landing_card_page.job_show.tooltip.edit_offer' />
                </Button>
            }
            {applyProcessContext.studentHasApplied()
                ? <Button onClick={applyProcessContext.redirectToCandidaciesOverview} variant={buttonVariant} size={buttonSize} className={`${buttonsClass && buttonsClass}`}>
                    <FormattedMessage id='apply_button.already_applied' />
                </Button>
                : <Button disabled={applyProcessContext.applyDisabled()} onClick={applyProcessContext.onClick()} variant={buttonVariant} size={buttonSize} className={buttonsClass && buttonsClass}>
                    <FormattedMessage id={job.application_url ? 'transl_internship.postulate_on_company_website' : 'transl_internship.postulate_button'} />
                </Button>
            }
        </Box>
    )
}

export default ApplyButton
