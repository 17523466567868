import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    whyAlertContainer: {
        padding: 40,
        paddingTop: 60,
        paddingBottom: 80
    },
    whyAlertTitle: {
        textAlign: 'center',
        color: theme.palette.fg.black,
        fontFamily: 'GreedWide-Heavy',
        fontSize: '40px',
        lineHeight: '50px',
        textTransform: 'uppercase',
        marginBottom: 60,
        [theme.breakpoints.down('md')]: {
            fontSize: '32px',
            lineHeight: '40px'
        }
    },
    whyAlertsBenefits: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: 60,
    },
    whyAlertsBenefit: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        width: 330,
        minWidth: 280,
        borderRadius: 'var(--border-radius)',
        boxShadow: 'var(--box-shadow)',
        border: '1px solid black',
        backgroundColor: theme.palette.bg.white,
    },
    whyAlertsImage: {
        height: 240,
        width: '100%',
        objectFit: 'cover'
    },
    whyAlertsBenefitTitle: {
        padding: '10px 4px',
        fontSize: 24,
        textAlign: 'center',
        height: 60
    },
    whyAlertsBenefitExplanation: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px 20px',
        textAlign: 'center',
        height: 100,
    }
}))
