import React from 'react'
import { Box, Typography } from '@material-ui/core'
import ReactQuill from 'react-quill'
import Button from 'components/Button/Button'
import IconFactory from 'icons/IconFactory/IconFactory'
import StringInput from '../StringInput/StringInput'
import { useStyles } from '../../AdminEditFeaturedSchoolView_style'

const FaqFormInput = (props) => {
    const { index, items, setItems, deleteItem } = props
    const classes = useStyles()

    const handleItemChange = (name) => {
        items[index][name] = event.target.value
        setItems([...items])
    }

    const handleQuillChange = (value, name) => {
        items[index][name] = value
        setItems([...items])
    }

    return (
        <Box className={classes.itemWrapper}>
            <StringInput
                title='Question'
                className={classes.stringInput}
                state={items[index]}
                value='question'
                handleChange={() => handleItemChange('question')}
            />
            <Box className={classes.marginTopBox}>
                <Typography>Answer</Typography>
                <ReactQuill
                    modules={{ clipboard: { matchVisual: false } }}
                    className={classes.quillStyle}
                    value={items[index].answer || ''}
                    onChange={(quillValue) => handleQuillChange(quillValue, 'answer')}
                />
            </Box>
            <Box className={classes.marginTopBox}>
                <Button onClick={() => deleteItem(index)} size='small' variant='secondary'>
                    Delete this question
                    <IconFactory color='black' icon='delete' />
                </Button>
            </Box>
        </Box>
    )
}

export default FaqFormInput
