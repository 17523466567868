import React from 'react'

import PageWrapper from 'shared/wrappers/PageWrapper/PageWrapper'
import AlertPageHelmet from 'helmets/AlertPageHelmet'
import AlertCreation from './AlertCreation'

const AlertPage = (props) => {
    const { user, itemType } = props

    return (
        <PageWrapper user={user} disableFooterLists>
            <AlertPageHelmet />
            <AlertCreation itemType={itemType} />
        </PageWrapper>
    )
}

export default AlertPage
