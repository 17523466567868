export const useStyles = (theme) => ({
    // === IMPORTANT - TO READ BEFORE CHANGE ===
    // The styling of the content is similar to the promotion show
    // with some small differences. However, both styles should be updated
    // when changing the article content style
    articleText: {
        '& span, & p, & li': {
            ...theme.typography.body1,
            ...{
                fontFamily: "'Helvetica Neue', 'Open Sans', serif",
                margin: 0,
                fontSize: '20px !important',
                lineHeight: '30px !important',
                marginBottom: '32px !important',
                color: 'var(--black)',
                fontWeight: 400,
                [theme.breakpoints.down('md')]: {
                    fontSize: '18px !important',
                    lineHeight: '28px !important',
                    marginBottom: '30px !important'
                },
                [theme.breakpoints.down('xs')]: {
                    fontSize: '16px !important',
                    lineHeight: '26px !important',
                    marginBottom: '28px !important'
                }
            }
        },
        '& .also-read-box': {
            backgroundColor: 'var(--background-silver)',
            borderRadius: 20,
            padding: 20
        },
        '& .ql-align-center': {
            textAlign: 'center'
        },
        '& .ql-align-justify': {
        },
        '& .ql-align-right': {
            textAlign: 'right'
        },
        '& li': {
            '&.ql-indent-1': {
                marginLeft: '1.5rem'
            },
            '&.ql-indent-2': {
                marginLeft: '3rem'
            },
            '&.ql-indent-3': {
                marginLeft: '4.5rem'
            }
        },
        '& h1': {
            ...theme.typography.h1,
            ...{
                fontFamily: "'Helvetica Neue', 'Open Sans', serif",
                marginTop: '26px !important',
                marginBottom: '26px !important',
                fontSize: '26px !important',
                fontWeight: 600,
                [theme.breakpoints.down('md')]: {
                    fontSize: '24px !important'
                }
            }
        },
        '& h2': {
            ...theme.typography.h2,
            ...{
                fontFamily: "'Helvetica Neue', 'Open Sans', serif",
                fontSize: '22px !important',
                marginBottom: '24px !important',
                fontWeight: 600,
                [theme.breakpoints.down('md')]: {
                    fontSize: '22px !important'
                }
            }
        },
        '& h3': {
            ...theme.typography.h3,
            ...{
                fontFamily: "'Helvetica Neue', 'Open Sans', serif",
                marginBottom: '20px !important',
                fontSize: '22px !important',
                fontWeight: 600,
                [theme.breakpoints.down('md')]: {
                    fontSize: '20px !important'
                }
            }
        },
        '& iframe': {
            marginBottom: '20px !important'
        },
        '& img': {
            maxWidth: '100%',
            borderRadius: 12
        },
        '& strong': {
            fontWeight: 600
        }
    }
})
