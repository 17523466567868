import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Image, Transformation } from 'cloudinary-react'

import { useStyles } from './CTABannerCreateProfile_style'
import { Typography, Container, Box, Hidden } from '@material-ui/core'

import CTABannerButton from 'shared/components/CTABannerButton/CTABannerButton.jsx'

const CTABannerCreateProfile = (props) => {
    const { userType, type } = props
    const classes = useStyles()

    return (
        <Container className={classes.bannerContainer} disableGutters>
            <Box className={classes.upContainer}>
                <Hidden smUp>
                    <FormattedMessage id='banner_create_profile.title_mobile'>
                        {text =>
                            <Typography className={classes.titleContainer} variant='h1' component='p' dangerouslySetInnerHTML={{ __html: text }} />
                        }
                    </FormattedMessage>
                </Hidden>
                <Hidden xsDown>
                    <FormattedMessage id='banner_create_profile.title_desktop'>
                        {text =>
                            <Typography className={classes.titleContainer} variant='h1' component='p' dangerouslySetInnerHTML={{ __html: text }} />
                        }
                    </FormattedMessage>
                </Hidden>
                <Typography className={classes.description}>  <FormattedMessage id='banner_create_profile.description' /></Typography>
                <CTABannerButton userType={userType} type={type} textId='banner_create_profile.button.label' />
            </Box>

            <Box className={classes.downContainer}>
                <Hidden smUp>
                    <Image
                        cloudName='studentbe'
                        publicId='assets/girls_smiling_group'
                        alt='student.be post 3 jobs for free'
                        className={classes.image}
                    >
                        <Transformation flags='progressive' fetchFormat='auto' quality='auto:best' width='1000' crop='fill' />
                    </Image>
                </Hidden>
                <Hidden xsDown>
                    <Image
                        cloudName='studentbe'
                        publicId='assets/girls_smiling_group_2'
                        alt='student.be post 3 jobs for free'
                        className={classes.image}
                    >
                        <Transformation flags='progressive' fetchFormat='auto' quality='auto:best' width='1000' crop='fill' />
                    </Image>
                </Hidden>
            </Box>
        </Container >

    )
}

export default CTABannerCreateProfile
