import React, { useState } from 'react'
import AdminAPI from 'api/AdminAPI'
import AdminEditFeaturedSchoolView from './AdminEditFeaturedSchoolView'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'

const AdminEditFeaturedSchool = (props) => {
    const { featuredSchool = {} } = props
    const [state, setState] = useState({
        ...featuredSchool,
        email: featuredSchool.email || '',
        name: featuredSchool.name || '',
        description: featuredSchool.description || '',
        blurb_title: featuredSchool.blurb_title || '',
        blurb_description: featuredSchool.blurb_description || '',
        cta_faculties_title: featuredSchool.cta_faculties_title || '',
        cta_faculties_description: featuredSchool.cta_faculties_description || '',
        cta_faculties_url_title: featuredSchool.cta_faculties_url_title || '',
        cta_faculties_url: featuredSchool.cta_faculties_url || '',
        url: featuredSchool.url || '',
        url_tiktok: featuredSchool.url_tiktok || '',
        url_linkedin: featuredSchool.url_linkedin || '',
        url_instagram: featuredSchool.url_instagram || '',
        url_facebook: featuredSchool.url_facebook || '',
        program_types: featuredSchool.program_types || [],
        en: featuredSchool.en || false,
        fr: featuredSchool.fr || false,
        nl: featuredSchool.nl || false,
        active: featuredSchool.active || false,
        paid: featuredSchool.paid || false
    })
    const [logo, setLogo] = useState(featuredSchool.logo || null)
    const [bannerImage, setBannerImage] = useState(featuredSchool.banner_image || null)
    const [campusMapImage, setCampusMapImage] = useState(featuredSchool.campus_map_image || null)
    const [imageArray, setImageArray] = useState(featuredSchool.images || [])
    const [schoolFacts, setSchoolFacts] = useState(featuredSchool.school_facts || [])
    const [faculties, setFaculties] = useState(featuredSchool.faculties || [])
    const [featuredCampuses, setFeaturedCampuses] = useState(featuredSchool.featured_campuses || [])
    const [faqs, setFaqs] = useState(featuredSchool.faqs || [])
    const [feedback, setFeedback] = useState({
        open: false,
        message: null,
        severity: null
    })

    const closeFeedback = () => { setFeedback({ ...feedback, open: false }) }
    const handleChange = (e) => { setState({ ...state, [e.target.name]: e.target.value }) }
    const handleBannerImageAltChange = (e) => { setBannerImage({ ...bannerImage, alt: e.target.value }) }
    const handleQuillChange = (value, name) => { setState({ ...state, [name]: value }) }

    const handleSelectedItems = (selectedItems, attribute) => {
        const formattedItems = selectedItems.map(item => item.value)
        setState({ ...state, [attribute]: formattedItems })
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        const data = {
            ...state,
            images: imageArray,
            logo: logo,
            banner_image: bannerImage,
            school_facts: schoolFacts,
            faculties: faculties,
            featured_campuses: featuredCampuses,
            faqs: faqs
        }
        AdminAPI.UpdateFeaturedSchool(data).then((response) => {
            const { is_success, errors, redirection_url } = response.data
            if (is_success) {
                setFeedback({
                    open: true,
                    message: 'Successfully updated your featured school',
                    severity: 'success'
                })
                setTimeout(() => {
                    window.location.href = redirection_url
                }, 500)
            } else {
                setFeedback({
                    open: true,
                    message: `Error occurred: ${errors}`,
                    severity: 'error'
                })
            }
        })
    }

    return (
        <PageWrapper disableFooter disableFooterLists disableNavbar>
            <AdminEditFeaturedSchoolView
                state={state}
                setState={setState}
                feedback={feedback}
                closeFeedback={closeFeedback}
                handleChange={handleChange}
                handleBannerImageAltChange={handleBannerImageAltChange}
                handleSubmit={handleSubmit}
                handleQuillChange={handleQuillChange}
                handleSelectedItems={handleSelectedItems}
                imageArray={imageArray}
                setImageArray={setImageArray}
                logo={logo}
                setLogo={setLogo}
                bannerImage={bannerImage}
                setBannerImage={setBannerImage}
                campusMapImage={campusMapImage}
                setCampusMapImage={setCampusMapImage}
                schoolFacts={schoolFacts}
                setSchoolFacts={setSchoolFacts}
                faculties={faculties}
                setFaculties={setFaculties}
                featuredCampuses={featuredCampuses}
                setFeaturedCampuses={setFeaturedCampuses}
                faqs={faqs}
                setFaqs={setFaqs}
            />
        </PageWrapper>
    )
}

export default AdminEditFeaturedSchool
