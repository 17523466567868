import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    promotionPageContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 50,
        marginBottom: 60
    },
    bannerContainer: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        height: 240,
        backgroundColor: theme.palette.bg.purple,
        backgroundImage: 'url(\'https://res.cloudinary.com/studentbe/image/upload/v1721138136/student-be-assets/Mask_group.png\')',
        [theme.breakpoints.down('md')]: {
            height: 180
        },
        [theme.breakpoints.down('sm')]: {
            height: 'fit-content'
        }
    },
    bannerText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        gap: 24,
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            padding: 70,
            width: '100%',
            textAlign: 'center'
        },
        [theme.breakpoints.down('sm')]: {
            padding: 40,
            paddingTop: 0
        }
    },
    bannerTitle: {
        color: theme.palette.fg.white,
        fontSize: 60,
        textTransform: 'uppercase',
        width: 'fit-content',
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
            fontSize: 44
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 26
        }
    },
    lottieContainer: {
        position: 'absolute',
        top: '50%',
        left: '5%',
        width: 100,
        [theme.breakpoints.down('md')]: {
            left: '7%'
        },
        [theme.breakpoints.down('sm')]: {
            left: '0%',
            top: '10%'
        }
    },
    lottieContainerBis: {
        position: 'absolute',
        top: '12%',
        right: '5%',
        width: 100,
        [theme.breakpoints.down('md')]: {
            top: '-5%'
        },
        [theme.breakpoints.down('sm')]: {
            top: -55,
            right: 0
        }
    },
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 50,
        [theme.breakpoints.down('sm')]: {
            padding: '0 10px'
        }
    },
    text: {
        fontSize: 16,
    },
    breaklines: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        padding: 30,
        [theme.breakpoints.down('sm')]: {
            margin: '0 14px'
        }
    },
    breaklineTitle: {
        fontSize: '20px !important',
        fontFamily: 'GreedWide-Bold, sans-serif !important'
    },
    promotionTitle: {
        fontFamily: 'GreedWide-Bold, sans-serif !important',
        fontSize: 36,
        color: 'white'
    },
    promotionCountTitle: {
        fontFamily: 'GreedWide-Bold, sans-serif !important',
        fontSize: '24px !important',
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        },
        // .promotionSpan defined on hard in Phrase
        // Requires direct access to class
        '& .promotionsSpan': {
            color: 'var(--foreground-purple)'
        }
    },
    promotionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 32
    },
    promotionCardsContainer: {
        [theme.breakpoints.up('md')]: {
            padding: '0 80px'
        }
    },
    promotionItem: {
        width: 300
    },
    CTAContainer: {
        [theme.breakpoints.up('md')]: {
            margin: '0 80px'
        },
        [theme.breakpoints.down('sm')]: {
            margin: '0 14px'
        }
    },
    aboutPageBanner: {
        minHeight: '66px',
        background: '#FF6762',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2.5rem'
    },
    titlePage: {
        color: '#FFFFFF',
        padding: '1rem 0',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center'
        }
    },
    pressContent: {
        marginLeft: '24px',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 'unset'
        }
    },
    pressImg: {
        width: '100%',
        objectFit: 'cover'
    },
    pressImgBox: {
        width: '260px',
        height: '368',
        background: 'rgba(241, 237, 232, 0.1)',
        margin: '0 auto'
    },
    alignCenter: {
        height: '3.5rem',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    alignTextCenter: {
        textAlign: 'center'
    }
}))
