import React, { useState } from 'react'
import { List } from 'react-virtualized'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './SingleCityFilterView_style'
import { Box, Select, MenuItem, ListItemText, FormControl, Typography, TextField } from '@material-ui/core'
import IconFactory from 'icons/IconFactory/IconFactory'

const SingleCityFilterView = (props) => {
    const { city, handleCityChange, handleInputSearchCity, searchCities, citiesInput } = props
    const classes = useStyles()
    const [openDropdown, setOpenDropdown] = useState(false)

    const rowRenderer = (row) => {
        const { key, index, style } = row
        const option = searchCities[index]

        return (
            <MenuItem
                key={key}
                style={style}
                value={option.value}
                className={classes.menuItem}
                onClick={() => handleCityChange(option)}
            >

                <ListItemText primary={`${option.name} (${option.value})`} primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
        )
    }

    const renderSelectValue = (selected) => {
        return (
            <Box className={classes.triggerPlaceholderContainer}>
                <Typography className={classes.triggerPlaceholderText}>
                    {selected.name ? selected.name : <FormattedMessage id="home_search.city" />}
                </Typography>
            </Box>
        )
    }

    return (
        <Box className={classes.SinglecityFilterViewContainer}>
            <Box>
                <Typography variant='h3'>Selected City: <strong>{city?.name}</strong></Typography>
            </Box>
            <FormControl className={classes.SinglecityFilterViewFormControl}>
                <Box className={classes.searchInputContainer}>
                    <Box className={`${classes.textInputContainer}`}>
                        <IconFactory icon='search-regular' className={classes.searchIcon} />
                        <TextField
                            InputProps={{ classes: { input: classes.textInput }, disableUnderline: true }}
                            placeholder='type a city'
                            value={citiesInput}
                            name='citySearch'
                            onChange={handleInputSearchCity}
                            fullWidth
                            onKeyDown={e => e.stopPropagation()}
                        />
                    </Box>
                </Box>
                <List
                    width={500}
                    height={300}
                    rowCount={searchCities.length}
                    rowHeight={45}
                    rowRenderer={rowRenderer}
                />
            </FormControl>
        </Box>
    )
}

export default SingleCityFilterView
