import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Bugsnag from '@bugsnag/js'
import Box from '@material-ui/core/Box'
import SVGFactory from 'icons/SVGFactory/SVGFactory'
import CloudinaryHelper from 'assets/CloudinaryHelper'
import DocumentAPI from 'api/DocumentAPI'
import UploadImageItem from './UploadImageItem'
import { useStyles } from './UploadImageV2_style'

const UploadImageV2 = (props) => {
    const { image, setImage, setImageArray, imageArray, isLarge, title } = props
    const isPlural = !!setImageArray
    const [_, setLoading] = useState(false)
    const classes = useStyles({ isLarge })

    const openModal = () => {
        const cloudy = CloudinaryHelper.createImageUploadWidget({
            cropping: false,
            multiple: isPlural
        },
        (error, result) => {
            if (!error && result?.event === 'success') {
                try {
                    setLoading(true)
                    const data = {
                        cloudinary_key: result.info.public_id,
                        name: result.info.original_filename,
                        format: result.info.format,
                        is_used: true,
                        width: result.info.width,
                        height: result.info.height
                    }
                    DocumentAPI.postImage(data).then(
                        (response) => {
                            const newImage = {
                                id: response.data.document_object.id,
                                cloudinary_key: result.info.public_id,
                                format: result.info.format,
                                name: result.info.original_filename,
                                is_used: true
                            }
                            if (isPlural) {
                                const newImageArray = imageArray
                                newImageArray.push(newImage)
                                setImageArray(newImageArray)
                            } else {
                                setImage(newImage)
                            }
                            setLoading(false)
                        }
                    )
                } catch (e) {
                    Bugsnag.notify(e)
                }
            }
        })
        cloudy.open()
    }
    const handleDelete = (image) => {
        DocumentAPI.deleteDocumentImage(image.id)
        setImage(null)
    }

    const handlePluralDelete = (_, key) => {
        DocumentAPI.deleteDocumentImage(imageArray[key].id)

        const newImageArray = imageArray.filter((_, index) => index !== key)
        setImageArray(newImageArray)
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.uploadBox} onClick={openModal}>
                <Box className={classes.iconContainer} style={{ backgroundColor: 'var(--background-purple)' }}>
                    <SVGFactory name='image' width='24px' height='24px' />
                </Box>
                <Box className={classes.uploadText}>
                    {title || <FormattedMessage id='upload_image.call_to_action' />}
                </Box>
            </Box>
            {image &&
                <UploadImageItem
                    image={image}
                    handleDelete={() => { handleDelete(image) }}
                    isLarge={isLarge}
                    isPlural={isPlural}
                />
            }
            {isPlural && imageArray.map((image, key) => {
                if (image.is_used) {
                    return (
                        <UploadImageItem
                            key={`image-item-${image.id}`}
                            image={image}
                            handleDelete={() => { handlePluralDelete(image, key) }}
                            isLarge={isLarge}
                        />
                    )
                }
            })}
        </Box>
    )
}

export default UploadImageV2
