import React, { useState, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'

import { Box, Container, Hidden, Typography } from '@material-ui/core'
import { Image, Transformation } from 'cloudinary-react'
import isEmpty from 'lodash.isempty'

import { DEFAULT_TAB, getTabFromUrl, updateURL } from './CompanyPageHelper'
import { optimiseSizeForMobile, EXTRA_LARGE_SIZE, SMALLER_SIZE } from 'assets/CloudinaryURLHelper'
import { useStyles } from './CompanyPage_style'
import ArticleCardV2 from 'cards/ArticleCard/ArticleCardV2'
import Button from 'shared/components/Button/Button'
import CollectionHandler from 'assets/CollectionHandler.js'
import CompanyEditBanner from './CompanyEditBanner/CompanyEditBanner'
import CompanyJobs from './CompanyJobs/CompanyJobs'
import CompanyKeyStats from './CompanyKeyStats/CompanyKeyStats'
import CompanyProfileHelmet from 'helmets/CompanyProfileHelmet'
import CompanyTabs from './CompanyTabs/CompanyTabs'
import ExpandingText from 'shared/components/ExpandingText/ExpandingText'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import RouteMap from 'assets/RouteMap.js'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import ShareAction from 'components/ShareAction/ShareAction'
import SharedTabs from 'components/SharedTabs/SharedTabs'
import SimilarCompanies from 'shared/components/SimilarCompanies/SimilarCompanies'
import translations from 'assets/i18n/translations.json'
import URLHandler from 'assets/URLHandler'
import VideoAPI from 'api/VideoAPI'
import Sanitizer from 'assets/Sanitizer'
import CompanyOverview from './CompanyOverview/CompanyOverview'
import CompanyNews from './CompanyNews/CompanyNews'

const ALL_TABS = ['overview', 'jobs', 'news']

const CompanyPage = (props) => {
    const { user, page, company, userIsOwner, articles, jobs, jobsCount, editPage } = props
    const locale = URLHandler.GetLocaleFromUrl()
    const topOfTabsRef = useRef()
    const [activeTabs, setActiveTabs] = useState(ALL_TABS)
    const [selectedTab, setSelectedTab] = useState(getTabFromUrl(ALL_TABS) || DEFAULT_TAB)
    const [selectedArticles] = useState(articles.slice(0, 2))
    const [videos, setVideos] = useState([])
    const [companyImages, setCompanyImages] = useState(company.gallery_images)
    const [featuredCharacteristics, setFeaturedCharacteristics] = useState([])
    const classes = useStyles({ noBanner: !company.banner_image_cloudinary_key, editPage: editPage })
    const companyDescription = company['description_' + RouteMap.GetLocaleFromUrl()] || company.description
    const hasCompanyDescription = !isEmpty(Sanitizer.RemoveHTML(companyDescription)?.trim())

    useEffect(() => {
        let info = company.featured_characteristics.map((characteristic) => {
            if (company.extra_info[characteristic]) return { [characteristic]: company.extra_info[characteristic] }
        })
        info = info.filter(item => item)
        setFeaturedCharacteristics(info)
    }, [])

    useEffect(() => {
        if (isEmpty(articles)) {
            const updatedTabs = activeTabs.filter(tab => tab !== 'news')
            setActiveTabs(updatedTabs)
            setSelectedTab('overview')
        }
    }, [])

    useEffect(() => {
        updateURL(selectedTab, ALL_TABS)
    }, [selectedTab])

    useEffect(() => {
        if (isEmpty(companyImages) && isEmpty(videos) && selectedTab !== 'jobs') {
            openTab('jobs')
        }
    }, [videos, companyImages])

    useEffect(() => {
        if (company.main_show_video && company.main_video_url) {
            const videoImageSpecs = {
                type: 'video',
                format: 'regular',
                url: company.main_video_url,
                width: 800,
                height: 475
            }
            setCompanyImages([videoImageSpecs, ...companyImages])
        } else if (!company.main_show_video && company.main_image_cloudinary_key) {
            const imageSpecs = {
                type: 'image',
                cloudinary_key: company.main_image_cloudinary_key
            }
            setCompanyImages([imageSpecs, ...companyImages])
        }
    }, [])

    useEffect(() => {
        getVideos()
    }, [])

    useEffect(() => {
        if (!isEmpty(videos)) {
            const formattedVideos = videos.map(video => {
                return {
                    type: 'video',
                    format: 'shorts',
                    url: video.url,
                    width: 250,
                    height: 480
                }
            })
            setCompanyImages([...companyImages, ...formattedVideos])
        }
    }, [videos])

    const handleTabSelection = (clickedTab) => {
        if (clickedTab === selectedTab) return

        openTab(clickedTab)
    }

    const openTab = (tab) => {
        setSelectedTab(tab)
        topOfTabsRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    const getVideos = () => {
        const data = {
            type: 'company',
            company_id: company.id
        }

        VideoAPI.GetVideos(data).then((response) => {
            const fetchedVideos = response.data.splice(0, 10)
            setVideos(fetchedVideos)
        })
    }

    return (
        <PageWrapper user={user} page={page} disableFooterLists>
            <CompanyProfileHelmet company={company} logoCloudinaryKey={company.logo_cloudinary_key} />
            {editPage &&
                <SharedTabs user={user} currentTab='users/company/edit' />
            }
            {editPage &&
                <CompanyEditBanner
                    user={user}
                    company={company}
                />
            }
            {(company.banner_image_cloudinary_key || editPage) &&
                <Box className={classes.bannerContainer}>
                    <Box className={classes.shareButton}>
                        <ShareAction
                            infos={{
                                title: company.name,
                                url: RouteMap.Show('company', company.url),
                                type: 'company'
                            }}
                        />
                    </Box>
                    <Image
                        alt={editPage ? translations[locale]['company_page.add_banner_image'] : `${company.name} banner`}
                        cloudName="studentbe"
                        publicId={company.banner_image_cloudinary_key}
                        className={editPage ? classes.addBannerCTA : ''}
                    >
                        <Transformation flags="progressive" fetchFormat="auto" width={optimiseSizeForMobile(EXTRA_LARGE_SIZE)} quality="auto:best" crop="fill" />
                    </Image>
                </Box>
            }
            <Box className={classes.pageContent}>
                <Container maxWidth='lg'>
                    <Box className={classes.companyInfo}>
                        {company.logo_cloudinary_key &&
                            <Box className={classes.logoBox}>
                                <Image
                                    className={classes.logo}
                                    cloudName="studentbe"
                                    publicId={company.logo_cloudinary_key ? company.logo_cloudinary_key : 'default-company-logo-black'}
                                    alt={`${company.logo_cloudinary_key} logo`}
                                >
                                    <Transformation flags="progressive" fetchFormat="auto" width={SMALLER_SIZE} quality="auto:best" crop="scale" />
                                </Image>
                            </Box>
                        }
                        <Box className={classes.companyInfoText}>
                            <Box className={classes.companyNameAndCTA}>
                                <Typography component='h1' variant='h1' className={classes.companyName}>
                                    {company.name}
                                </Typography>
                                {userIsOwner &&
                                    <RouteMapLink page='users/company_profiles/edit' className={classes.editPageButton}>
                                        <Button variant='primary' size='big'>
                                            <FormattedMessage id="company_profile.company_profile_banner.modify_page_button" />
                                        </Button>
                                    </RouteMapLink>
                                }
                            </Box>
                            <Box className={classes.companyDetailsFlex} ref={topOfTabsRef}>
                                <Box className={classes.companyDetails}>
                                    <IconFactory icon='location-pin' fontSize='14px' />
                                    <Typography component='span'>{company.address.city}</Typography>
                                </Box>
                                <Box className={classes.companyDetails}>
                                    <IconFactory icon='attachment' fontSize='14px' />
                                    <Typography component='span'>{CollectionHandler.Translate('sectorCollection', company.sector)}</Typography>
                                </Box>
                                {company.impact &&
                                    <Box className={classes.companyDetails}>
                                        <IconFactory icon='globe-europe' fontSize='14px' />
                                        <Typography component='span'>
                                            <FormattedMessage id='company_page.info.company_with_impact' />
                                        </Typography>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Box>
                    <Box const className={classes.companyBodyContainer}>
                        <Box className={classes.companyBodyLeftSide}>
                            <Box>
                                <Typography variant='h1' component='h2' className={classes.companyTitle}>
                                    <FormattedMessage id='job_show.company_info_card.title_prefix' /> {company.name}
                                </Typography>
                                {hasCompanyDescription &&
                                <ExpandingText text={companyDescription} />
                                }
                            </Box>
                            {company.gallery_images.length > 0 && !isEmpty(featuredCharacteristics) && isEmpty(videos) &&
                                <Hidden xsDown>
                                    <Box>
                                        <CompanyKeyStats stats={featuredCharacteristics} vertical />
                                    </Box>
                                </Hidden>
                            }
                            {!isEmpty(selectedArticles) &&
                                <Hidden smDown>
                                    <Box className={classes.companyArticlesContainer}>
                                        <Typography variant='h1' component='h2' className={classes.companyArticlesTitle}>
                                            <FormattedMessage id='job_show.news_and_tips.title' />
                                        </Typography>
                                        {selectedArticles.map(article =>
                                            <ArticleCardV2
                                                article={article}
                                                id={'articlearticle_' + article.id}
                                                key={'articlearticle_' + article.id}
                                            />
                                        )}
                                    </Box>
                                    <Box className={classes.linkContainer}>
                                        <Button onClick={() => openTab('news')} size='small' variant='cta-primary' className={classes.customButton}>
                                            <FormattedMessage id='button.see_more_articles' />
                                        </Button>
                                    </Box>
                                </Hidden>
                            }
                        </Box>
                        <Box className={classes.companyBodyRightSide}>
                            <CompanyTabs
                                tabs={activeTabs}
                                selectedTab={selectedTab}
                                setTab={openTab}
                                jobsCount={jobsCount}
                                topOfTabsRef={topOfTabsRef}
                            />
                            {selectedTab === 'overview' && <>
                                <CompanyOverview
                                    jobs={jobs}
                                    videos={videos}
                                    images={companyImages}
                                    keyStats={featuredCharacteristics}
                                    setTab={handleTabSelection}
                                />
                            </>}
                            {selectedTab === 'jobs' &&
                                <CompanyJobs companyId={company.id} jobs={jobs} totalJobsCount={jobsCount} />
                            }
                            {selectedTab === 'news' &&
                                <CompanyNews articles={articles} />
                            }
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Box>
                <SimilarCompanies company={company} />
            </Box>
        </PageWrapper>
    )
}

export default CompanyPage
