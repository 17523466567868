import React, { useState, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Container, Typography } from '@material-ui/core'

import { UserContext } from 'contexts/UserContext'
import { useStyles } from './AlertCreation_style'
import { LoginRegisterContext } from 'shared/contexts/LoginRegisterContext'
import { SAVE_FILTERS } from 'assets/AuthentificationHelper'
import AlertsAPI from 'api/AlertsAPI'
import RouteMap from 'assets/RouteMap'
import MultiFontText from 'shared/components/MultiFontText/MultiFontText'
import FeedbackSnackbar from 'shared/components/FeedbackSnackbar/FeedbackSnackbar'
import URLParamsHandler from 'assets/URLParamsHandler'
import BannerWrapper from 'shared/wrappers/BannerWrapper/BannerWrapper'
import AlertCreationBanner from 'shared/components/AlertCreationBanner/AlertCreationBanner'
import AlertForm from 'shared/components/AlertForm/AlertForm'

const AlertCreation = (props) => {
    const { itemType } = props
    const classes = useStyles()
    const userContext = useContext(UserContext)
    const loginRegisterContext = useContext(LoginRegisterContext)
    const [config] = useState({
        formType: 'new',
        creationOrigin: 'alert_creation_page'
    })
    const [feedback, setFeedback] = useState({ open: false, message: null, severity: null })
    const closeFeedback = () => setFeedback({ ...feedback, open: false })

    const handleSubmit = (filters, itemType) => {
        if (userContext?.user) {
            createAlert(filters, itemType)
        } else {
            loginRegisterContext.openLoginRegisterModal({
                origin: SAVE_FILTERS,
                afterAuthAction: () => afterAuthAction(filters, itemType)
            })
        }
    }

    const afterAuthAction = (filters, itemType) => {
        URLParamsHandler.createURLFilters('alert', { ...filters, itemType: itemType })
        URLParamsHandler.reloadPageAndAddParams({ process: SAVE_FILTERS })
    }

    const createAlert = (filters, itemType) => {
        const data = {
            creation_origin: config.creationOrigin,
            job_type: itemType,
            locations: filters.location,
            quick_filter: filters.quickFilter,
            sectors: filters.sectors?.map(e => e.value),
            schedules: filters.schedule?.map(e => e.value),
            study_domains: filters.studyDomains?.map(e => e.value),
            company_sizes: filters.companySize?.map(e => e.value)
        }
        AlertsAPI.CreateAlert(data)
            .then((response) => {
                setFeedback({
                    open: true,
                    message: <FormattedMessage id='create_alert_popup.alert_creation_success' />,
                    severity: 'success'
                })
                setTimeout(() => {
                    window.location.href = RouteMap.Page('users/alerts')
                }, 500)
            })
            .catch(() => {
                setFeedback({
                    open: true,
                    message: <FormattedMessage id='create_alert_popup.alert_creation_failure' />,
                    severity: 'error'
                })
            })
    }

    return (<>
        <Box className={classes.banner}>
            <FeedbackSnackbar
                open={feedback.open}
                message={feedback.message}
                severity={feedback.severity}
                closeFeedback={closeFeedback}
            />
            <Container maxWidth='lg' className={classes.bannerContainer}>
                <Box className={classes.bannerText}>
                    <MultiFontText>
                        <FormattedMessage id='alert_creation_page.title'>
                            {text => <Typography variant='h6' component='h1' className={classes.pageTitle} dangerouslySetInnerHTML={{ __html: text }} />}
                        </FormattedMessage>
                    </MultiFontText>
                    <Typography variant='body1' component='p' className={classes.pageSubTitle}>
                        <FormattedMessage id='alert_creation_page.subtitle_1' />
                    </Typography>
                </Box>
            </Container>
        </Box>
        <Box className={classes.alertFormContainer}>
            <AlertForm
                itemType={itemType}
                handleSubmit={handleSubmit}
                formType={config.formType}
            />
        </Box>
        <BannerWrapper>
            <AlertCreationBanner />
        </BannerWrapper>
    </>)
}

export default AlertCreation
