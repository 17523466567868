import React, { useState } from 'react'

import AdminVideoAPI from 'api/AdminVideoAPI'
import RouteMap from 'assets/RouteMap'
import AdminEditVideoView from './AdminEditVideoView'

const AdminEditVideo = (props) => {
    const { isNew } = props
    const propsVideo = props.video
    const [video, setVideo] = useState(propsVideo)
    const [feedback, setFeedback] = useState({
        open: false,
        message: null,
        severity: null
    })
    const [formErrors, setFormErrors] = useState({})

    const closeFeedback = () => {
        setFeedback({ ...feedback, open: false })
    }

    const handleChange = (e) => {
        setVideo({
            ...video,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        isNew ? createVideo() : updateVideo()
    }

    const handleFetch = () => {
        AdminVideoAPI.FetchVideo(video)
            .then((response) => {
                setVideo({ ...video, ...response.data.video_info })
                setFeedback({
                    open: true,
                    message: 'Successfully fetched data from YouTube',
                    severity: 'success'
                })
            })
            .catch(error => {
                setVideo({ url: '', validated: false })
                setFeedback({
                    open: true,
                    message: `Error: ${error.response.data.error}`,
                    severity: 'error'
                })
            })
    }

    const createVideo = () => {
        AdminVideoAPI.CreateVideo(video)
            .then((response) => {
                setFeedback({
                    open: true,
                    message: 'Successfully created your video',
                    severity: 'success'
                })
                setTimeout(() => {
                    window.location.href = RouteMap.Page('admin/video')
                }, 500)
            })
            .catch(error => {
                setFeedback({
                    open: true,
                    message: `Error occurred: ${error.response.data.error}`,
                    severity: 'error'
                })
            })
    }

    const updateVideo = () => {
        AdminVideoAPI.UpdateVideo(video)
            .then((response) => {
                setFeedback({
                    open: true,
                    message: 'Successfully updated your video',
                    severity: 'success'
                })
                setTimeout(() => {
                    window.location.href = RouteMap.Page('admin/video')
                }, 500)
            })
            .catch(error => {
                setFeedback({
                    open: true,
                    message: `Error occurred: ${error.response.data.error}`,
                    severity: 'error'
                })
            })
    }

    return (
        <AdminEditVideoView
            isNew={isNew}
            video={video}
            feedback={feedback}
            formErrors={formErrors}
            closeFeedback={closeFeedback}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleFetch={handleFetch}
        />
    )
}

export default AdminEditVideo
