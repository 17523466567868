import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import SVGFactory from 'icons/SVGFactory/SVGFactory'

import { useStyles } from '../../Searchbar_v2_style'

const QuickFilterTagOption = (props) => {
    const classes = useStyles()
    const { isSelected, isHoverText, setIsHoverText, quickFilterOption } = props

    return (<>
        <Box className={`
            ${classes.containerIcon}
            ${isSelected ? classes.selected : classes.notSelected}
            ${isHoverText === quickFilterOption.value ? classes.selected : classes.notSelected}
            ${quickFilterOption.value === 'impact' && classes.containerIconImpact}
        `}>
            <SVGFactory name={quickFilterOption.icon} height="25" width="24" />
        </Box>
        <Typography
            onMouseOver={() => setIsHoverText(quickFilterOption.value)}
            onMouseLeave={() => setIsHoverText(null)}
            variant='h3'
            component='p'
            className={classes.quickFilterText}
        >
            <FormattedMessage id={`quick_filters.${quickFilterOption.value}`} />
        </Typography>
    </>)
}

export default QuickFilterTagOption
