import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Image, Transformation } from 'cloudinary-react'

import { useStyles } from './HomeEmployerPage_style'
import { Box, Container, Typography, Divider, Grid, Hidden } from '@material-ui/core'
import Button from 'shared/components/Button/Button'
import RouteMap from 'assets/RouteMap'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

import { MEDIUM_SIZE } from 'assets/CloudinaryURLHelper'
import { usePipedrive, CHAT_EMPLOYER_GENERAL } from 'hooks/usePipedrive'
import ArticleCardAlt from 'shared/cards/ArticleCardAlt/ArticleCardAlt'
import EmployerFindOutMoreBanner from 'shared/components/EmployerFindOutMoreBanner/EmployerFindOutMoreBanner'
import EmployerPostAJobBanner from 'shared/components/EmployerPostAJobBanner/EmployerPostAJobBanner'
import HomeStats from '../HomeStudent/components/HomeStats/HomeStats'
import LottieFactory from 'icons/LottieFactory/LottieFactory'
import MultiFontText from 'shared/components/MultiFontText/MultiFontText'
import SVGFactory from 'icons/SVGFactory/SVGFactory'

const HomeEmployerPage = (props) => {
    const { user, employerArticles } = props
    const classes = useStyles()
    const [employerBrandingInfoToggle, setEmployerBrandingInfoToggle] = useState(false)
    const [advertisingInfoToggle, setAdvertisingInfoToggle] = useState(false)
    const [jobPostingInfoToggle, setJobPostingInfoToggle] = useState(false)

    useEffect(() => {
        const { instantiateChat } = usePipedrive()
        instantiateChat(CHAT_EMPLOYER_GENERAL)
    }, [])

    const partners = [
        { imageId: 'Deloitte_Logo_Black', name: 'deloitte-belgium' },
        { imageId: 'toogoodtogo_2', name: 'too-good-to-go' },
        { imageId: 'bpost', name: 'bpost' },
        { imageId: 'workingfor_logo', name: 'travaillerpour-be-werkenvoor-be' },
        { imageId: 'merchery', name: 'merchery' },
        { imageId: 'dokters_van_de_wereld', name: 'medecins-du-monde-belgique' },
        { imageId: 'bavet-logo-publish-a-student-job', name: 'bavet-bbb3bad4-f932-4146-94ac-7b96603d5c45' },
        { imageId: 'defensie', name: 'defensie' },
        { imageId: 'drink_a_flower', name: 'drink-a-flower' },
        { imageId: 'gsk', name: 'gsk' },
        { imageId: 'beego', name: 'beego' },
        { imageId: 'Vlaamse_Overheid_logo_Student.be_jobs_Stages_studentenjobs', name: 'vlaamse-overheid' },
    ]
    const jobPostingUrl = user ? RouteMap.Page('employer/new-ad') : RouteMap.Page('payment')

    return (
        <>
            {/* BANNER */}
            <Box className={classes.banner}>
                <Container maxWidth='lg' className={classes.bannerContainer}>
                    <Hidden mdDown>
                        <Box className={classes.lottieIconOne}>
                            <LottieFactory lottie='smiley' />
                        </Box>
                        <Box className={classes.lottieIconTwo}>
                            <LottieFactory lottie='star' delay={1000}/>
                        </Box>
                    </Hidden>
                    <Box className={classes.bannerText}>
                        <MultiFontText>
                            <FormattedMessage id='landing_home_employer_page.title_v2'>
                                {text =>
                                    <Typography className={classes.bannerTitle} variant='h1' dangerouslySetInnerHTML={{ __html: text }} />
                                }
                            </FormattedMessage>
                        </MultiFontText>
                        <Box className={classes.bannerCTA}>
                            <RouteMapLink redirectionUrl={jobPostingUrl}>
                                <Button variant='cta-primary' className={classes.customButton}>
                                    <FormattedMessage id='publish_a_student_job.post_a_job_button' />
                                </Button>
                            </RouteMapLink>
                        </Box>
                    </Box>
                    <Hidden mdDown>
                        <Box className={classes.bannerImage}>
                            <Image
                                cloudName='studentbe'
                                publicId='assets/boy_smiling'
                                alt='student.be employer boy smiling banner.'
                            >
                                <Transformation flags='progressive' fetchFormat='auto' quality='auto:best' width='1000' crop='fill'/>
                            </Image>
                        </Box>
                    </Hidden>
                </Container>
            </Box>
            {/* WHY US */}
            <Box className={classes.whyUs}>
                <Container maxWidth='lg' className={classes.whyUsContainer}>
                    <Box className={classes.titleSection}>
                        <Typography variant='h2' className={classes.pageTitleFullWidth}>
                            <FormattedMessage id="landing_home_employer_page.why_us.title" />
                        </Typography>
                        <FormattedMessage id="landing_employer.stat.description_html">
                            {translatedText =>
                                <Typography variant='body1' component='p' className={classes.pageSubTitle} dangerouslySetInnerHTML={{ __html: translatedText }}/>
                            }
                        </FormattedMessage>
                    </Box>
                    <Box className={classes.whyUsStats}>
                        <Box className={classes.whyUsStat}>
                            <Box className={classes.iconContainer} style={{ backgroundColor: 'var(--background-blue)' }}>
                                <SVGFactory name='person' width='40px' height='40px' />
                            </Box>
                            <Box>
                                <Typography variant="h2" className={classes.stat}><FormattedMessage id='landing_home_employer_page.why_us_stats.youngsters_in_db_number'/></Typography>
                                <Typography component="p" className={classes.statDescription}>
                                    <FormattedMessage id='landing_home_employer_page.why_us_stats.youngsters_in_db' />
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.whyUsStat}>
                            <Box className={classes.iconContainer} style={{ backgroundColor: 'var(--background-orange)' }}>
                                <SVGFactory name='book' width='40px' height='40px' />
                            </Box>
                            <Box>
                                <Typography variant="h2" className={classes.stat}>51.000</Typography>
                                <Typography component="p" className={classes.statDescription}>
                                    <FormattedMessage id='landing_home_employer_page.why_us_stats.application_per_month' />
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.whyUsStat}>
                            <Box className={classes.iconContainer} style={{ backgroundColor: 'var(--background-green)' }}>
                                <SVGFactory name='desktop' width='40px' height='40px' />
                            </Box>
                            <Box>
                                <Typography variant="h2" className={classes.stat}>10.000</Typography>
                                <Typography component="p" className={classes.statDescription}>
                                    <FormattedMessage id='landing_home_employer_page.why_us_stats.visitors_per_day' />
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>
            {/* WHAT WE OFFER */}
            <Box className={classes.whatWeOffer}>
                <Container maxWidth='lg' className={classes.whyUsContainer}>
                    <Box className={classes.titleSection}>
                        <Typography variant='h6' component='h2' className={classes.pageTitleFullWidth}>
                            <FormattedMessage  id="landing_home_employer_page.what_we_offer.title" />
                        </Typography>
                        <FormattedMessage id="landing_home_employer_page.what_we_offer.subtitle_html">
                            {translatedText => <Typography variant='body1' component='p' className={classes.pageSubTitle} dangerouslySetInnerHTML={{ __html: translatedText }}/>}
                        </FormattedMessage>
                    </Box>
                    <Box className={classes.offers}>
                        {/* EMPLOYER BRANDING */}
                        <Box className={classes.offer}>
                            <Image
                                cloudName='studentbe'
                                publicId='assets/employer_branding'
                                alt='student.be employer branding offer.'
                                className={classes.offerImage}
                            >
                                <Transformation flags='progressive' fetchFormat='auto' quality='auto:good' crop='fill' />
                            </Image>
                            <Box className={classes.offerText}>
                                <Typography variant='h6' component='h3' className={classes.offerTitle}>
                                    <FormattedMessage id="landing_home_employer_page.what_we_offer.employer_branding.title" />
                                </Typography>
                                <Typography>
                                    <FormattedMessage id="landing_home_employer_page.what_we_offer.employer_branding.explanation" />
                                </Typography>
                                <Box mt={1}>
                                    <Box className={classes.expandingText} onClick={() => setEmployerBrandingInfoToggle(!employerBrandingInfoToggle)}>
                                        <Typography className={classes.offerExplanationTitle}>
                                            <span className={classes.boldBlue}>+</span>
                                            <FormattedMessage id="landing_home_employer_page.what_we_offer.offers.how" />
                                        </Typography>
                                        {employerBrandingInfoToggle && <Box ml={2} mt={1}>
                                            <Typography className={classes.offerExplanation}>
                                                <FormattedMessage id="landing_home_employer_page.what_we_offer.employer_branding.how_explanation" />
                                            </Typography>
                                        </Box>}
                                    </Box>
                                    <Box className={classes.divider}>
                                        <Divider />
                                    </Box>
                                    <RouteMapLink page='contact-employer'>
                                        <Box className={classes.offerCTA}>
                                            <Button size='small' variant='cta-primary' className={classes.customButton}>
                                                <FormattedMessage id='landing_home_employer_page.what_we_offer.offers.contact_us' />
                                            </Button>
                                        </Box>
                                    </RouteMapLink>
                                </Box>
                            </Box>
                        </Box>
                        {/* ADVERTISEMENT */}
                        <Box className={classes.offer}>
                            <Image
                                cloudName='studentbe'
                                publicId='assets/advertisement'
                                alt='student.be employer branding offer.'
                                className={classes.offerImage}
                            >
                                <Transformation flags='progressive' fetchFormat='auto' quality='auto:good' crop='fill' />
                            </Image>
                            <Box className={classes.offerText}>
                                <Typography variant='h6' component='h3' className={classes.offerTitle}>
                                    <FormattedMessage id="landing_home_employer_page.what_we_offer.advertisement.title" />
                                </Typography>
                                <Typography>
                                    <FormattedMessage id="landing_home_employer_page.what_we_offer.advertisement.tagling" /><br/>
                                    <FormattedMessage id="landing_home_employer_page.what_we_offer.advertisement.explanation" />
                                </Typography>
                                <Box mt={1}>
                                    <Box className={classes.expandingText} onClick={() => setAdvertisingInfoToggle(!advertisingInfoToggle)}>
                                        <Typography className={classes.offerExplanationTitle}>
                                            <span className={classes.boldBlue}>+</span>
                                            <FormattedMessage id="landing_home_employer_page.what_we_offer.offers.how" />
                                        </Typography>
                                        {advertisingInfoToggle && <Box ml={2} mt={1}>
                                            <Typography className={classes.offerExplanation}>
                                                <FormattedMessage id="landing_home_employer_page.what_we_offer.advertisement.how_explanation" />
                                            </Typography>
                                        </Box>}
                                    </Box>
                                    <Box className={classes.divider}>
                                        <Divider />
                                    </Box>
                                    <RouteMapLink page='contact-employer'>
                                        <Box className={classes.offerCTA}>
                                            <Button size='small' variant='cta-primary' className={classes.customButton}>
                                                <FormattedMessage id='landing_home_employer_page.what_we_offer.offers.contact_us' />
                                            </Button>
                                        </Box>
                                    </RouteMapLink>
                                </Box>
                            </Box>
                        </Box>
                        {/* JOB POSTING */}
                        <Box className={classes.offer}>
                            <Image
                                cloudName='studentbe'
                                publicId='assets/employer_job_posting'
                                alt='student.be job posting offer.'
                                className={classes.offerImage}
                            >
                                <Transformation flags='progressive' fetchFormat='auto' quality='auto:good' crop='fill' />
                            </Image>
                            <Box className={classes.offerText}>
                                <Typography variant='h6' component='h3' className={classes.offerTitle}>
                                    <FormattedMessage id="landing_home_employer_page.what_we_offer.job_posting.title" />
                                </Typography>
                                <Typography>
                                    <FormattedMessage id="landing_home_employer_page.what_we_offer.job_posting.explanation" />
                                </Typography>
                                <Box mt={1}>
                                    <Box className={classes.expandingText} onClick={() => setJobPostingInfoToggle(!jobPostingInfoToggle)}>
                                        <Typography className={classes.offerExplanationTitle}>
                                            <span className={classes.boldPurple}>+</span>
                                            <FormattedMessage id="landing_home_employer_page.what_we_offer.offers.advantages" />
                                        </Typography>
                                        {jobPostingInfoToggle && <Box ml={2} mt={1}>
                                            <Typography className={classes.offerExplanation}>
                                                <FormattedMessage id="landing_home_employer_page.what_we_offer.job_posting.advantages" />
                                            </Typography>
                                        </Box>}
                                    </Box>
                                    <Box className={classes.divider}>
                                        <Divider />
                                    </Box>
                                    <RouteMapLink page='contact-employer' >
                                        <Box className={classes.offerCTA}>
                                            <Button size='small' variant='cta-primary' className={classes.customButton}>
                                                <FormattedMessage id='landing_home_employer_page.what_we_offer.offers.contact_us' />
                                            </Button>
                                        </Box>
                                    </RouteMapLink>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>
            {/* EMPLOYER FIND OUT MORE */}
            <Box className={classes.findOutMore}>
                <EmployerFindOutMoreBanner
                    imageId='assets/team_laughing'
                    context="HomeEmployerPage"
                />
            </Box>
            {/* COMPANIES ON OUR PLATFORM */}
            <Box className={classes.theyTrustUs}>
                <Container maxWidth="lg" className={classes.whyUsContainer}>
                    <Box className={classes.titleSection}>
                        <Typography variant='h6' component='h2' className={classes.pageTitleFullWidth}><FormattedMessage id="landing_employer.they_trust_us.title" /></Typography>
                        <Typography className={classes.pageSubTitle}><FormattedMessage id="landing_employer.they_trust_us.subtitle" /></Typography>
                    </Box>
                    <Grid container justifyContent="center" spacing={2} className={classes.partnerBox}>
                        {partners.map(partner =>
                            <Grid key={partner.imageId} item xs={4} sm={2} spacing={0} className={classes.partnerImageBox}>
                                <RouteMapLink showItemType={'company'} showItemUrl={partner.name} target={'_blank'}>
                                    <Image
                                        className={classes.partnerImage}
                                        cloudName="studentbe"
                                        publicId={`partner-logo/${partner.imageId}`}
                                        alt={`partner image ${partner.imageId}`}
                                    >
                                        <Transformation flags="progressive" fetchFormat="auto" width={MEDIUM_SIZE} quality="auto:best" crop="fill" />
                                    </Image>
                                </RouteMapLink>
                            </Grid>
                        )}
                    </Grid>
                </Container>
            </Box>
            {/* EMPLOYER ARTICLES */}
            <Box className={classes.employerArticles}>
                <Container maxWidth='lg' className={classes.whyUsContainer}>
                    <Box className={classes.titleSection}>
                        <Typography variant='h6' component='h2' className={classes.employerArticlesTitle}><FormattedMessage id="landing_employer.articles_title" /></Typography>
                        <Typography className={classes.employerArticlesSubtitle}><FormattedMessage id="landing_employer.articles_subtitle" /></Typography>
                    </Box>
                    <Box className={classes.articles}>
                        {employerArticles.map(article => {
                            return (
                                <ArticleCardAlt
                                    key={`article-card-alt-${article.title}`}
                                    article={article}
                                />
                            )
                        })}
                    </Box>
                    <Box className={classes.employerArticleCTA}>
                        <RouteMapLink articleCategory='employer'>
                            <Button size='big' variant='cta-primary'>
                                <FormattedMessage id='item_index.show_more_button' />
                            </Button>
                        </RouteMapLink>
                    </Box>
                </Container>
            </Box>
            {/* EMPLOYER POST A JOB */}
            <Box className={classes.postAJob}>
                <EmployerPostAJobBanner
                    imageId={'assets/two_girls_working'}
                />
            </Box>
            {/* STUDENT IN NUMBERS */}
            <Container maxWidth='lg' className={classes.homeStatsContainer}>
                <HomeStats />
            </Container>
        </>
    )
}

export default HomeEmployerPage
