import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { injectIntl } from 'react-intl'

import ItemIndexMain from 'pages/ItemIndexMain/ItemIndexMain'
import ItemShowFactory from './ItemShowFactory'
import RouteHelper from 'assets/RouteHelper'

class SchoolRouter extends React.Component {
    render () {
        const { formatMessage } = this.props.intl
        const basePage = formatMessage({ id: 'routes.schools' })
        return (
            <div className='school'>
                <div className="navigation-container">
                    <Switch>
                        <Route exact path={RouteHelper.GetIndexPaths(basePage, 'schools')}>
                            <ItemIndexMain
                                {...{ ...this.props }}
                                key="school-index-main"
                                type='school'
                                filterUrl="/api/v1/schools/filter"
                                user={this.props.user}
                                shouldFetchArticles={false}
                                ads={this.props.ads}
                                pageName="schools"
                                selectedLocationTag={this.props.selectedLocationTag}
                                altLangUrls={this.props.altLangUrls}
                            />
                        </Route>

                        <Route
                            path={`/${basePage}/:itemid/`}
                            render={(props) => <>
                                <ItemShowFactory
                                    {...props}
                                    renderType='school'
                                    getAllInfoUrl='/api/v1/schools/get_all_info?id='
                                    closeShowTag={this.closeShowTag}
                                    user={this.props.user}
                                    ads={this.props.ads}
                                />
                            </>}
                        />
                    </Switch>
                </div>
            </div>
        )
    }
}

export default injectIntl(SchoolRouter)
