import React from 'react'
import { Box } from '@material-ui/core'
import Button from 'components/Button/Button'
import IconFactory from 'icons/IconFactory/IconFactory'
import StringInput from '../StringInput/StringInput'
import { useStyles } from '../../AdminEditFeaturedSchoolView_style'

const SchoolFactFormInput = (props) => {
    const { index, items, setItems, deleteItem } = props
    const classes = useStyles()

    const handleItemChange = (name) => {
        items[index][name] = event.target.value
        setItems([...items])
    }

    return (
        <Box className={classes.itemWrapper}>
            <StringInput
                title='Title'
                className={classes.stringInput}
                state={items[index]}
                value='title'
                handleChange={() => handleItemChange('title')}
            />
            <StringInput
                title='Value'
                className={classes.stringInput}
                state={items[index]}
                value='value'
                handleChange={() => handleItemChange('value')}
            />
            <Box className={classes.marginTopBox}>
                <Button onClick={() => deleteItem(index)} size='small' variant='secondary'>
                    Delete this school fact
                    <IconFactory color='black' icon='delete' />
                </Button>
            </Box>
        </Box>
    )
}

export default SchoolFactFormInput
