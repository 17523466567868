/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import SingleCityFilterView from './SingleCityFilterView'
import CollectionHandler from 'assets/CollectionHandler.js'

const SingleCityFilter = (props) => {
    const { handleCityChange, city } = props
    const cityCollection = CollectionHandler.Get('cityCollection')
    const [cityQuery, setCityQuery] = useState('')
    const [searchCities, setSearchCities] = useState(cityCollection)

    const handleInputSearchCity = (event) => {
        const { value } = event.target
        var newCities = []
        if (value.length !== 0) {
            const regex = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
            newCities = cityCollection.filter(elem =>
                elem.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(regex) === true ||
                elem.value.includes(regex) === true
            )
        }

        if (newCities.length !== 0) {
            setSearchCities(newCities)
        } else {
            setSearchCities(cityCollection)
        }
        setCityQuery(value)
    }

    return (
        <SingleCityFilterView
            city={city}
            handleCityChange={handleCityChange}
            handleInputSearchCity={handleInputSearchCity}
            searchCities={searchCities}
            citiesInput={cityQuery}
        />
    )
}

export default SingleCityFilter
