import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import SchoolShowHelmet from 'helmets/SchoolShowHelmet'
import { Box, Container, Hidden, Typography } from '@material-ui/core'

const SchoolShow = (props) => {
    const { user, school, ads, unavailable } = props

    return (
        <>
            <SchoolShowHelmet school={school} logoCloudinaryKey={school.logo_cloudinary_key} />
            <Typography variant='h1'>{school.name}</Typography>
        </>
    )
}

export default SchoolShow
