import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    container: {},
    recruitmentStatusDropdown: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 12px !important',
        borderRadius: 50,
        backgroundColor: 'var(--background-grey-pale)',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 250
        }
    },
    recruitmentStatusSelected: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        fontSize: 14
    },
    recruitmentStatusOptions: {
        display: 'flex',
        alignItems: 'center',
        gap: 8
    },
    dropdownContainer: {
        marginLeft: 20
    },
    overrideSelect: {
        padding: 0
    }
}))
