export const useStyles = (theme) => ({
    root: {
        display: 'flex'
    },
    bannerColorContainer: {
        backgroundColor: 'var(--background-grey-light)',
        padding: 20
    },
    pageBubble: {
        height: 24,
        width: 24,
        backgroundColor: 'var(--black)',
        color: 'white',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 12px',
        cursor: 'pointer'
    },
    active: {
        opacity: '1 !important'
    },
    nonActive: {
        opacity: '0.4 !important'
    },
    test: {
        backgroundColor: 'red !important'
    },
    iconButton: {
        height: 45,
        width: 45
    },
    jobQuestionsContainer: {
        [theme.breakpoints.up('md')]: {
            height: '100%',
            top: 0,
            position: 'sticky',
            display: 'flex',
            alignItems: 'center',
            paddingRight: 8
        },
        [theme.breakpoints.only('md')]: {
            paddingRight: '20px',
        },
    },
    boxContainer: {
        borderRadius: 20,
        border: '1px solid var(--background-grey-light)'
    },
    paperHead: {
        backgroundColor: 'var(--background-grey-light)',
        borderRadius: '20px 20px 0 0'
    }
})
