/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Typography, Hidden, Tooltip, IconButton } from '@material-ui/core'

import { useStyles } from './AlertListCard_style'
import CollectionHandler from 'assets/CollectionHandler'
import RouteMap from 'assets/RouteMap'
import AlertsAPI from 'api/AlertsAPI'
import URLParamsHandler from 'assets/URLParamsHandler'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import ItemIcon from 'components/ItemIcon/ItemIcon'
import IconFactory from 'icons/IconFactory/IconFactory'
import Paper from 'components/Paper/Paper'
import JobAlertEditModal from 'modals/JobAlertEditModal/JobAlertEditModal'
import FeedbackSnackbar from 'shared/components/FeedbackSnackbar/FeedbackSnackbar'

const AlertListCard = (props) => {
    const classes = useStyles()
    const quickFilterCollection = CollectionHandler.Get('quickFilterCollection')
    const { alert } = props
    const [alertState, setAlertState] = useState(alert)
    const [isAlertEditOpen, setIsAlertEditOpen] = useState(false)
    const [feedback, setFeedback] = useState({ open: false, message: null, severity: null })
    const closeFeedback = () => setFeedback({ ...feedback, open: false })

    const toggleActive = () => {
        setAlertState({
            ...alertState,
            active: !alertState.active
        })
        AlertsAPI.UpdateAlert({
            id: alertState.id,
            active: !alertState.active
        })
    }

    const getRedirectionUrl = () => {
        const filters = {
            itemType: alert?.job_type,
            location: alert?.locations,
            quickFilter: alert?.quick_filter,
            studyDomains: alert?.study_domains?.map(e => ({ value: e })),
            schedule: alert?.schedules?.map(e => ({ value: e })),
            companySize: alert?.company_sizes?.map(e => ({ value: e })),
            sectors: alert?.sectors?.map(e => ({ value: e })),
        }
        const urlParams = URLParamsHandler.getURLSearchParams('alert', filters)
        return RouteMap.Show(
            alertState.job_type,
            `?${urlParams}`
        ).slice(0, -1).replace('/?', '?')
    }

    const renderSearchParams = () => {
        let paramsFormat = ''

        if (alertState.quick_filter) {
            paramsFormat += CollectionHandler.TranslateFromCollection(
                quickFilterCollection[alertState.job_type.replace('_', '-') + 's'],
                alertState.quick_filter
            ) + ' | '
        }
        alertState.locations.map((location) => {
            paramsFormat += CollectionHandler.Translate(
                'cityCollection',
                location.value
            ) + ' | '
        })
        alertState.study_domains.map((domain) => {
            paramsFormat += CollectionHandler.Translate(
                'studyDomainCollection',
                domain
            ) + ' | '
        })
        alertState.company_sizes.map((size) => {
            paramsFormat += CollectionHandler.Translate(
                'companySizeCollection',
                size
            ) + ' | '
        })
        alertState.sectors.map((sector) => {
            paramsFormat += CollectionHandler.Translate(
                'sectorCollection',
                sector
            ) + ' | '
        })

        // Removes '|' char after the last array elem
        paramsFormat = paramsFormat.slice(0, -3)

        return (
            paramsFormat
        )
    }

    const closeAlertEditModal = () => {
        setIsAlertEditOpen(false)
    }

    const handleAlertEdit = () => {
        setIsAlertEditOpen(true)
    }

    const updateAlert = (filters, itemType) => {
        const data = {
            id: alertState.id,
            job_type: itemType,
            locations: filters.location,
            quick_filter: filters.quickFilter,
            sectors: filters.sectors?.map(e => e.value),
            schedules: filters.schedule?.map(e => e.value),
            study_domains: filters.studyDomains?.map(e => e.value),
            company_sizes: filters.companySize?.map(e => e.value)
        }
        AlertsAPI.UpdateAlert(data)
            .then((response) => {
                setIsAlertEditOpen(false)
                setFeedback({
                    open: true,
                    message: <FormattedMessage id='create_alert_popup.alert_update_success' />,
                    severity: 'success'
                })
                setTimeout(() => {
                    window.location.reload()
                }, 500)
            })
            .catch(() => {
                setIsAlertEditOpen(false)
                setFeedback({
                    open: true,
                    message: <FormattedMessage id='create_alert_popup.alert_update_failure' />,
                    severity: 'error'
                })
            })
    }

    return (
        <Box className={`${classes.alertCardContainer} ${alertState.job_type.replace('_', '-')}`}>
            <JobAlertEditModal
                alert={alert}
                isOpen={isAlertEditOpen}
                closeModal={closeAlertEditModal}
                handleSubmit={updateAlert}
            />
            <FeedbackSnackbar
                open={feedback.open}
                message={feedback.message}
                severity={feedback.severity}
                closeFeedback={closeFeedback}
            />
            <Paper className={classes.alertCard}>
                <Hidden smDown>
                    <Box className={`${classes.alertJobType} ${classes.alertCardColumn}`} >
                        <ItemIcon
                            color={!alertState.active && 'var(--background-grey-light'}
                            type={alertState.job_type}
                            width={30}
                            height={30}
                        />
                        <Typography variant='h1' component='span' className={classes.jobType}>
                            <FormattedMessage id={`type.${alertState.job_type}.plural`} />
                        </Typography>
                    </Box>
                </Hidden>
                <Box className={classes.gridContainer}>
                    <Box className={classes.gridSubContainer}>
                        <Hidden mdUp>
                            <Box className={classes.mobileTypeContainer}>
                                <ItemIcon
                                    color={!alertState.active && 'var(--background-grey-light'}
                                    type={alertState.job_type}
                                    width={14}
                                    height={14}
                                />
                                <Typography variant='h1' component='span' className={classes.jobType}>
                                    <FormattedMessage id={`type.${alertState.job_type}.plural`} />
                                </Typography>
                            </Box>
                        </Hidden>
                        <Typography variant='body1' component='span'>
                            {renderSearchParams()}
                        </Typography>
                    </Box>
                    <RouteMapLink className={classes.matchingJobsButton} redirectionUrl={getRedirectionUrl()}>
                        <Typography
                            variant='h1'
                            component='span'
                            className={classes.jobType}
                        >
                            <FormattedMessage id='alert_card.tooltip.show_alert' />
                        </Typography>
                        <IconFactory
                            icon='circle-arrow-right'
                            color='black'
                        />
                    </RouteMapLink>
                </Box>
                <Box className={classes.hoverActionContainer}>
                    <Tooltip title={<FormattedMessage id='alert_card.tooltip.edit_alert' />}>
                        <IconButton className={classes.iconButton} onClick={handleAlertEdit}>
                            <IconFactory icon='edit-alt' width='20px' height='20px' color='black' />
                        </IconButton>
                    </Tooltip>
                    <Box className={classes.hoverAction}>
                        <Box onClick={toggleActive}>
                            <IconFactory
                                icon={alertState.active ? 'toggle-on' : 'toggle-off'}
                                color={alertState.active ? 'var(--foreground-black)' : 'var(--foreground-grey-slate)'}
                                width='20px'
                                height='20px'
                            />
                            <Box className={classes.hoverActionDescription}>
                                {alertState.active
                                    ? <FormattedMessage id="company_job_card.activated" />
                                    : <span className={classes.inactive}>
                                        <FormattedMessage id="company_job_card.deactivated" />
                                    </span>
                                }
                            </Box>
                        </Box>
                    </Box>
                    <Tooltip title={<FormattedMessage id='alert_card.tooltip.delete_alert' />}>
                        <IconButton className={classes.iconButton} onClick={() => props.remove(alert)}>
                            <IconFactory icon='delete' width='20px' height='20px' color='black' />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Paper>
        </Box>
    )
}

export default AlertListCard
