import React from 'react'
import { InputLabel, Input, FormHelperText, FormControl } from '@material-ui/core'

const StringInput = (props) => {
    const { title, state, value, formErrors, handleChange } = props

    return (
        <FormControl>
            <InputLabel htmlFor="email-input">{title}</InputLabel>
            <Input
                value={state[value]}
                name={value}
                id={`${value}-input`}
                aria-describedby={`${value}-helper-text`}
                onChange={handleChange}
                error={formErrors && !!formErrors[value]}
            />
            <FormHelperText id="email-helper-text">{formErrors && formErrors[value]}</FormHelperText>
        </FormControl>

    )
}

export default StringInput
