import React from 'react'
import { Helmet } from 'react-helmet'

import ItemShowFactory from './ItemShowFactory'
import ItemIndexMain from 'pages/ItemIndexMain/ItemIndexMain'
import { injectIntl } from 'react-intl'

import { Switch, Route } from 'react-router-dom'

class KotRouter extends React.Component {
    render () {
        const { formatMessage } = this.props.intl
        const basePage = formatMessage({ id: 'routes.kots' })
        return (
            <div className='kot'>
                <div className="navigation-container">
                    <Switch>
                        <Route exact path={[`/${basePage}`, `/:location/${basePage}`, `/:location/${basePage}`]}>
                            {this.props.selectedLocationTag &&
                                <Helmet>
                                    <title>{formatMessage({ id: 'helmet.kot.city_index.title' }, { filter: this.props.selectedLocationTag.name })}</title>
                                    <meta property="og:title" content={formatMessage({ id: 'helmet.kot.city_index.title' }, { filter: this.props.selectedLocationTag.name })} />
                                    <meta property="og:description" content={formatMessage({ id: 'helmet.kot.city_index.description' }, { filter: this.props.selectedLocationTag.name })} />
                                    <meta name="description" content={formatMessage({ id: 'helmet.kot.city_index.description' }, { filter: this.props.selectedLocationTag.name })} />
                                    <link rel="canonical" href={this.props.altLangUrls[this.props.lang]} />
                                    <link rel="alternate" hrefLang="fr-BE" href={this.props.altLangUrls.fr} />
                                    <link rel="alternate" hrefLang="en-BE" href={this.props.altLangUrls.en} />
                                    <link rel="alternate" hrefLang="nl-BE" href={this.props.altLangUrls.nl} />
                                    <link rel="alternate" hrefLang="x-default" href={this.props.altLangUrls.en} />
                                </Helmet>
                            }
                            {!this.props.selectedLocationTag &&
                                <Helmet>
                                    <title>{formatMessage({ id: 'helmet.kot.index.title' })}</title>
                                    <meta property="og:title" content={formatMessage({ id: 'helmet.kot.index.title' })} />
                                    <meta property="og:description" content={formatMessage({ id: 'helmet.kot.index.description' })} />
                                    <meta name="description" content={formatMessage({ id: 'helmet.kot.index.description' })} />
                                    <link rel="canonical" href={`https://www.student.be/${this.props.lang}/${this.props.path}/`} />
                                    <link rel="alternate" hrefLang="fr-BE" href='https://www.student.be/fr/kots-a-louer/' />
                                    <link rel="alternate" hrefLang="en-BE" href='https://www.student.be/en/student-rooms/' />
                                    <link rel="alternate" hrefLang="nl-BE" href='https://www.student.be/nl/koten/' />
                                    <link rel="alternate" hrefLang="x-default" href='https://www.student.be/en/student-rooms/' />
                                </Helmet>
                            }
                            <ItemIndexMain
                                {...{ ...this.props }}
                                key="kot-items-index-main"
                                items={this.props.items}
                                type='kot'
                                filterUrl="/api/v1/kots/filter"
                                searchParams={this.props.searchParams}
                                offerCount={this.props.offerCount}
                                itemsGeocoding={this.props.itemsGeocoding}
                                cardSlider={this.props.indexCardSlider}
                                jobsKazi={this.props.jobsKazi}
                                userAddress={this.props.userAddress}
                                user={this.props.user}
                                ref={this.jobRef}
                                shouldFetchArticles={false}
                                ads={this.props.ads}
                                pageName="student-rooms"
                                selectedLocationTag={this.props.selectedLocationTag}
                                altLangUrls={this.props.altLangUrls}
                            />
                        </Route>

                        <Route
                            path={`/${basePage}/:itemid/`}
                            render={ (props) => <>
                                <ItemShowFactory
                                    {...props}
                                    renderType='kot'
                                    getAllInfoUrl='/api/v1/kots/get_all_info?id='
                                    similarUrl='/api/v1/kots/similar'
                                    closeShowTag={this.closeShowTag}
                                    user={this.props.user}
                                    ads={this.props.ads}
                                />
                            </>}
                        />
                    </Switch>
                </div>
            </div>
        )
    }
}

export default injectIntl(KotRouter)
